import { css, useTheme } from '@emotion/react';
import { memo, useMemo, type ReactNode } from 'react';
import { Bar, BarChart, Brush, CartesianGrid, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { Stack } from '@amalia/design-system/components';
import { ColorCategory } from '@amalia/design-system/meta';
import { assert } from '@amalia/ext/typescript';
import { getCustomReportJoinForUser } from '@amalia/reporting/custom-reports/shared';
import { useCustomReportsList } from '@amalia/reporting/custom-reports/state';
import {
  ChartDisplaySettingsXAxis,
  type ChartType,
  type DashboardChartConfiguration,
  type DashboardChartResult,
} from '@amalia/reporting/dashboards-v2/types';

import { ChartLegend } from '../../common/components/chart-legend/ChartLegend';
import { ChartTooltip } from '../../common/components/chart-tooltip/ChartTooltip';
import { useChartColor } from '../../common/hooks/charts-colors';
import { ChartSeriesType, type ChartSeriesMetadata } from '../../common/types/charts.types';
import { useChartColumn } from '../common/hooks/useChartColumn';
import { useYAxisWidth } from '../common/hooks/useYAxisWidth';
import { XAxisTick } from '../common/ticks/x-axis/XAxisTick';
import { YAxisTick } from '../common/ticks/y-axis/YAxisTick';

import { BarLabel } from './ticks/bar-label/BarLabel';

const MAX_COLUMNS_FOR_PREVIEW = 12;

export type SimpleBarChartProps = {
  readonly chartConfiguration: DashboardChartConfiguration<ChartType.SIMPLE_BAR_CHART>;
  readonly chartData?: DashboardChartResult;
  readonly chartFilters: ReactNode;
  readonly isAnimationActive?: boolean;
};

export const SimpleBarChart = memo(function SimpleBarChart({
  chartConfiguration,
  chartData,
  chartFilters,
  isAnimationActive = true,
}: SimpleBarChartProps) {
  const theme = useTheme();
  const chartColors = useChartColor();

  const { data: customReports = [] } = useCustomReportsList();

  const columnIdentifierForXAxis = useMemo(() => {
    if (chartConfiguration.displaySettings.xAxis.identifier === ChartDisplaySettingsXAxis.PERIOD) {
      return chartConfiguration.displaySettings.xAxis;
    }

    const customReport = customReports.find((customReport) => customReport.id === chartConfiguration.customReportId);

    // Should never happen
    assert(customReport, 'Custom report not found');

    return {
      joins: [getCustomReportJoinForUser(customReport.source)],
      identifier: 'fullName',
    };
  }, [chartConfiguration.customReportId, chartConfiguration.displaySettings.xAxis, customReports]);

  const { chartRef, width: yAxisWidth } = useYAxisWidth();

  const xAxisColumn = useChartColumn({
    chartData,
    columnIdentifier: columnIdentifierForXAxis,
  });

  const yAxisColumn = useChartColumn({
    chartData,
    columnIdentifier: chartConfiguration.displaySettings.yAxis,
  });

  const seriesList: ChartSeriesMetadata[] = useMemo(
    () =>
      [
        yAxisColumn && {
          columnMetadata: yAxisColumn,
          color: chartColors[chartConfiguration.displaySettings.color],
          type: ChartSeriesType.BAR,
        },
      ].filter(Boolean),
    [chartColors, chartConfiguration.displaySettings.color, yAxisColumn],
  );

  const brushEndIndex = useMemo(() => {
    if (chartData?.items.length) {
      return Math.min(MAX_COLUMNS_FOR_PREVIEW, chartData.items.length - 1);
    }

    return 0;
  }, [chartData?.items]);

  if (!xAxisColumn || !yAxisColumn) {
    return null;
  }

  return (
    <Stack
      gap={16}
      css={css`
        height: 100%;
        // Useful when the chart is alone in a dashboard otherwise the height will be null and the chart will not be displayed
        min-height: 250px;
      `}
    >
      {chartFilters}

      <div
        css={css`
          flex: 1;
          position: relative;
        `}
      >
        <div
          css={css`
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
          `}
        >
          <ResponsiveContainer height="100%">
            <BarChart
              ref={chartRef}
              data={chartData?.items}
              margin={{ top: 18, right: 0, bottom: 0, left: 0 }}
            >
              <CartesianGrid
                stroke={theme.ds.colors.gray[100]}
                strokeWidth={0.5}
                vertical={false}
              />

              <Tooltip
                allowEscapeViewBox={{ x: false, y: true }}
                cursor={{ fill: theme.ds.colors.gray[100] }}
                filterNull={false}
                reverseDirection={{ x: false, y: true }}
                content={
                  <ChartTooltip
                    formatLabel={xAxisColumn.formatTooltipValue}
                    seriesList={seriesList}
                  />
                }
              />

              <Bar
                barSize={32}
                dataKey={yAxisColumn.dataKey}
                fill={chartColors[chartConfiguration.displaySettings.color]}
                isAnimationActive={isAnimationActive}
                name={yAxisColumn.column.label}
                radius={[2, 2, 0, 0]}
              >
                <LabelList
                  content={<BarLabel />}
                  dataKey={yAxisColumn.column.identifier}
                  formatter={yAxisColumn.formatValue}
                  position="top"
                />
              </Bar>

              <XAxis
                axisLine={{ stroke: theme.ds.colors.gray[500] }}
                dataKey={xAxisColumn.dataKey}
                height={24}
                tick={<XAxisTick />}
                tickFormatter={xAxisColumn.formatTick}
                tickLine={false}
              />

              <YAxis
                axisLine={false}
                dataKey={yAxisColumn.dataKey}
                orientation="left"
                tick={<YAxisTick />}
                tickFormatter={yAxisColumn.formatTick}
                tickLine={false}
                tickMargin={8}
                width={yAxisWidth ? yAxisWidth + 8 : undefined}
              />

              {(chartData?.items.length ?? 0) > MAX_COLUMNS_FOR_PREVIEW && (
                <Brush
                  dataKey="xAxisColumn.dataKey"
                  endIndex={brushEndIndex}
                  height={20}
                  startIndex={0}
                  stroke={theme.ds.colors[ColorCategory.GRAY]['200']}
                />
              )}
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      <ChartLegend seriesList={seriesList} />
    </Stack>
  );
});
