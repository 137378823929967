import { IconDatabase, IconUserCircle } from '@tabler/icons-react';
import { isNil } from 'lodash';
import moment from 'moment';
import { memo, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';

import { routes } from '@amalia/core/routes';
import { type OverwriteResponse, formatDate, formatDatasetCell } from '@amalia/core/types';
import { type RecordContentPropertyType } from '@amalia/data-capture/connectors/types';
import {
  FieldValuePrettyFormat,
  getPrettyFormatProps,
  isFieldValuePrettyFormatCompatible,
  LabelVariant,
  UserPrettyFormat,
} from '@amalia/data-capture/fields/components';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { OverwriteTooltip } from '@amalia/data-correction/overwrites/components';
import { ColumnHelper, DataGrid, type DataGridProps, Table } from '@amalia/design-system/components';
import { Link } from '@amalia/ext/react-router-dom';
import { isEnum } from '@amalia/ext/typescript';
import { objectToQs } from '@amalia/ext/web';
import {
  clearOverwrite as clearOverwriteAction,
  useThunkDispatch,
  fetchCustomObjectDefinitions,
  selectCustomObjectDefinitionsMapById,
  selectIsCustomObjectDefinitionsLoading,
  selectOverwritesIsLoading,
} from '@amalia/frontend/web-data-layers';

import { overwriteScopeMessages, overwriteTypeMessages } from './OverwritesTable.messages';

const columnHelper = new ColumnHelper<OverwriteResponse>();

export type OverwritesTableProps = Omit<
  DataGridProps<OverwriteResponse, OverwriteResponse['id']>,
  'columns' | 'isLoading' | 'rowKey'
>;

export const OverwritesTable = memo(function OverwritesTable({ ...props }: OverwritesTableProps) {
  const dispatch = useThunkDispatch();
  const { formatMessage } = useIntl();

  const isCustomObjectDefinitionsLoading = useSelector(selectIsCustomObjectDefinitionsLoading);
  const isOverwritesLoading = useSelector(selectOverwritesIsLoading);
  const customObjectDefinitionsById = useSelector(selectCustomObjectDefinitionsMapById);

  useAsyncEffect(async () => {
    await dispatch(fetchCustomObjectDefinitions());
  }, [dispatch]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('scope', {
        id: 'overwrite_scope',
        header: formatMessage({ defaultMessage: 'Scope' }),
        isSortable: true,
        cell: ({ value: scope }) => formatMessage(overwriteScopeMessages[scope]),
      }),
      columnHelper.accessor('statement', {
        id: 'user_firstname',
        header: formatMessage({ defaultMessage: 'User name' }),
        isSortable: true,
        size: 240,
        cell: ({ value: statement }) => (
          <Table.Cell.Text>
            {statement?.user ? (
              <UserPrettyFormat
                firstName={statement.user.firstName}
                lastName={statement.user.lastName}
                pictureURL={statement.user.pictureURL}
              />
            ) : null}
          </Table.Cell.Text>
        ),
      }),
      columnHelper.accessor('statement', {
        id: 'period_startDate',
        header: formatMessage({ defaultMessage: 'Period' }),
        isSortable: true,
        cell: ({ value: statement }) => <Table.Cell.Text>{statement?.period?.name}</Table.Cell.Text>,
      }),
      columnHelper.accessor('statement', {
        id: 'plan_name',
        header: formatMessage({ defaultMessage: 'Plan' }),
        isSortable: true,
        cell: ({ value: statement }) => <Table.Cell.Text>{statement?.plan.name}</Table.Cell.Text>,
      }),
      columnHelper.accessor('rule', {
        id: 'rule_name',
        header: formatMessage({ defaultMessage: 'Rule' }),
        isSortable: true,
        cell: ({ value: rule }) => <Table.Cell.Text>{rule?.name}</Table.Cell.Text>,
      }),
      columnHelper.accessor('overwriteType', {
        id: 'overwrite_type',
        header: formatMessage({ defaultMessage: 'Type' }),
        isSortable: true,
        cell: ({ value: overwriteType }) => formatMessage(overwriteTypeMessages[overwriteType]),
      }),
      columnHelper.accessor('creator', {
        id: 'creator_firstname',
        header: formatMessage({ defaultMessage: 'Overwritten by' }),
        isSortable: true,
        size: 240,
        cell: ({ value: creator }) => (
          <Table.Cell.Text>
            <UserPrettyFormat
              firstName={creator.firstName}
              lastName={creator.lastName}
              pictureURL={creator.pictureURL}
            />
          </Table.Cell.Text>
        ),
      }),
      columnHelper.display({
        id: 'customobject_externalid',
        header: formatMessage({ defaultMessage: 'Record' }),
        isSortable: true,
        cell: ({ row: overwrite }) => (
          <Table.Cell.Text>{overwrite?.appliesToDealName || overwrite?.appliesToExternalId}</Table.Cell.Text>
        ),
      }),
      columnHelper.accessor('propertyName', {
        id: 'overwrite_field',
        header: formatMessage({ defaultMessage: 'Variable' }),
        isSortable: true,
      }),
      columnHelper.display({
        id: 'overwrite_overwritevalue',
        header: formatMessage({ defaultMessage: 'Value' }),
        isSortable: true,
        size: 200,
        cell: ({ row: overwrite }) => {
          // Here we're struggling a bit to retrieve the value.
          // For overwrites over object variables, it's in the key of a JSON in the field.
          // For the other, we have it directly.
          // Be careful, in JS typeof null is object, meaning we have to assert it isn't null first.
          const maybePercentFormat = overwrite.propertyFormat === FormatsEnum.percent ? FormatsEnum.percent : undefined;

          const overwriteValue =
            !!overwrite.overwriteValue &&
            typeof overwrite.overwriteValue === 'object' &&
            Object.keys(overwrite.overwriteValue).includes(overwrite.field)
              ? (overwrite.overwriteValue?.[overwrite.field] as RecordContentPropertyType)
              : (overwrite.overwriteValue as number | string);

          const sourceValue =
            !!overwrite.sourceValue &&
            typeof overwrite.sourceValue === 'object' &&
            Object.keys(overwrite.sourceValue).includes(overwrite.field)
              ? (overwrite.sourceValue?.[overwrite.field] as RecordContentPropertyType)
              : (overwrite.sourceValue as number | string);

          const format: FormatsEnum | undefined = isEnum(overwrite.propertyFormat, FormatsEnum)
            ? overwrite.propertyFormat
            : undefined;

          const propertyRef =
            customObjectDefinitionsById?.[overwrite?.appliesToDefinitionId]?.properties?.[overwrite?.field]?.ref;

          return (
            // Set tooltipContent to empty string to avoid the tooltip going over the popover.
            // The untrucated value is in the tooltip popover anyway.
            <Table.Cell.Text tooltipContent="">
              <OverwriteTooltip
                isReadOnly
                author={`${overwrite.creator.firstName} ${overwrite.creator.lastName}`}
                date={moment.utc(overwrite.createdAt, 'YYYY-MM-DD').format('MMMM Do, YYYY')}
                newValue={formatDatasetCell(overwriteValue, maybePercentFormat)}
                oldValue={formatDatasetCell(sourceValue, maybePercentFormat)}
                valueFormat={format}
                valueRef={propertyRef}
              >
                {!isNil(overwriteValue) ? (
                  isFieldValuePrettyFormatCompatible(format, propertyRef) ? (
                    <FieldValuePrettyFormat
                      {...getPrettyFormatProps(format, overwriteValue, propertyRef)}
                      valueRef={propertyRef}
                      variant={LabelVariant.ACCENTUATED}
                    />
                  ) : (
                    formatDatasetCell(overwriteValue, maybePercentFormat)
                  )
                ) : null}
              </OverwriteTooltip>
            </Table.Cell.Text>
          );
        },
      }),
      columnHelper.accessor('clearedAt', {
        id: 'overwrite_clearedat',
        header: formatMessage({ defaultMessage: 'Cleared at' }),
        isSortable: true,
        cell: ({ value: clearedAt, row: overwrite }) =>
          clearedAt ? (
            <Table.Cell.Text>{formatDate(clearedAt, 'YYYY-MM-DD')}</Table.Cell.Text>
          ) : (
            <Table.Cell.Actions>
              <Table.Cell.Action
                onClick={async () => {
                  await dispatch(clearOverwriteAction(overwrite));
                }}
              >
                <FormattedMessage defaultMessage="Clear" />
              </Table.Cell.Action>
            </Table.Cell.Actions>
          ),
      }),
      columnHelper.accessor('createdAt', {
        id: 'overwrite_createdat',
        header: formatMessage({ defaultMessage: 'Overwritten date' }),
        isSortable: true,
        cell: ({ value: createdAt }) => (
          <Table.Cell.Text>{createdAt ? formatDate(createdAt, 'YYYY-MM-DD') : null}</Table.Cell.Text>
        ),
      }),
      columnHelper.display({
        id: Table.Cell.Actions.columnId,
        header: '',
        size: 0,
        cell: ({ row: overwrite }) => (
          <Table.Cell.Actions>
            {!!overwrite.statement && (
              <Link
                openInNewTab
                to={generatePath(routes.STATEMENT, { id: overwrite.createdOnStatementId })}
              >
                <Table.Cell.IconAction
                  icon={<IconUserCircle />}
                  label={formatMessage({ defaultMessage: 'Go to statement' })}
                />
              </Link>
            )}

            {!!(overwrite.appliesToDealName || overwrite.appliesToExternalId) && (
              <Link
                to={
                  overwrite.customObjectDefinitionMachineName
                    ? generatePath(routes.CAPTURED_RECORD_MODEL_DETAILS, {
                        modelMachineName: overwrite.customObjectDefinitionMachineName,
                      })
                    : {
                        pathname: generatePath(routes.CAPTURED_RECORD_MODEL_DETAILS, { modelMachineName: null }),
                        search: `?${objectToQs({ search: overwrite.appliesToExternalId })}`,
                      }
                }
              >
                <Table.Cell.IconAction
                  icon={<IconDatabase />}
                  label={formatMessage({ defaultMessage: 'Go to record' })}
                />
              </Link>
            )}
          </Table.Cell.Actions>
        ),
      }),
    ],
    [formatMessage, dispatch, customObjectDefinitionsById],
  );

  return (
    <DataGrid
      {...props}
      columns={columns}
      isLoading={isOverwritesLoading || isCustomObjectDefinitionsLoading}
      rowKey="id"
    />
  );
});
