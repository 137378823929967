import { useTheme } from '@emotion/react';
import { IconDots, IconHome, IconHomeOff, IconTrash } from '@tabler/icons-react';
import { Fragment, memo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { IconButton, MenuDropdown } from '@amalia/design-system/components';
import { ColorCategory } from '@amalia/design-system/meta';
import { useBoolState } from '@amalia/ext/react/hooks';
import { DashboardV2DeleteModal } from '@amalia/reporting/dashboards-v2/components';
import { type Dashboard } from '@amalia/reporting/dashboards-v2/types';

import { DashboardSetHomeModal } from '../modals/DashboardSetHomeModal';
import { DashboardUnsetHomeModal } from '../modals/DashboardUnsetHomeModal';

type DashboardV2DetailsHeaderMenuProps = {
  readonly canModifyDashboard: boolean;
  readonly dashboard?: Dashboard;
  readonly isDashboardSetAsHome: boolean;
};

export const DashboardV2DetailsHeaderMenu = memo(function DashboardV2DetailsHeaderMenu({
  canModifyDashboard,
  dashboard,
  isDashboardSetAsHome,
}: DashboardV2DetailsHeaderMenuProps) {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const navigate = useNavigate();

  const { isSetHomeModalOpen, setSetHomeModalOpenFalse, setSetHomeModalOpenTrue } = useBoolState(
    false,
    'setHomeModalOpen',
  );
  const { isUnsetHomeModalOpen, setUnsetHomeModalOpenFalse, setUnsetHomeModalOpenTrue } = useBoolState(
    false,
    'unsetHomeModalOpen',
  );

  const { isDeleteDashboardModalOpen, setDeleteDashboardModalOpenTrue, setDeleteDashboardModalOpenFalse } =
    useBoolState(false, 'deleteDashboardModalOpen');

  const onDeleteDashboard = useCallback(() => {
    setDeleteDashboardModalOpenFalse();
    navigate(generatePath(routes.DASHBOARDS_V2));
  }, [navigate, setDeleteDashboardModalOpenFalse]);

  return (
    <Fragment>
      <MenuDropdown
        content={
          <Fragment>
            {isDashboardSetAsHome ? (
              <MenuDropdown.Item
                icon={<IconHomeOff />}
                label={formatMessage({ defaultMessage: 'Remove from home' })}
                onClick={setUnsetHomeModalOpenTrue}
              />
            ) : (
              <MenuDropdown.Item
                icon={<IconHome />}
                label={formatMessage({ defaultMessage: 'Set as home' })}
                onClick={setSetHomeModalOpenTrue}
              />
            )}

            {!!canModifyDashboard && (
              <MenuDropdown.Item
                icon={<IconTrash color={theme.ds.colors.danger[500]} />}
                label={formatMessage({ defaultMessage: 'Delete dashboard' })}
                onClick={setDeleteDashboardModalOpenTrue}
              />
            )}
          </Fragment>
        }
      >
        <MenuDropdown.IconButton
          withBackground
          aria-label={formatMessage({ defaultMessage: 'Open actions' })}
          icon={<IconDots color={theme.ds.colors[ColorCategory.PRIMARY][500]} />}
          label={formatMessage({ defaultMessage: 'Open actions' })}
          size={IconButton.Size.MEDIUM}
        />
      </MenuDropdown>
      {!!dashboard && (
        <DashboardSetHomeModal
          dashboardId={dashboard.id}
          dashboardName={dashboard.name}
          isOpen={isSetHomeModalOpen}
          onCloseModal={setSetHomeModalOpenFalse}
        />
      )}
      {!!dashboard && (
        <DashboardUnsetHomeModal
          dashboardName={dashboard.name}
          isOpen={isUnsetHomeModalOpen}
          onCloseModal={setUnsetHomeModalOpenFalse}
        />
      )}
      {!!dashboard && !!canModifyDashboard && (
        <DashboardV2DeleteModal
          dashboard={dashboard}
          isOpen={isDeleteDashboardModalOpen}
          onClose={onDeleteDashboard}
        />
      )}
    </Fragment>
  );
});
