import { useMemo } from 'react';
import { generatePath, type To } from 'react-router-dom';

import { type VariableObjectsEnum } from '@amalia/amalia-lang/tokens/types';
import { routes } from '@amalia/core/routes';
import { type Statement } from '@amalia/core/types';
import { objectToQs } from '@amalia/ext/web';
import {
  getCustomObjectDefinitionFromDefinitionIdInStatemnet,
  getDatasetNameFromStatementDefinition,
  getRuleDefinitionFromIdInStatement,
  getVariableDefinitionFromIdInStatement,
  getVariableDefinitionFromMachineNameInStatement,
} from '@amalia/frontend/web-data-layers';
import { type StatementThreadScope, StatementThreadScopesType } from '@amalia/payout-collaboration/comments/types';

export interface CommentScopeInterface {
  rule?: string;
  variable?: string;
  dataset?: string;
  dataSource?: {
    label?: string;
    link?: To;
    type?: VariableObjectsEnum;
  };
}

export const useCommentScope = (statement: Statement, scope?: StatementThreadScope): CommentScopeInterface =>
  useMemo(() => {
    if (!scope) {
      return {};
    }

    if (scope.type === StatementThreadScopesType.OBJECT) {
      // Get rule and variables name
      const rule = scope.ruleId && getRuleDefinitionFromIdInStatement(statement.results, scope.ruleId)?.name;

      const variableName =
        getVariableDefinitionFromMachineNameInStatement(statement.results, scope.field)?.name || scope.field;

      const variableType = getVariableDefinitionFromMachineNameInStatement(statement.results, scope.field)?.type;

      // Get custom object definition and field from schema
      const customObjectDefinition = getCustomObjectDefinitionFromDefinitionIdInStatemnet(
        statement.results,
        scope.definitionId,
      );

      let link: To | undefined;
      if (customObjectDefinition) {
        link = {
          pathname: generatePath(routes.CAPTURED_RECORD_MODEL_DETAILS, {
            modelMachineName: customObjectDefinition.machineName,
          }),
          search: `?${objectToQs({ search: scope.id })}`,
        };
      }

      const dataset = scope.datasetMachineName
        ? getDatasetNameFromStatementDefinition(statement.results, scope.datasetMachineName)
        : undefined;

      return {
        dataset,
        rule,
        dataSource: {
          label: scope.name ? scope.name : scope.id,
          link,
          type: variableType,
        },
        variable: variableName,
      };
    }

    // Get rule name and KPI variable name
    const ruleName = scope.ruleId && getRuleDefinitionFromIdInStatement(statement.results, scope.ruleId)?.name;
    const variableName = scope.id && getVariableDefinitionFromIdInStatement(statement.results, scope.id)?.name;

    return {
      rule: ruleName,
      variable: variableName,
    };
  }, [scope, statement]);
