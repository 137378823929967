import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { useClipboard } from '@amalia/design-system/ext';
import { openInNewTab } from '@amalia/ext/web';
import { useImpersonateUserMutation } from '@amalia/superadmin/state';
import { tenantUsersRoutes } from '@amalia/tenants/users/profile/shared';

import { useShortcutCommandContext } from '../ShortcutCommands.context';
import { PagesEnum } from '../shortcutCommands.types';

export type Action = {
  title?: string;
  onSelect: (value: string) => void;
};

type UseActionsReturnType = {
  primaryAction: Action | null;
  secondaryAction: Action | null;
  shiftSecondaryAction: Action | null;
  shiftPrimaryAction: Action | null;
};

export const useActions = (page: PagesEnum | undefined): UseActionsReturnType => {
  const { formatMessage } = useIntl();
  const { closeDialog } = useShortcutCommandContext();
  const navigate = useNavigate();
  const { mutate: impersonateUser } = useImpersonateUserMutation({ redirectToHome: true });

  const { copy } = useClipboard();

  const { primaryAction, secondaryAction, shiftSecondaryAction, shiftPrimaryAction } = useMemo(() => {
    let primaryAction: Action | null = null;
    let shiftPrimaryAction: Action | null = null;
    let secondaryAction: Action | null = null;
    let shiftSecondaryAction: Action | null = null;
    switch (page) {
      case undefined:
        break;
      case PagesEnum.LIST_COMPANIES:
        primaryAction = {
          onSelect: (adminEmail) => {
            closeDialog();
            impersonateUser(adminEmail);
          },
          title: formatMessage({ defaultMessage: 'Impersonate company' }),
        };
        secondaryAction = {
          onSelect: (companyId: string) => {
            closeDialog();
            navigate(generatePath(routes.SUPERADMIN_COMPANY, { companyId }));
          },
          title: formatMessage({ defaultMessage: 'Go to super-admin company settings' }),
        };
        shiftSecondaryAction = {
          onSelect: (companyId: string) => {
            closeDialog();
            openInNewTab(generatePath(routes.SUPERADMIN_COMPANY, { companyId }));
          },
        };
        break;
      case PagesEnum.LIST_PLANS:
        primaryAction = {
          onSelect: (planId: string) => {
            closeDialog();
            navigate(generatePath(routes.DESIGNER_PLAN, { planId }));
          },
          title: formatMessage({ defaultMessage: 'Open in Designer' }),
        };
        shiftPrimaryAction = {
          onSelect: (planId: string) => {
            closeDialog();
            openInNewTab(generatePath(routes.DESIGNER_PLAN, { planId }));
          },
        };
        secondaryAction = {
          onSelect: (planId: string) => {
            closeDialog();
            navigate(generatePath(routes.PLAN_ASSIGNMENT, { planId }));
          },
          title: formatMessage({ defaultMessage: 'Go to plan assignments' }),
        };
        shiftSecondaryAction = {
          onSelect: (planId: string) => {
            closeDialog();
            openInNewTab(generatePath(routes.PLAN_ASSIGNMENT, { planId }));
          },
        };
        break;
      case PagesEnum.LIST_USERS:
        primaryAction = {
          onSelect: (value: string) => {
            closeDialog();
            navigate(generatePath(tenantUsersRoutes.MEMBER_PAGE, { memberId: getIdFromValue(value) }));
          },
          title: formatMessage({ defaultMessage: 'Open in directory page' }),
        };
        shiftPrimaryAction = {
          onSelect: (value: string) => {
            closeDialog();
            openInNewTab(generatePath(tenantUsersRoutes.MEMBER_PAGE, { memberId: getIdFromValue(value) }));
          },
        };
        secondaryAction = {
          onSelect: (value: string) => {
            closeDialog();
            copy(getSecondArgumentFromValue(value));
          },
          title: formatMessage({ defaultMessage: 'Copy external ID' }),
        };
        break;
      case PagesEnum.LIST_REPORTS:
        primaryAction = {
          onSelect: (reportId: string) => {
            closeDialog();
            navigate(generatePath(routes.CUSTOM_REPORT_ITEM, { reportId }));
          },
          title: formatMessage({ defaultMessage: 'Go to report' }),
        };
        shiftPrimaryAction = {
          onSelect: (reportId: string) => {
            closeDialog();
            openInNewTab(generatePath(routes.CUSTOM_REPORT_ITEM, { reportId }));
          },
        };
        break;
      case PagesEnum.LIST_CUSTOM_OBJECTS:
        primaryAction = {
          onSelect: (machineName: string) => {
            closeDialog();
            navigate(generatePath(routes.CAPTURED_RECORD_MODEL_DETAILS, { modelMachineName: machineName }));
          },
          title: formatMessage({ defaultMessage: 'Go to custom object' }),
        };
        shiftPrimaryAction = {
          onSelect: (machineName: string) => {
            closeDialog();
            openInNewTab(generatePath(routes.CAPTURED_RECORD_MODEL_DETAILS, { modelMachineName: machineName }));
          },
        };
        break;
      case PagesEnum.LIST_TEAMS:
        primaryAction = {
          onSelect: (teamId: string) => {
            closeDialog();
            navigate(generatePath(routes.TEAM_DETAILS, { teamId }));
          },
          title: formatMessage({ defaultMessage: 'Go to team assignments' }),
        };
        shiftPrimaryAction = {
          onSelect: (teamId: string) => {
            closeDialog();
            openInNewTab(generatePath(routes.TEAM_DETAILS, { teamId }));
          },
        };
        break;
    }
    return { primaryAction, secondaryAction, shiftSecondaryAction, shiftPrimaryAction };
  }, [page, formatMessage, closeDialog, impersonateUser, navigate, copy]);

  return { primaryAction, secondaryAction, shiftSecondaryAction, shiftPrimaryAction };
};

// values are in format: id__label so we need to extract id to perform the actions
const getIdFromValue = (value: string) => value.split('__')[0];

const getSecondArgumentFromValue = (value: string) => value.split('__')[1];
