import { memo } from 'react';

import { type TokenType } from '@amalia/amalia-lang/tokens/types';
import { MenuDropdown, type MenuDropdownProps } from '@amalia/design-system/components';

import { newObjectSupportedTokenTypes } from './DesignerNewObjectModal.types';
import { DesignerNewObjectModalItem } from './DesignerNewObjectModalItem';

export interface DesignerNewObjectDropdownProps {
  readonly onCreate: (type: TokenType) => void;
  readonly children: MenuDropdownProps['children'];
}

export const DesignerNewObjectDropdown = memo(function DesignerNewObjectDropdown({
  onCreate,
  children,
}: DesignerNewObjectDropdownProps) {
  return (
    <MenuDropdown
      content={
        <div>
          {newObjectSupportedTokenTypes.map((type) => (
            <DesignerNewObjectModalItem
              key={type}
              type={type}
              onCreate={onCreate}
            />
          ))}
        </div>
      }
    >
      {children}
    </MenuDropdown>
  );
});
