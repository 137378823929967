import { css } from '@emotion/react';
import { useFormikContext } from 'formik';
import { Fragment, memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { Checkbox, Divider, FormLayout, Typography } from '@amalia/design-system/components';
import { ComponentSwitch } from '@amalia/ext/react/components';
import {
  ChartDisplaySettingsXAxis,
  type ChartDisplaySettings,
  type ChartType,
  type DashboardChartConfiguration,
} from '@amalia/reporting/dashboards-v2/types';

import { DashboardChartConfigurationModalLabel } from '../../../DashboardChartConfigurationModalLabel';

type SimpleLineChartFiltersProps = Pick<ChartDisplaySettings<ChartType>, 'filters'> & {
  readonly onFilterChange: (
    filterName: keyof ChartDisplaySettings<ChartType>['filters'],
    newChecked: boolean,
  ) => Promise<void>;
};

export const SimpleLineChartFilters = memo(function SimpleLineChartFilters({
  filters,
  onFilterChange,
}: SimpleLineChartFiltersProps) {
  const { values } = useFormikContext<DashboardChartConfiguration<ChartType.SIMPLE_BAR_CHART>>();

  const handleChangeFilterStatus = useCallback(
    (filterName: keyof ChartDisplaySettings<ChartType>['filters']) => async (newChecked: boolean) => {
      await onFilterChange(filterName, newChecked);
    },
    [onFilterChange],
  );

  return (
    <Fragment>
      <FormLayout.Group>
        <DashboardChartConfigurationModalLabel variant={Typography.Variant.BODY_BASE_MEDIUM}>
          <FormattedMessage defaultMessage="X-axis" />
        </DashboardChartConfigurationModalLabel>

        <ComponentSwitch value={values.displaySettings.xAxis.identifier}>
          <ComponentSwitch.Item value={ChartDisplaySettingsXAxis.PERIOD}>
            <Checkbox
              checked={!!filters.periods.isEnabled}
              label={<FormattedMessage defaultMessage="Show filter on periods" />}
              onChange={handleChangeFilterStatus('periods')}
            />
          </ComponentSwitch.Item>

          <ComponentSwitch.Item value={ChartDisplaySettingsXAxis.USER}>
            <Checkbox
              checked={!!filters.users.isEnabled}
              label={<FormattedMessage defaultMessage="Show filter on users" />}
              onChange={handleChangeFilterStatus('users')}
            />
          </ComponentSwitch.Item>
        </ComponentSwitch>
      </FormLayout.Group>

      <Divider.Horizontal
        css={(theme) => css`
          margin: 0 -32px;
          width: calc(100% + 64px);
          background-color: ${theme.ds.colors.gray[100]};
        `}
      />

      <FormLayout.Group>
        <DashboardChartConfigurationModalLabel variant={Typography.Variant.BODY_BASE_MEDIUM}>
          <FormattedMessage defaultMessage="Y-axis" />
        </DashboardChartConfigurationModalLabel>

        <ComponentSwitch value={values.displaySettings.xAxis.identifier}>
          <ComponentSwitch.Item value={ChartDisplaySettingsXAxis.PERIOD}>
            <Checkbox
              checked={!!filters.teams.isEnabled}
              label={<FormattedMessage defaultMessage="Show filter on teams" />}
              onChange={handleChangeFilterStatus('teams')}
            />

            <Checkbox
              checked={!!filters.users.isEnabled}
              label={<FormattedMessage defaultMessage="Show filter on users" />}
              onChange={handleChangeFilterStatus('users')}
            />
          </ComponentSwitch.Item>

          <ComponentSwitch.Item value={ChartDisplaySettingsXAxis.USER}>
            <Checkbox
              checked={!!filters.periods.isEnabled}
              label={<FormattedMessage defaultMessage="Show filter on periods" />}
              onChange={handleChangeFilterStatus('periods')}
            />

            <Checkbox
              checked={!!filters.teams.isEnabled}
              label={<FormattedMessage defaultMessage="Show filter on teams" />}
              onChange={handleChangeFilterStatus('teams')}
            />
          </ComponentSwitch.Item>
        </ComponentSwitch>
      </FormLayout.Group>
    </Fragment>
  );
});
