import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { assert } from '@amalia/ext/typescript';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';

export default new AmaliaFunctionDefault<[string | null | undefined, number | undefined], string>({
  name: AmaliaFunctionKeys.LEFT,
  category: AmaliaFunctionCategory.STRING,
  nbParamsRequired: 1,
  description: 'Return the left part of a string based on a given number of characters',

  exec: (text, numChars) => {
    assert(text !== null && text !== undefined, `${AmaliaFunctionKeys.LEFT}: source string is null or undefined`);

    return text.toString().substring(0, numChars || 1);
  },

  params: [
    { name: 'string', description: 'String: variables, fields, properties, string' },
    { name: 'numChars', description: 'Number of characters to take, by default it is 1', defaultValue: '1' },
  ],

  examples: [
    {
      desc: 'Returns co.',
      formula: 'LEFT("commission", 2)' as AmaliaFormula,
      result: 'co',
    },
    {
      desc: 'Returns c.',
      formula: 'LEFT("commission")' as AmaliaFormula,
      result: 'c',
    },
    {
      desc: 'Returns commission.',
      formula: 'LEFT("commission", 24)' as AmaliaFormula,
      result: 'commission',
    },
  ],
});
