import { useTheme } from '@emotion/react';
import {
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
  useInteractions,
  useMergeRefs,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react';
import { type Ref } from 'react';

export const useBulkActionsPopover = ({
  anchorRef,
  enabled = true,
}: { anchorRef?: Ref<unknown>; enabled?: boolean } = {}) => {
  const theme = useTheme();

  // Create a new floating context.
  const { refs, floatingStyles, context } = useFloating<HTMLDivElement>({
    placement: 'right',
    strategy: 'fixed',
    open: enabled,
    whileElementsMounted: autoUpdate,
    middleware: [
      // Distance to the anchor.
      offset(8),
      // Should change placement if not enough space.
      flip(),
      // Should move the popover if too close to the edge.
      shift({ padding: 8 }),
    ],
  });

  // Add a transition on show/hide popover. Use the default transition parameters.
  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
    duration: theme.ds.transitions.default.durationMs,
    common: {
      transitionTimingFunction: theme.ds.transitions.default.easing,
    },
  });

  // Add accessibility role of menu.
  const role = useRole(context, { role: 'menu', enabled });

  // Register interactions declared above.
  const { getReferenceProps, getFloatingProps } = useInteractions([role]);

  // Merge the child ref with the reference from floating-ui.
  const referenceRef = useMergeRefs([refs.setReference, anchorRef]);

  return {
    referenceRef,
    floatingRef: refs.setFloating,
    isMounted,
    floatingStyles,
    transitionStyles,
    getReferenceProps,
    getFloatingProps,
  };
};
