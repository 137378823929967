import { type FieldHookConfig, type FieldInputProps, useField } from 'formik';
import { type ReactNode, useCallback, useMemo } from 'react';

import { type Merge } from '@amalia/ext/typescript';

import { type InputType } from '../../types/inputType';

export type UseFormikFieldAdapterOptions<TValue = unknown, TType extends InputType = 'text'> = {
  name: FieldHookConfig<TValue>['name'];
  type?: TType;
  validate?: FieldHookConfig<TValue>['validate'];
};

export type UseFormikFieldAdapterValue<TValue = unknown, TType extends InputType = 'text'> = Merge<
  FieldInputProps<TValue>,
  {
    onChange: (value: TType extends 'checkbox' ? boolean : TValue) => void;
    onBlur: (event: unknown) => void;
    error?: ReactNode;
  }
>;

export const useFormikFieldAdapter = <TValue = unknown, TType extends InputType = 'text'>({
  name,
  type = 'text' as TType,
  validate = undefined,
}: UseFormikFieldAdapterOptions<TValue, TType>): UseFormikFieldAdapterValue<TValue, TType> => {
  const [{ onBlur, ...inputProps }, { touched, error }, { setValue }] = useField<TValue>({
    name,
    type,
    validate,
  });

  const onChange: UseFormikFieldAdapterValue<TValue, typeof type>['onChange'] = useCallback(
    (value) => {
      setValue(value as Parameters<typeof setValue>[0], true).catch(() => {
        // Ignore, Formik returns validation errors.
      });
    },
    [setValue],
  );

  // Bind onBlur to the field name.
  // When called with a string, Formik returns a new onBlur function bound to the parameter.
  const onBlurProxy = useMemo(() => onBlur(name), [onBlur, name]);

  return {
    ...inputProps,
    onChange,
    onBlur: onBlurProxy,
    error: touched ? error : undefined,
  };
};
