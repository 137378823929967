import { type ComputedTeam, type Statement } from '@amalia/core/types';

export const getComputedTeamInStatementResults = (statement?: Statement): ComputedTeam | null => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- review the type, result can be not selected
  if (!statement?.results) {
    return null;
  }

  // If the teamUsedDuringCalculationId is defined, get it, or default on the first team.
  const computedTeam: ComputedTeam | undefined =
    (statement.results.teamUsedDuringCalculationId &&
      statement.results.computedTeams?.find((t) => t.teamId === statement.results.teamUsedDuringCalculationId)) ||
    statement.results.computedTeams?.[0];

  return computedTeam || null;
};
