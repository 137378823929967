import { css } from '@emotion/react';
import { memo } from 'react';
import { type LabelProps } from 'recharts';

import { ColorCategory } from '@amalia/design-system/meta';

export type BarLabelProps = LabelProps;

export const BarLabel = memo(function BarLabel({ value, formatter, x, y, width }: BarLabelProps) {
  return (
    <text
      dx={parseInt(`${width || 0}`, 10) / 2}
      dy={-8}
      textAnchor="middle"
      x={x}
      y={y}
      css={(theme) => css`
        ${theme.ds.typographies.bodyXsmallMedium};
        fill: ${theme.ds.colors[ColorCategory.GRAY][900]};
      `}
    >
      {formatter?.(value)}
    </text>
  );
});
