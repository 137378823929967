import { isNil } from 'lodash';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { formatDate } from '@amalia/core/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { assert } from '@amalia/ext/typescript';
import { isCurrencyValue } from '@amalia/kernel/monetary/types';
import { type CustomReportValue } from '@amalia/reporting/custom-reports/shared';

const getDateFormat = ({ isQuarterPeriod, compact }: { isQuarterPeriod: boolean; compact: boolean }) =>
  isQuarterPeriod ? '[Q]Q YY' : compact ? 'MMM YY' : 'MMMM YYYY';

export const useFormatChartValue = () => {
  const { formatNumber } = useIntl();
  return useCallback(
    ({
      value,
      format,
      compact = false,
      isQuarterPeriod = false,
    }: {
      /** Value to format. */
      value?: CustomReportValue;
      /** Format of the value. */
      format: FormatsEnum;
      /** e.g. compact mode for chart, non compact mode for tooltip. */
      compact?: boolean;
      /** Different label for period based on quarter or month */
      isQuarterPeriod?: boolean;
    }): string => {
      if (isNil(value)) {
        return '';
      }

      switch (format) {
        case FormatsEnum.currency: {
          assert(isCurrencyValue(value), 'Value is not a currency value');
          return value.value !== null
            ? formatNumber(
                value.value,
                compact
                  ? {
                      notation: 'compact',
                      compactDisplay: 'short',
                      style: FormatsEnum.currency,
                      currency: value.symbol,
                    }
                  : {
                      style: FormatsEnum.currency,
                      currency: value.symbol,
                      maximumFractionDigits: 2,
                    },
              )
            : '';
        }

        case FormatsEnum.number: {
          assert(typeof value === 'number', 'Value is not a number');
          return formatNumber(
            value,
            compact
              ? {
                  notation: 'compact',
                  compactDisplay: 'short',
                }
              : { maximumFractionDigits: 2 },
          );
        }

        case FormatsEnum.percent: {
          assert(typeof value === 'number', 'Value is not a number');
          return formatNumber(
            value,
            compact
              ? {
                  notation: 'compact',
                  compactDisplay: 'short',
                  style: 'percent',
                }
              : {
                  maximumFractionDigits: 2,
                  style: 'percent',
                },
          );
        }

        case FormatsEnum.date: {
          assert(typeof value === 'string', 'Value is not a string');
          return formatDate(value, getDateFormat({ isQuarterPeriod, compact }));
        }

        default:
          return `${value as string}`;
      }
    },
    [formatNumber],
  );
};
