import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { Modal, Typography } from '@amalia/design-system/components';
import { useDeleteDashboard } from '@amalia/reporting/dashboards-v2/state';
import { type Dashboard } from '@amalia/reporting/dashboards-v2/types';

type DashboardV2DeleteModalProps = {
  readonly dashboard: Dashboard;
  readonly onClose: () => void;
  readonly isOpen: boolean;
};

export const DashboardV2DeleteModal = memo(function DashboardV2DeleteModal({
  dashboard,
  onClose,
  isOpen,
}: DashboardV2DeleteModalProps) {
  const { mutate: deleteDashboard, isPending: isDeleteDashboardPending } = useDeleteDashboard(dashboard.id);

  const handleDeleteDashboard = useCallback(() => {
    deleteDashboard();
    onClose();
  }, [deleteDashboard, onClose]);

  return (
    <Modal
      isDismissable
      isOpen={isOpen}
      variant={Modal.Variant.DANGER}
      onClose={onClose}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage defaultMessage="Delete dashboard" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Typography variant={Typography.Variant.BODY_BASE_REGULAR}>
            <FormattedMessage
              defaultMessage="Are you sure you want to delete <bold>{dashboardName}</bold> ?"
              values={{
                dashboardName: dashboard.name,
                bold: (chunks) => <Typography variant={Typography.Variant.BODY_BASE_BOLD}>{chunks}</Typography>,
              }}
            />
          </Typography>
        </Modal.Body>
      </Modal.Content>
      <Modal.Actions>
        <Modal.CancelAction />
        <Modal.MainAction
          isLoading={isDeleteDashboardPending}
          onClick={handleDeleteDashboard}
        >
          <FormattedMessage defaultMessage="Delete" />
        </Modal.MainAction>
      </Modal.Actions>
    </Modal>
  );
});
