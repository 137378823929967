import styled from '@emotion/styled';
import { IconChevronRight } from '@tabler/icons-react';
import { memo, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@amalia/design-system/components';

import { useDashboardChartConfigurationContext } from '../contexts/DashboardChartConfiguration.context';

const NextStepButtonContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 20px;
`;

interface DashboardChartConfigurationNextStepButtonProps {
  readonly isTabValid: boolean;
  readonly onClick: () => void;
}

export const DashboardChartConfigurationNextStepButton = memo(function DashboardChartConfigurationNextStepButton({
  isTabValid,
  onClick,
}: DashboardChartConfigurationNextStepButtonProps) {
  const nextStepButtonRef = useRef<HTMLButtonElement | null>(null);

  const { forMode } = useDashboardChartConfigurationContext();

  useEffect(() => {
    if (isTabValid && forMode === 'creation') {
      nextStepButtonRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [forMode, isTabValid]);

  return (
    isTabValid && (
      <NextStepButtonContainer>
        <Button
          ref={nextStepButtonRef}
          icon={<IconChevronRight />}
          iconPosition={Button.IconPosition.RIGHT}
          size={Button.Size.MEDIUM}
          variant={Button.Variant.PRIMARY}
          onClick={onClick}
        >
          <FormattedMessage defaultMessage="Next" />
        </Button>
      </NextStepButtonContainer>
    )
  );
});
