import { ChartType } from './dashboard-chart.types';
import { type DashboardChartConfiguration } from './requests/dashboard-chart-configuration.types';

export const isKpiCardChartConfiguration = (
  configuration: DashboardChartConfiguration<ChartType>,
): configuration is DashboardChartConfiguration<ChartType.KPI_CARD_CHART> =>
  configuration.type === ChartType.KPI_CARD_CHART;

export const isSimpleBarChartConfiguration = (
  configuration: DashboardChartConfiguration<ChartType>,
): configuration is DashboardChartConfiguration<ChartType.SIMPLE_BAR_CHART> =>
  configuration.type === ChartType.SIMPLE_BAR_CHART;

export const isSimpleLineChartConfiguration = (
  configuration: DashboardChartConfiguration<ChartType>,
): configuration is DashboardChartConfiguration<ChartType.SIMPLE_LINE_CHART> =>
  configuration.type === ChartType.SIMPLE_LINE_CHART;
