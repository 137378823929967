import styled from '@emotion/styled';
import { useFormikContext } from 'formik';
import { omit } from 'lodash';
import { Fragment, memo, useCallback, useMemo } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { formatToIcon } from '@amalia/data-capture/fields/components';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import {
  FormLayout,
  FormikSelect,
  RadioButtonGroup,
  Select,
  Typography,
  type SelectOption,
} from '@amalia/design-system/components';
import {
  CustomReportAggregationsForFormat,
  getCustomReportFieldDefinition,
  parseCustomReportFieldPosition,
  stringifyCustomReportFieldPosition,
  type CustomReport,
  type CustomReportAggregationOperation,
} from '@amalia/reporting/custom-reports/shared';
import { useCustomReportSourcesManifests } from '@amalia/reporting/custom-reports/state';
import { CustomReportAggregationLabel } from '@amalia/reporting/custom-reports/views';
import { DividerHorizontal } from '@amalia/reporting/dashboards-v2/components';
import {
  KPIChartDisplayMode,
  defaultGaugeSegmentsValue,
  type ChartType,
  type DashboardChartConfiguration,
} from '@amalia/reporting/dashboards-v2/types';

import { GaugeSegmentsValuesTable } from './GaugeSegmentsValuesTable';

const CustomInputLabel = styled(Typography)`
  color: ${({ theme }) => theme.ds.colors.gray[800]};
`;

interface KPICardChartContentProps {
  readonly customReports: CustomReport[];
}

const KpiChartDisplayModeMessages = defineMessages<KPIChartDisplayMode>({
  [KPIChartDisplayMode.KPI]: { defaultMessage: 'KPI only' },
  [KPIChartDisplayMode.DOUGHNUT]: { defaultMessage: 'Doughnut' },
  [KPIChartDisplayMode.GAUGE]: { defaultMessage: 'Gauge' },
});

export const KPICardChartContent = memo(function KPICardChartContent({ customReports }: KPICardChartContentProps) {
  const { formatMessage } = useIntl();

  const { values, setValues, setFieldValue } =
    useFormikContext<DashboardChartConfiguration<ChartType.KPI_CARD_CHART>>();

  const selectedReport = customReports.find((customReport) => customReport.id === values.customReportId);
  const { manifestsMap } = useCustomReportSourcesManifests(selectedReport ?? null, false);

  const selectedKPI = useMemo(
    () =>
      selectedReport ? getCustomReportFieldDefinition(selectedReport, manifestsMap, values.displaySettings.kpi) : null,
    [selectedReport, manifestsMap, values.displaySettings.kpi],
  );

  const kpisOptions = useMemo(
    () =>
      selectedReport && Object.keys(manifestsMap).length
        ? selectedReport.configuration.fields
            .map((field) => {
              const fieldDefinition = getCustomReportFieldDefinition(selectedReport, manifestsMap, field);

              if (!fieldDefinition) {
                return null;
              }

              return {
                value: stringifyCustomReportFieldPosition(field),
                label: `${field.alias || fieldDefinition.label}`,
                format: fieldDefinition.format,
                icon: formatToIcon[fieldDefinition.format],
              };
            })
            .filter(Boolean)
        : [],
    [selectedReport, manifestsMap],
  );

  const aggregationsOptions = useMemo(() => {
    const kpiOption = kpisOptions.find(
      ({ value }) => value === stringifyCustomReportFieldPosition(values.displaySettings.kpi),
    );

    if (!kpiOption) {
      return [];
    }

    return CustomReportAggregationsForFormat[kpiOption.format].map((agg) => ({
      value: agg,
      label: formatMessage(CustomReportAggregationLabel[agg]),
    }));
  }, [formatMessage, kpisOptions, values]);

  const handleChangeKPI = useCallback(
    async (newKPI: string | null) => {
      const newValues: DashboardChartConfiguration<ChartType.KPI_CARD_CHART> = {
        ...values,
        displaySettings: {
          ...omit(values.displaySettings, 'segmentsValue'),
          kpi: { ...values.displaySettings.kpi, ...parseCustomReportFieldPosition(newKPI!) },
          displayMode:
            selectedKPI?.format === FormatsEnum.percent &&
            values.displaySettings.displayMode === KPIChartDisplayMode.DOUGHNUT
              ? KPIChartDisplayMode.DOUGHNUT
              : KPIChartDisplayMode.KPI,
        },
      };
      await setValues(newValues);
    },
    [values, setValues, selectedKPI?.format],
  );

  const chartDisplayModeOptions = useMemo(
    () =>
      Object.values(KPIChartDisplayMode)
        .map((displayMode) => ({
          label: formatMessage(KpiChartDisplayModeMessages[displayMode]),
          value: displayMode,
          disabled:
            (displayMode === KPIChartDisplayMode.DOUGHNUT && selectedKPI?.format !== FormatsEnum.percent) ||
            (displayMode === KPIChartDisplayMode.GAUGE &&
              selectedKPI?.format &&
              ![FormatsEnum.number, FormatsEnum.percent, FormatsEnum.currency].includes(selectedKPI.format)),
        }))
        .filter(({ disabled }) => !disabled),
    [formatMessage, selectedKPI?.format],
  );

  const shouldRenderGaugeSegmentsTable =
    selectedKPI && values.displaySettings.displayMode === KPIChartDisplayMode.GAUGE;

  const handleChangeChartRadioButton = useCallback(
    async (value: KPIChartDisplayMode) => {
      await setFieldValue('displaySettings.displayMode', value);
      await setFieldValue(
        'displaySettings.segmentsValue',
        value === KPIChartDisplayMode.GAUGE ? defaultGaugeSegmentsValue : undefined,
      );
    },
    [setFieldValue],
  );

  return (
    <Fragment>
      <FormLayout.Group>
        <CustomInputLabel variant={Typography.Variant.BODY_BASE_MEDIUM}>
          <FormattedMessage defaultMessage="Value" />
        </CustomInputLabel>

        <Select<SelectOption<string>>
          required
          disabled={!selectedReport}
          id="displaySettings.kpi"
          options={kpisOptions}
          value={stringifyCustomReportFieldPosition(values.displaySettings.kpi)}
          placeholder={
            selectedReport
              ? formatMessage({ defaultMessage: 'Select KPI' })
              : formatMessage({ defaultMessage: 'Please select a report first' })
          }
          onChange={handleChangeKPI}
        />

        <FormikSelect<SelectOption<CustomReportAggregationOperation>>
          required
          disabled={!values.displaySettings.kpi.identifier}
          id="displaySettings.kpi.aggregation.operation"
          name="displaySettings.kpi.aggregation.operation"
          options={aggregationsOptions}
          placeholder={
            values.displaySettings.kpi.identifier
              ? formatMessage({ defaultMessage: 'Select aggregate' })
              : formatMessage({ defaultMessage: 'Please select a KPI first' })
          }
        />

        {chartDisplayModeOptions.length > 1 && (
          <RadioButtonGroup
            name="displaySettings.displayMode"
            options={chartDisplayModeOptions}
            size={RadioButtonGroup.Size.MEDIUM}
            value={values.displaySettings.displayMode}
            onChange={handleChangeChartRadioButton}
          />
        )}
      </FormLayout.Group>
      {!!shouldRenderGaugeSegmentsTable && (
        <Fragment>
          <DividerHorizontal />

          <GaugeSegmentsValuesTable kpiFormat={selectedKPI.format} />
        </Fragment>
      )}
    </Fragment>
  );
});
