import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';

export default new AmaliaFunctionDefault<number[], number>({
  name: AmaliaFunctionKeys.MAX,
  category: AmaliaFunctionCategory.NUMBERS,
  nbParamsRequired: 0,
  hasInfiniteParams: true,
  description: 'Return the maximum among parameters such as numbers, fields, properties, variables, formulas etc',

  exec: (...numbers) => Math.max(...numbers),

  params: [
    { name: 'param1', description: 'The first param to compare' },
    { name: 'param2', description: 'The second param to compare' },
    { name: 'paramN', description: 'The Nth param to compare' },
  ],

  examples: [
    {
      desc: 'Returns 8',
      formula: 'MAX(8, 3)' as AmaliaFormula,
      result: 8,
    },
    {
      desc: 'Returns 623',
      formula: 'MAX(10, 2, 0, 5, 623)' as AmaliaFormula,
      result: 623,
    },
    {
      desc: 'Returns the maximum between Commission and 0. This will return only positive Commission.',
      formula: 'MAX(0, statement.commission)',
    },
    {
      desc: 'Returns the most recent date between Close Date and Payment Date.',
      formula: 'MAX(opportunity.closeDate, statementPeriod.paymentDate)',
    },
  ],
});
