import { type Variable } from '@amalia/amalia-lang/tokens/types';
import { type Property } from '@amalia/data-capture/fields/types';

import { type Filter } from './filters';

export enum HidableElementVisibility {
  ON_DISPLAY = 'ON_DISPLAY',
  AVAILABLE = 'AVAILABLE',
}

export interface PlanRuleFilterToDisplay {
  id: NonNullable<Filter['id']>;
  displayStatus: HidableElementVisibility;
  fieldsToDisplay: PlanRuleFieldToDisplay[];
}

export interface PlanRuleFieldToDisplay {
  name: Property['machineName'] | Variable['machineName'];
  displayStatus: HidableElementVisibility;
}

export type PlanRuleKpiSection = {
  id: string; // UUID generated on section creation.
  name?: string;
  kpis: PlanRuleKpiToDisplay[];
};

export interface PlanRuleKpiToDisplay {
  id: NonNullable<Variable['id']>;
  displayStatus: HidableElementVisibility;
}

export interface ComputedPlanRuleFieldsToDisplay extends PlanRuleFieldToDisplay {
  label: string;
}
