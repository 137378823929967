import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { type ForwardedRef, memo, type ReactNode } from 'react';

import { GrabHandle, type GrabHandleProps } from '@amalia/design-system/components';
import { type AmaliaThemeType } from '@amalia/ext/mui/theme';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  listElement: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  handle: {
    marginLeft: theme.spacing(-1),
  },
  listHeader: {
    backgroundColor: theme.palette.grey[100],
    border: `1px solid ${theme.palette.divider}`,
  },
}));

interface ListElementProps {
  readonly header?: boolean;
  readonly className?: string;
  readonly innerRef?: ForwardedRef<HTMLDivElement>;
  readonly handleProps?: Partial<GrabHandleProps>;
  readonly children?: ReactNode;
  readonly styleToApply?: object;
  readonly style?: object;
}

export const ListElement = memo(function ListElement({
  children,
  className,
  header,
  innerRef,
  handleProps,
  styleToApply,
  ...props
}: ListElementProps) {
  const classes = useStyles();

  return (
    <div
      ref={innerRef}
      className={clsx(classes.listElement, header && classes.listHeader, className)}
      {...props}
      style={{
        ...(props.style || {}),
        ...(styleToApply || {}),
      }}
    >
      {handleProps ? (
        <GrabHandle
          className={classes.handle}
          {...handleProps}
        />
      ) : null}
      {children}
    </div>
  );
});
