import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { type ComputeEngineResult } from '@amalia/payout-calculation/types';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';

export default new AmaliaFunctionDefault<[ComputeEngineResult], boolean>({
  name: AmaliaFunctionKeys.NOT,
  category: AmaliaFunctionCategory.MISC,

  exec: (evaluate): boolean => !evaluate,

  nbParamsRequired: 1,
  description:
    'This function takes a boolean value as input and returns the logical negation of that value, effectively flipping true to false and false to true.',

  params: [{ name: 'parameter', description: 'Boolean value to revert.' }],

  examples: [
    {
      desc: 'Returns true if opportunity.name does not contains "Closed".',
      formula: 'NOT(CONTAINS(opportunity.name, "Closed")' as AmaliaFormula,
    },
    {
      desc: 'Returns false if the team name is equal to "BDR - FR".',
      formula: 'NOT(team.name = "BDR - FR")' as AmaliaFormula,
    },
    {
      desc: 'Returns false if 1 = 1',
      formula: 'NOT(1 = 1)' as AmaliaFormula,
      result: false,
    },
    {
      desc: 'Returns true when the parameter is null.',
      formula: 'NOT(null)' as AmaliaFormula,
      result: true,
    },
  ],
});
