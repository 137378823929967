import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  type DataConnectorTypes,
  type DataConnectorObject,
  type PatchDataConnectorRequest,
} from '@amalia/data-capture/connectors/types';

import { DataConnectorObjectApiClient } from '../api-client/data-connector-object.api-client';
import { DataConnectorsApiClient } from '../api-client/data-connectors.api-client';

import { DATA_CONNECTOR_MUTATION_KEYS, DATA_CONNECTOR_QUERY_KEYS } from './queries.keys';

const FIVE_MINUTES = 1000 * 60 * 5;

export const useDataConnectors = () =>
  useQuery({
    queryKey: [DATA_CONNECTOR_QUERY_KEYS.CONNECTORS],
    queryFn: DataConnectorsApiClient.list,
  });

export const useDataConnector = (connectorType?: DataConnectorTypes | null) => {
  const { data, ...rest } = useDataConnectors();

  return {
    ...rest,
    data: data?.find((connector) => connector.type === connectorType),
  };
};

/**
 * React query mutation hook to add a data connector.
 */
export const useAddConnectorMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: DataConnectorsApiClient.create,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [DATA_CONNECTOR_QUERY_KEYS.CONNECTORS] });
    },
  });
};

export const useListObjectFields = (dataConnectorId: string, objectName: string) =>
  useQuery({
    staleTime: FIVE_MINUTES,
    queryKey: [DATA_CONNECTOR_QUERY_KEYS.OBJECT_FIELDS, dataConnectorId, objectName],
    queryFn: async () => DataConnectorsApiClient.listObjectFields(dataConnectorId, objectName),
    enabled: !!dataConnectorId && !!objectName,
  });

export const useListObject = (dataConnectorId: string) =>
  useQuery({
    staleTime: FIVE_MINUTES,
    queryKey: [DATA_CONNECTOR_QUERY_KEYS.OBJECTS, dataConnectorId],
    queryFn: async () => DataConnectorsApiClient.listObjects(dataConnectorId),
    enabled: !!dataConnectorId,
  });

type PatchConnectorObjectMutationInput = {
  connectorId: string;
  connectorObject: PatchDataConnectorRequest;
};

export const usePatchDataConnector = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ connectorId, connectorObject }: PatchConnectorObjectMutationInput) =>
      DataConnectorsApiClient.patch(connectorId, connectorObject),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [DATA_CONNECTOR_QUERY_KEYS.CONNECTORS] });
    },
  });
};

type DeleteConnectorObjectMutationInput = {
  objectName: string;
  dataConnectorId: string;
};

/**
 * React query mutation hook to delete a data connector object.
 */
export const useDeleteObjectMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (variables: DeleteConnectorObjectMutationInput) =>
      DataConnectorObjectApiClient.deleteConnectorObject(variables.dataConnectorId, variables.objectName),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [DATA_CONNECTOR_QUERY_KEYS.CONNECTORS] });
    },
  });
};

type UpdateConnectorObjectMutationInput = {
  dataConnectorObject: DataConnectorObject;
  dataConnectorId: string;
};

/**
 * React query mutation hook to add a data connector object.
 */
export const useAddConnectorObjectMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (variables: UpdateConnectorObjectMutationInput) =>
      DataConnectorObjectApiClient.addConnectorObject(variables.dataConnectorId, variables.dataConnectorObject),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [DATA_CONNECTOR_QUERY_KEYS.CONNECTORS] });
    },
  });
};

/**
 * React query mutation hook to update a data connector object.
 */
export const useUpdateDataConnectorObjectMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [DATA_CONNECTOR_MUTATION_KEYS.UPDATE_DATA_CONNECTOR_OBJECT],
    mutationFn: (variables: UpdateConnectorObjectMutationInput) =>
      DataConnectorObjectApiClient.updateConnectorObject(variables.dataConnectorId, variables.dataConnectorObject),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [DATA_CONNECTOR_QUERY_KEYS.CONNECTORS] });
    },
  });
};

/**
 * React query mutation hook to log out from a data connector object.
 */
export const useDataConnectorLogoutMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: DataConnectorsApiClient.logout,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [DATA_CONNECTOR_QUERY_KEYS.CONNECTORS] });
    },
  });
};
