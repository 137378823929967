import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
  StatementFrequency,
  getPeriodStringFromUserDate,
  getRelativePeriodString,
  getUserDateFromPeriodDate,
} from '@amalia/core/types';
import { DatePickerBase, IconButton } from '@amalia/design-system/components';
import { type PeriodFrequencyEnum } from '@amalia/payout-definition/periods/types';

import { DatePickerInput } from './date-picker-input/DatePickerInput';
import * as styles from './PeriodSelector.styles';

type PeriodSelectorProps = {
  readonly frequency: PeriodFrequencyEnum;
  readonly timestamp: number;
  readonly onPeriodChange: (dateString: string) => void;
};

const PeriodSelectorBase = function PeriodSelectorBase({ frequency, timestamp, onPeriodChange }: PeriodSelectorProps) {
  const { formatMessage } = useIntl();

  const datePickerOptions = useMemo(
    () => ({
      dateFormat: frequency === StatementFrequency.month ? 'MMMM yyyy' : 'QQQ yyyy',
      showQuarterYearPicker: frequency !== StatementFrequency.month,
      showMonthYearPicker: frequency === StatementFrequency.month,
    }),
    [frequency],
  );

  /**
   * Init date picker date with timezone offset
   * (date picker only works with user date, and here we have a utc period date, so we apply user timezone offset to it).
   */
  const datePickerSelectedDate = useMemo(() => getUserDateFromPeriodDate(timestamp), [timestamp]);

  /**
   * We receive a user date from datepicker. We need to unapply timezone offset from it to have a utc period compatible date.
   */
  const onDatePickerChange = useCallback(
    (selectedDate: Date) => {
      // Date picker output is not utc. So we need to apply offset to it to match period utc date.
      const formattedDate = getPeriodStringFromUserDate(selectedDate);
      onPeriodChange(formattedDate);
    },
    [onPeriodChange],
  );

  /**
   * Callback when user click on "previous" / "next" period.
   */
  const onClickRelativePeriod = useCallback(
    (offset: number) => {
      const formattedDate = getRelativePeriodString(timestamp, offset, frequency);

      onPeriodChange(formattedDate);
    },
    [frequency, timestamp, onPeriodChange],
  );

  const onClickPrevious = useCallback(() => onClickRelativePeriod(-1), [onClickRelativePeriod]);
  const onClickNext = useCallback(() => onClickRelativePeriod(1), [onClickRelativePeriod]);

  return (
    <div css={styles.container}>
      <IconButton
        icon={<IconChevronLeft />}
        label={formatMessage({ defaultMessage: 'Previous period' })}
        onClick={onClickPrevious}
      />
      {/* Wrap inside a div, otherwise the modal messes with the flex container gap. */}
      <div>
        <DatePickerBase
          {...datePickerOptions}
          popperPlacement="bottom"
          value={datePickerSelectedDate}
          onChange={onDatePickerChange}
        >
          {({ isOpen: isCalendarOpen }) => <DatePickerInput isOpen={isCalendarOpen} />}
        </DatePickerBase>
      </div>
      <IconButton
        icon={<IconChevronRight />}
        label={formatMessage({ defaultMessage: 'Next period' })}
        onClick={onClickNext}
      />
    </div>
  );
};

export const PeriodSelector = memo(PeriodSelectorBase) as typeof PeriodSelectorBase;
