import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';

import { CalculationParser } from '../../../CalculationParser';
import { SanitizeFormula } from '../../../sanitizeFormula';
import { AmaliaFunctionRawArgs } from '../../AmaliaFunction';
import { getValueOrFormula } from '../../utils';

export default new AmaliaFunctionRawArgs({
  name: AmaliaFunctionKeys.GETATINDEX,
  category: AmaliaFunctionCategory.ARRAY,

  exec: (args, _, scope) => CalculationParser.getFunctionResult(args, scope, AmaliaFunctionKeys.GETATINDEX),
  execMock: () => 1,

  nbParamsRequired: 2,
  description: 'Get a value from a filtered dataset',

  params: [
    {
      name: 'array',
      description: 'Array, Filters or Links',
      validFormats: [FormatsEnum.table],
    },
    {
      name: 'rowIndex',
      description: 'Number of the record (row) starting at 0.',
    },
    {
      name: 'result',
      description: 'Name of the field to return. It can include a formula.',
      defaultValue: '',
    },
    {
      name: 'condition',
      description: 'Boolean condition to filter on the array before getting the index (OPTIONAL).',
      defaultValue: '',
    },
  ],

  examples: [
    {
      desc: 'Returns the amount of the first record in this filtered dataset.',
      formula: 'GETATINDEX(filter.closedInPeriod, 0, opportunity.amount)' as AmaliaFormula,
    },
    {
      desc: 'Returns the amount of the second record in this filtered dataset with invoiceSent equals to true.',
      formula:
        'GETATINDEX(filter.closedInPeriod, 1, opportunity.amount, IF(opportunity.invoiceSent, 1, 0))' as AmaliaFormula,
    },
  ],

  generateComputedFunctionResult: (args) => ({
    array: getValueOrFormula(args[0]),
    index: getValueOrFormula(args[1]),
    formula: SanitizeFormula.amaliaFormulaToMathJs(getValueOrFormula(args[2])) as AmaliaFormula,
    predicate: args[3] && (SanitizeFormula.amaliaFormulaToMathJs(getValueOrFormula(args[3])) as AmaliaFormula),
  }),

  parametersToEscapeOnParse: [0, 2, 3],
});
