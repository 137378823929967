import { TokenType } from '@amalia/amalia-lang/tokens/types';

export const newObjectSupportedTokenTypes = [
  TokenType.VARIABLE,
  TokenType.QUOTA,
  TokenType.FIELD,
  TokenType.FILTER,
  TokenType.LINK,
] as const;

export type NewObjectSupportedTokenType = (typeof newObjectSupportedTokenTypes)[number];
