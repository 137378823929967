import { IconDots } from '@tabler/icons-react';
import { type ElementType, Fragment, memo, type MouseEvent, type ReactNode, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { DesignerTokenIcon, type DesignerTokenIconProps } from '@amalia/amalia-lang/tokens/components';
import { type VariableFormatOptions } from '@amalia/amalia-lang/tokens/types';
import { type FormatsEnum } from '@amalia/data-capture/fields/types';
import { type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';
import { MenuDropdown, type TablerIconProps, UnstyledButton } from '@amalia/design-system/components';
import { useClipboard } from '@amalia/design-system/ext';
import { eventStopPropagation } from '@amalia/ext/web';

import { designerObjectsDetails } from '../../../utils/designer';
import { KpiRowItem } from '../../kpi-row-item/KpiRowItem';
import { DesignerObjectsActions } from '../designer-new.types';

import * as styles from './KpiTokenDisplay.styles';

export interface PlanDesignerTileElement {
  id?: string;
  name: string;
  label?: string;
  format?: FormatsEnum;
  formula?: string;
  formatOptions?: VariableFormatOptions;
  // Definition is used in TokenType.PROPERTY, otherwise it's null
  definition?: CustomObjectDefinition | null;
  machineName?: string;
}

export type KpiTokenDisplayProps = {
  readonly dropdownActions?: {
    icon: ElementType<TablerIconProps>;
    label: string;
    action: DesignerObjectsActions;
    onClick: () => void;
    disabled?: boolean;
  }[];
  readonly isForecasted?: boolean;
  readonly isWarning?: boolean;
  readonly name: string;
  readonly element: PlanDesignerTileElement;
  readonly rawValue?: number | string;
  readonly value: ReactNode;
  readonly kpiIconProps?: Pick<DesignerTokenIconProps, 'propertyRef' | 'tokenFormat' | 'tokenType'>;
  readonly kpiOnClick?: () => void;
  readonly displayElementIconButton?: ReactNode;
  readonly hideActions?: boolean;
};

export const KpiTokenDisplay = memo(function KpiTokenDisplay({
  dropdownActions,
  isForecasted = false,
  isWarning = false,
  name,
  element,
  rawValue,
  value,
  kpiIconProps,
  kpiOnClick,
  displayElementIconButton,
  hideActions,
}: KpiTokenDisplayProps) {
  const { formatMessage } = useIntl();
  const kpiStyles = [isWarning && styles.warning].filter(Boolean);

  const { copy } = useClipboard();

  const onClickName = useCallback(
    (event: MouseEvent) => {
      if (designerObjectsDetails[kpiIconProps.tokenType].onCopy) {
        eventStopPropagation(event);
        copy(designerObjectsDetails[kpiIconProps.tokenType].onCopy(element));
      }
    },
    [copy, element, kpiIconProps.tokenType],
  );

  return (
    <KpiRowItem
      isDraggable
      dataKpiKey={name}
      elementId={element.id}
      hideActions={hideActions}
      isForecasted={isForecasted}
      kpiOnClick={kpiOnClick}
      kpiStyles={kpiStyles}
      name={<UnstyledButton onClick={onClickName}>{name}</UnstyledButton>}
      rawValue={rawValue}
      value={value}
      kpiActions={
        <Fragment>
          {displayElementIconButton}
          <MenuDropdown
            content={
              <div>
                {dropdownActions.map(({ action, onClick, icon: Icon, disabled, label }) => (
                  <MenuDropdown.Item
                    key={action}
                    data-testid={`${label}-${element.id}`}
                    disabled={disabled}
                    icon={<Icon css={action === DesignerObjectsActions.DELETE ? styles.red : null} />}
                    label={label}
                    onClick={onClick}
                  />
                ))}
              </div>
            }
          >
            {({ isOpen }) => (
              <MenuDropdown.IconButton
                withBackground
                css={!isOpen && styles.hidden}
                data-testid={`open-kpi-actions-${element.id}`}
                icon={<IconDots />}
                label={formatMessage({ defaultMessage: 'Open actions' })}
              />
            )}
          </MenuDropdown>
        </Fragment>
      }
      kpiIcon={
        kpiIconProps ? (
          <DesignerTokenIcon
            height="16"
            propertyRef={kpiIconProps.propertyRef}
            tokenFormat={kpiIconProps.tokenFormat}
            tokenType={kpiIconProps.tokenType}
            width="16"
          />
        ) : null
      }
    />
  );
});
