import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';

export default new AmaliaFunctionDefault<[string, string], boolean>({
  name: AmaliaFunctionKeys.EXACT,
  category: AmaliaFunctionCategory.STRING,
  nbParamsRequired: 2,
  description: 'Return true if a string exactly matches another string',

  exec: (text1, text2) => text1 === text2,

  params: [
    { name: 'string1', description: 'String to compare: variables, fields, properties, string' },
    { name: 'string2', description: 'String to compare: variables, fields, properties, string' },
  ],

  examples: [
    {
      desc: 'Returns true if Opportunity type is Renewal.',
      formula: 'EXACT(opportunity.type, "Renewal")' as AmaliaFormula,
    },
    {
      desc: 'Returns false since the two strings are not exactly the same.',
      formula: 'EXACT("Jean", "Jean Dupont")' as AmaliaFormula,
    },
  ],
});
