import { useFormikContext } from 'formik';
import { memo, useCallback } from 'react';

import { FormLayout } from '@amalia/design-system/components';
import { ComponentSwitch } from '@amalia/ext/react/components';
import {
  type ChartDisplaySettings,
  ChartType,
  type DashboardChartConfiguration,
} from '@amalia/reporting/dashboards-v2/types';

import { KPICardChartFilters } from './kpi-card-chart/KPICardChartFilters';
import { SimpleBarChartFilters } from './simple-bar-chart/SimpleBarChartFilters';
import { SimpleLineChartFilters } from './simple-line-chart/SimpleLineChartFilters';

export const DashboardChartConfigurationFiltersTab = memo(function DashboardChartConfigurationFiltersTab() {
  const { values, setFieldValue } = useFormikContext<DashboardChartConfiguration<ChartType>>();

  const handleChangeFilterStatus = useCallback(
    async (filterName: keyof ChartDisplaySettings<ChartType>['filters'], newChecked: boolean) => {
      await setFieldValue(`displaySettings.filters.${filterName}.isEnabled`, newChecked);
    },
    [setFieldValue],
  );

  return (
    <FormLayout size={FormLayout.Size.SMALL}>
      <ComponentSwitch value={values.type}>
        <ComponentSwitch.Item value={ChartType.KPI_CARD_CHART}>
          <KPICardChartFilters
            filters={values.displaySettings.filters}
            onFilterChange={handleChangeFilterStatus}
          />
        </ComponentSwitch.Item>

        <ComponentSwitch.Item value={ChartType.SIMPLE_BAR_CHART}>
          <SimpleBarChartFilters
            filters={values.displaySettings.filters}
            onFilterChange={handleChangeFilterStatus}
          />
        </ComponentSwitch.Item>

        <ComponentSwitch.Item value={ChartType.SIMPLE_LINE_CHART}>
          <SimpleLineChartFilters
            filters={values.displaySettings.filters}
            onFilterChange={handleChangeFilterStatus}
          />
        </ComponentSwitch.Item>
      </ComponentSwitch>
    </FormLayout>
  );
});
