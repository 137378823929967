import { lazy, memo } from 'react';
import { useIntl } from 'react-intl';
import { Route } from 'react-router-dom';

import { Layout } from '@amalia/core/layout/components';
import { routes } from '@amalia/core/routes';
import { AmaliaHead } from '@amalia/design-system/ext';
import { RootRoutes } from '@amalia/ext/react-router-dom';
import { ActionsEnum, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { AbilityRouteProtector } from '@amalia/kernel/auth/state';

const Connection = lazy(() => import('./connectors/connector/connection/Connection'));
const DataConnectorPage = lazy(() => import('./connectors/connector/DataConnectorPage'));
const UserConnectorContainer = lazy(() => import('./connectors/userConnector/UserConnectorContainer'));
const Connectors = lazy(() => import('./connectors/Connectors'));

export const CustomObjectRoutes = memo(function CustomObjectRoutes() {
  const { formatMessage } = useIntl();

  return (
    <Layout currentPage="data">
      <RootRoutes>
        <Route
          path={routes.DATA_CONNECTOR_CONNECTION}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.modify, SubjectsEnum.Data_Connector)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Data connector | Connection' })} />
              <Connection />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.DATA_CONNECTOR}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.modify, SubjectsEnum.Data_Connector)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Data connector' })} />
              <DataConnectorPage />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.DATA_USER_CONNECTOR}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.modify, SubjectsEnum.Data_Connector)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'User connectors' })} />
              <UserConnectorContainer />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.DATA_CONNECTORS}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.modify, SubjectsEnum.Data_Connector)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Data connectors' })} />
              <Connectors />
            </AbilityRouteProtector>
          }
        />
      </RootRoutes>
    </Layout>
  );
});
