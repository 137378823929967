import { css, keyframes, useTheme } from '@emotion/react';
import { memo, useMemo } from 'react';
import { Cell, Pie, PieChart } from 'recharts';

import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { ColorCategory } from '@amalia/design-system/meta';
import { mathBetween } from '@amalia/ext/typescript';
import { type CurrencyValue } from '@amalia/kernel/monetary/types';
import { type CustomReportColumn } from '@amalia/reporting/custom-reports/shared';
import {
  defaultGaugeSegmentsValue,
  type ChartType,
  type DashboardChartConfiguration,
  type DashboardChartResult,
} from '@amalia/reporting/dashboards-v2/types';

import { useChartColor } from '../../../common/hooks/charts-colors';
import { CHART_SIZE } from '../kpi-card-chart.constants';

import { centerX, centerY, innerRadius, outerRadius } from './gauge-chart-constants';
import { needle } from './Needle';

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
    opacity: 0;
  }
  20% {
    transform: rotate(0deg);
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  to {
    transform: rotate(var(--end-angle));
  }
`;

type GaugeChartProps = {
  readonly chartData?: DashboardChartResult;
  readonly column?: CustomReportColumn;
  readonly segmentsValue: NonNullable<
    DashboardChartConfiguration<ChartType.KPI_CARD_CHART>['displaySettings']['segmentsValue']
  >;
};

export const GaugeChart = memo(function GaugeChart({
  chartData,
  column,
  segmentsValue = defaultGaugeSegmentsValue,
}: GaugeChartProps) {
  const chartColors = useChartColor();
  const theme = useTheme();

  const gaugeSegments = useMemo(
    () => [
      { value: segmentsValue[0], color: chartColors.RED },
      {
        value: segmentsValue[1] - segmentsValue[0],
        color: chartColors.YELLOW,
      },
      {
        value: segmentsValue[2] - segmentsValue[1],
        color: chartColors.GREEN,
      },
    ],
    [chartColors.GREEN, chartColors.RED, chartColors.YELLOW, segmentsValue],
  );

  const gaugeSegmentsSum = useMemo(() => gaugeSegments.reduce((acc, { value }) => acc + value, 0), [gaugeSegments]);

  const valueMultiplier = column?.format === FormatsEnum.percent ? 100 : 1;

  const dataValue =
    column && chartData
      ? column.format === FormatsEnum.currency
        ? ((chartData.items[0]?.[column.identifier] as CurrencyValue).value as number)
        : (chartData.items[0]?.[column.identifier] as number)
      : 0;

  const needleValue = !chartData || !column ? 0 : mathBetween(dataValue * valueMultiplier, 0, gaugeSegmentsSum);

  return (
    <PieChart
      height={CHART_SIZE}
      width={CHART_SIZE}
      css={css`
        [data-needle] {
          animation: ${rotateAnimation} 2s ease-in-out forwards;
        }
      `}
    >
      <Pie
        cx={centerX}
        cy={centerY}
        data={gaugeSegments}
        dataKey="value"
        endAngle={0}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={180}
        stroke="none"
      >
        {gaugeSegments.map(({ color }) => (
          <Cell
            key={`cell-${color}`}
            fill={color}
            style={{ outline: 'none' }}
          />
        ))}
      </Pie>
      {needle({
        needleColor: theme.ds.colors[ColorCategory.GRAY][800],
        value: needleValue,
        segmentsSum: gaugeSegmentsSum,
      })}
    </PieChart>
  );
});
