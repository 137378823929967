import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Fragment, memo, useMemo, type ReactNode } from 'react';

import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { Divider, Tooltip, Typography } from '@amalia/design-system/components';
import { ColorCategory, ShadowVariant, TypographyVariant } from '@amalia/design-system/meta';
import { ComponentSwitch, ComponentSwitchItem } from '@amalia/ext/react/components';
import { isCurrencyValue } from '@amalia/kernel/monetary/types';
import { makeUniqueCustomReportFieldIdentifier } from '@amalia/reporting/custom-reports/shared';
import {
  ChartDisplayContext,
  KPIChartDisplayMode,
  type ChartType,
  type DashboardChartConfiguration,
  type DashboardChartResult,
} from '@amalia/reporting/dashboards-v2/types';

import { useFormatChartValue } from '../../common/hooks/useFormatChartValue';

import { DoughnutChart } from './doughnut/DoughnutChart';
import { GaugeChart } from './gauge/GaugeChart';
import { CHART_SIZE } from './kpi-card-chart.constants';

const KPICardChartContainer = styled.div<{ chartDisplayContext: ChartDisplayContext }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-radius: ${({ theme }) => theme.ds.borderRadiuses.squared};
  box-shadow: ${({ theme, chartDisplayContext }) =>
    chartDisplayContext === ChartDisplayContext.IN_CONFIGURATION ? theme.ds.shadows[ShadowVariant.HARD] : 'none'};
`;

const KPICardChartFiltersAndKPI = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const KPICardChartFilters = styled.div<{ chartDisplayContext: ChartDisplayContext }>`
  padding: ${({ chartDisplayContext }) =>
    chartDisplayContext === ChartDisplayContext.IN_CONFIGURATION ? '6px 16px' : '0'};
`;

const KPICardChartKPI = styled.div`
  padding: 0 32px;
  // To avoid glitch when the dounghnut is toogled
  min-height: ${CHART_SIZE}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const KPICardChartKPIAndChartSeparator = styled(Divider.Vertical)<{
  chartDisplayContext: ChartDisplayContext;
}>`
  background-color: ${({ theme }) => theme.ds.colors[ColorCategory.GRAY][100]};
  // To avoid the divider not taking the full height
  margin: ${({ chartDisplayContext }) =>
    chartDisplayContext === ChartDisplayContext.IN_CONFIGURATION ? '0' : '-16px 0'};
`;

const KPICardChartDoughnut = styled.div<{
  chartDisplayContext: ChartDisplayContext;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ chartDisplayContext }) =>
    chartDisplayContext === ChartDisplayContext.IN_CONFIGURATION ? '16px' : '0px 24px'};
  margin-right: ${({ chartDisplayContext }) =>
    chartDisplayContext === ChartDisplayContext.IN_CONFIGURATION ? '0' : '-32px'};
`;

interface KPICardChartProps {
  readonly chartConfiguration: DashboardChartConfiguration<ChartType.KPI_CARD_CHART>;
  readonly chartData?: DashboardChartResult;
  readonly chartFilters: ReactNode;
  readonly chartDisplayContext: ChartDisplayContext;
}

export const KPICardChart = memo(function KPICardChart({
  chartConfiguration,
  chartData,
  chartFilters,
  chartDisplayContext,
}: KPICardChartProps) {
  const formatChartValue = useFormatChartValue();

  const column = useMemo(
    () =>
      (chartData?.columns || []).find(
        (column) =>
          column.identifier ===
          makeUniqueCustomReportFieldIdentifier(chartData!.source, chartConfiguration.displaySettings.kpi),
      ),
    [chartConfiguration, chartData],
  );

  const valueFormatted = useMemo(() => {
    if (!chartData || !column) {
      return null;
    }

    const value = chartData.items[0]?.[column.identifier];

    if (isCurrencyValue(value)) {
      const currency = value.symbol;
      return formatChartValue({
        value: { value: value.value, symbol: currency },
        format: FormatsEnum.currency,
      });
    }

    return formatChartValue({ value, format: column.format });
  }, [chartData, column, formatChartValue]);

  const shouldDisplayChart =
    (chartConfiguration.displaySettings.displayMode === KPIChartDisplayMode.DOUGHNUT &&
      column?.format === FormatsEnum.percent) ||
    (column &&
      chartConfiguration.displaySettings.displayMode === KPIChartDisplayMode.GAUGE &&
      [FormatsEnum.number, FormatsEnum.currency, FormatsEnum.percent].includes(column.format));

  return (
    <KPICardChartContainer chartDisplayContext={chartDisplayContext}>
      <KPICardChartFiltersAndKPI>
        <KPICardChartFilters chartDisplayContext={chartDisplayContext}>{chartFilters}</KPICardChartFilters>
        <KPICardChartKPI>
          <Tooltip content={valueFormatted}>
            <Typography
              variant={TypographyVariant.LARGE_HEADING_MEDIUM}
              css={css`
                max-width: 350px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              `}
            >
              {valueFormatted}
            </Typography>
          </Tooltip>
        </KPICardChartKPI>
      </KPICardChartFiltersAndKPI>

      {!!shouldDisplayChart && (
        <Fragment>
          <KPICardChartKPIAndChartSeparator chartDisplayContext={chartDisplayContext} />
          <KPICardChartDoughnut chartDisplayContext={chartDisplayContext}>
            <ComponentSwitch value={chartConfiguration.displaySettings.displayMode}>
              <ComponentSwitchItem value={KPIChartDisplayMode.DOUGHNUT}>
                <DoughnutChart
                  chartConfiguration={chartConfiguration}
                  chartData={chartData}
                  column={column}
                />
              </ComponentSwitchItem>
              <ComponentSwitchItem value={KPIChartDisplayMode.GAUGE}>
                <GaugeChart
                  chartData={chartData}
                  column={column}
                  segmentsValue={chartConfiguration.displaySettings.segmentsValue as number[]}
                />
              </ComponentSwitchItem>
            </ComponentSwitch>
          </KPICardChartDoughnut>
        </Fragment>
      )}
    </KPICardChartContainer>
  );
});
