import { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { type SimpleSelectOption, Breadcrumbs } from '@amalia/design-system/components';
import { useFeatureFlag } from '@amalia/frontend/web-data-layers';
import { useFindDashboards } from '@amalia/reporting/dashboards-v2/state';
import { CompanyFeatureFlags } from '@amalia/tenants/companies/types';

export const LEGACY_DASHBOARD_ID = 'legacy-dashboard';

type DashboardBreadcrumbSelectorProps = {
  readonly dashboardId?: string;
};

export const DashboardBreadcrumbSelector = memo(function DashboardBreadcrumbSelector({
  dashboardId,
}: DashboardBreadcrumbSelectorProps) {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const { isFeatureEnabled: isLegacyDashboardEnabled } = useFeatureFlag(CompanyFeatureFlags.DASHBOARDS);
  const { isFeatureEnabled: isDashboardV2Enabled } = useFeatureFlag(CompanyFeatureFlags.DASHBOARDS_V2);

  const { data: dashboards } = useFindDashboards({ isQueryEnabled: isDashboardV2Enabled });

  const handleChangeDashboard = useCallback(
    (dashboardId: string) => {
      if (dashboardId === LEGACY_DASHBOARD_ID) {
        navigate(generatePath(routes.DASHBOARD));
        return;
      }
      navigate(generatePath(routes.DASHBOARD_V2, { dashboardId }));
    },
    [navigate],
  );

  const dashboardsOptions: SimpleSelectOption<string>[] = useMemo(
    () => [
      ...(isLegacyDashboardEnabled
        ? [{ value: LEGACY_DASHBOARD_ID, label: formatMessage({ defaultMessage: 'Payout overview' }) }]
        : []),
      ...(dashboards ?? [])
        .map((d) => ({
          value: d.id,
          label: d.name,
        }))
        .toSorted((a, b) => a.label.localeCompare(b.label)),
    ],
    [dashboards, formatMessage, isLegacyDashboardEnabled],
  );

  if (!dashboardId || dashboardsOptions.length <= 1) {
    return null;
  }

  return (
    <Breadcrumbs.SelectItem<SimpleSelectOption<string>>
      options={dashboardsOptions}
      value={dashboardId}
      onChange={handleChangeDashboard}
    />
  );
});
