import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { assert } from '@amalia/ext/typescript';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';

export default new AmaliaFunctionDefault<[string | null | undefined, string], boolean>({
  name: AmaliaFunctionKeys.STARTS_WITH,
  category: AmaliaFunctionCategory.STRING,

  nbParamsRequired: 2,

  description: 'Return true when the given Source String starts with the given Search String',

  exec: (sourceString, searchString) => {
    assert(
      sourceString !== null && sourceString !== undefined,
      `${AmaliaFunctionKeys.STARTS_WITH}: source string is null or undefined`,
    );

    return sourceString.toString().startsWith(searchString);
  },

  params: [
    { name: 'sourceString', description: 'String to look into: array, variables, fields, properties, string' },
    { name: 'searchString', description: 'String to look for: array, variables, fields, properties, string' },
  ],

  examples: [
    {
      desc: 'Returns true if Opportunity type startsWith Renew.',
      formula: 'STARTS_WITH(opportunity.type, "Renew")' as AmaliaFormula,
    },
    {
      desc: 'Returns true.',
      formula: 'STARTS_WITH("Jean Dupont", "J")' as AmaliaFormula,
      result: true,
    },
    {
      desc: 'Returns false since the check is case sensitive.',
      formula: 'STARTS_WITH("Opportunity - Renewal XYZ", "opportunity")' as AmaliaFormula,
      result: false,
    },
  ],
});
