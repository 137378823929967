import { css, type Theme } from '@emotion/react';
import { Fragment, memo, type MouseEventHandler } from 'react';
import { FormattedMessage } from 'react-intl';

import { FieldValuePrettyFormat, LabelVariant } from '@amalia/data-capture/fields/components';
import { FormatsEnum, type PropertyRef } from '@amalia/data-capture/fields/types';
import { Tooltip, Typography, UnstyledButton } from '@amalia/design-system/components';

export const label = (theme: Theme) => css`
  color: ${theme.ds.colors.gray[800]};
`;

export const valueContainer = css`
  max-width: 208px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: center;
`;

export const converted = (theme: Theme) => css`
  color: ${theme.ds.colors.gray[700]};
`;

export enum OverwriteTooltipValueVariant {
  NEW = 'NEW',
  OLD = 'OLD',
}

export type OverwriteTooltipValueProps<TValue> = {
  readonly variant?: OverwriteTooltipValueVariant;
  readonly valueFormat?: FormatsEnum;
  readonly valueRef?: PropertyRef;
  readonly value: TValue;
  readonly rawValue?: TValue;
  readonly onClick: MouseEventHandler<unknown>;
};

export const OverwriteTooltipValue = memo(function OverwriteTooltipValue({
  variant = OverwriteTooltipValueVariant.NEW,
  valueFormat,
  valueRef,
  value,
  rawValue,
  onClick,
}: OverwriteTooltipValueProps<any>) {
  return (
    <Fragment>
      <Typography
        css={label}
        variant={Typography.Variant.BODY_SMALL_MEDIUM}
      >
        {variant === OverwriteTooltipValueVariant.NEW ? (
          <FormattedMessage defaultMessage="New value" />
        ) : (
          <FormattedMessage defaultMessage="Old value" />
        )}
      </Typography>
      <Tooltip
        content={
          <FormattedMessage
            defaultMessage="{value} (click to copy)"
            values={{ value }}
          />
        }
      >
        <div css={valueContainer}>
          <UnstyledButton onClick={onClick}>
            <FieldValuePrettyFormat
              format={valueFormat}
              value={value}
              valueRef={valueRef}
              variant={LabelVariant.DEFAULT}
              onClick={onClick}
            />
          </UnstyledButton>
        </div>
      </Tooltip>
      {rawValue ? (
        <Typography
          css={converted}
          variant={Typography.Variant.BODY_XSMALL_REGULAR}
        >
          <FormattedMessage
            defaultMessage="Converted from {value}"
            values={{ value: valueFormat === FormatsEnum.currency ? rawValue.value : rawValue }}
          />
        </Typography>
      ) : null}
    </Fragment>
  );
});
