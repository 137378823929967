import { pick } from 'lodash';

import {
  ChartDisplaySettingsXAxis,
  type ChartDisplaySettings,
  type ChartType,
} from '@amalia/reporting/dashboards-v2/types';

import { type ChartDisplaySettingsConverter } from './chart-display-settings-converter.types';

export class KpiCardChartDisplayConverter implements ChartDisplaySettingsConverter<ChartType.KPI_CARD_CHART> {
  public toKpiCardChart(
    displaySettings: ChartDisplaySettings<ChartType.KPI_CARD_CHART>,
  ): ChartDisplaySettings<ChartType.KPI_CARD_CHART> {
    return displaySettings;
  }

  public toSimpleBarChart(
    displaySettings: ChartDisplaySettings<ChartType.KPI_CARD_CHART>,
  ): ChartDisplaySettings<ChartType.SIMPLE_BAR_CHART> {
    return {
      ...pick(displaySettings, ['color', 'filters']),
      xAxis: { identifier: ChartDisplaySettingsXAxis.PERIOD },
      yAxis: { identifier: '', joins: [] },
    };
  }

  // eslint-disable-next-line sonarjs/no-identical-functions
  public toSimpleLineChart(
    displaySettings: ChartDisplaySettings<ChartType.KPI_CARD_CHART>,
  ): ChartDisplaySettings<ChartType.SIMPLE_LINE_CHART> {
    return {
      ...pick(displaySettings, ['color', 'filters']),
      xAxis: { identifier: ChartDisplaySettingsXAxis.PERIOD },
      yAxis: { identifier: '', joins: [] },
    };
  }
}
