import { css, useTheme } from '@emotion/react';
import { Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isNumber } from 'lodash';
import { memo, useCallback, useMemo, useState, Fragment, type ComponentType } from 'react';
import { Cell, Pie, PieChart } from 'recharts';

import { Group, Stack } from '@amalia/design-system/components';
import { CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { amaliaTheme, colors as colorPalette } from '@amalia/ext/mui/theme';

import { ActiveShape } from './ActiveShape';
import { DonutAnnotation } from './DonutAnnotation';
import './styles.css';

const useStyles = makeStyles({
  skeleton: {
    width: amaliaTheme.spacing(20),
    height: amaliaTheme.spacing(4),
    marginBlock: amaliaTheme.spacing(1),
  },

  // By default an outline is shown when clicking on pie charts.
  pieChart: {
    '& path, & g': {
      outline: 'none',
    },
  },
});

export interface DataDonut {
  name: string;
  value: number;
}

interface DonutChartProps {
  readonly colors?: string[];
  readonly innerRadius?: number;
  readonly thickness?: number;
  readonly data?: DataDonut[];
  readonly ReplaceAnnotation?: ComponentType;
  readonly disableAnimation?: boolean;
  readonly currency?: CurrencySymbolsEnum;
  readonly labelsContainerHeight?: string;
  readonly donutPadding?: number;
  readonly hideTooltip?: boolean;
  readonly loading?: boolean;
}

export const DonutChart = memo(function DonutChat({
  colors: propsColors,
  innerRadius = 70,
  thickness = 10,
  data = [],
  ReplaceAnnotation,
  disableAnimation = false,
  currency = CurrencySymbolsEnum.EUR,
  labelsContainerHeight = '200px',
  donutPadding = 20,
  hideTooltip = true,
  loading = true,
}: DonutChartProps) {
  const theme = useTheme();

  const [activeSection, setActiveSection] = useState<number | undefined>(undefined);
  const classes = useStyles();

  const colors = useMemo(
    () =>
      propsColors ?? [
        theme.ds.colors.primary[200],
        theme.ds.hues.orange[300],
        theme.ds.hues.green[300],
        theme.ds.hues.magenta[300],
        theme.ds.hues.cyan[300],
        theme.ds.hues.yellow[300],
        theme.ds.hues.purple[300],
        theme.ds.hues.red[300],
        theme.ds.hues.brown[300],
      ],
    [propsColors, theme],
  );

  const [dimension, outerRadius] = useMemo(
    () => [(innerRadius + thickness) * 2 + donutPadding, innerRadius + thickness],
    [donutPadding, innerRadius, thickness],
  );

  const [memoedData, allZeros] = useMemo(() => {
    if (data.some((row) => row.value > 0)) return [data, false];
    return [[{ name: 'skeleton', value: 100 }], true];
  }, [data]);

  const annotationsOverflow = memoedData.length > 4 ? 'scroll' : 'hidden';

  const onMouseEnterHandler = useCallback(
    (_, index: number) => {
      if (!(hideTooltip || loading || allZeros)) {
        document.getElementById(`donut-chart-annotation${index}`)?.scrollIntoView({ behavior: 'smooth', block: 'end' });
        setActiveSection(index);
      }
    },
    [allZeros, hideTooltip, loading],
  );

  const onMouseLeaveHandler = useCallback(() => {
    setActiveSection(undefined);
  }, [setActiveSection]);

  const pieProps = useMemo(() => {
    if (loading) {
      return {
        data: [{ name: 'skeleton', value: 100 }],
        className: 'loading',
        cornerRadius: 0,
        paddingAngle: 0,
      };
    }

    return {
      data: memoedData,
      className: ' ',
      cornerRadius: 0,
      activeIndex: activeSection,
    };
  }, [loading, memoedData, activeSection]);

  const donuts = useMemo(
    () =>
      memoedData.map((_, index) => {
        let fill: string;
        if (!isNumber(activeSection)) {
          fill = colors[index % colors.length];
        } else if (index === activeSection) {
          fill = colors[index % colors.length];
        } else {
          fill = colorPalette['grey-100'];
        }
        return (
          <Cell
            key={`cell-${index + 1}`}
            fill={fill}
          />
        );
      }),
    [activeSection, colors, memoedData],
  );

  return (
    <Group
      align="center"
      css={css`
        height: auto;
        width: auto;
      `}
    >
      <PieChart
        className={classes.pieChart}
        height={dimension}
        width={dimension}
      >
        <Pie
          {...pieProps}
          cx="50%"
          cy="50%"
          data={pieProps.data}
          dataKey="value"
          endAngle={90}
          innerRadius={innerRadius}
          isAnimationActive={disableAnimation}
          labelLine={false}
          legendType="circle"
          outerRadius={outerRadius}
          startAngle={-270}
          stroke="none"
          activeShape={({ ...props }) => (
            <ActiveShape
              {...props}
              currency={currency}
            />
          )}
          onMouseEnter={onMouseEnterHandler}
          onMouseLeave={onMouseLeaveHandler}
        >
          {donuts}
        </Pie>
      </PieChart>
      {!ReplaceAnnotation && (
        <Stack
          gap={8}
          style={{ height: labelsContainerHeight, overflowY: annotationsOverflow }}
        >
          {!loading &&
            !allZeros &&
            memoedData.map((entry, index) => (
              <DonutAnnotation
                key={entry.name}
                activeSection={activeSection}
                annotationIndex={index}
                colors={colors}
                currency={currency}
                entry={entry}
                setActiveSection={setActiveSection}
              />
            ))}
          {loading ? (
            <Fragment>
              <Skeleton
                className={classes.skeleton}
                variant="rectangular"
              />
              <Skeleton
                className={classes.skeleton}
                variant="rectangular"
              />
              <Skeleton
                className={classes.skeleton}
                variant="rectangular"
              />
            </Fragment>
          ) : null}
        </Stack>
      )}
      {!!ReplaceAnnotation && <ReplaceAnnotation />}
    </Group>
  );
});
