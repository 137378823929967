import moment from 'moment';

import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { TokenType } from '@amalia/amalia-lang/tokens/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';
import { type ComputeEngineMomentInput } from '../dates.types';

export default new AmaliaFunctionDefault<[ComputeEngineMomentInput], number>({
  name: AmaliaFunctionKeys.dayOfWeekNumber,
  category: AmaliaFunctionCategory.DATES,

  nbParamsRequired: 1,
  description: 'Return the number of weekday of a given date: Sunday is 0, Saturday is 6',

  exec: (date): number => moment(date, 'X').day(),

  params: [
    {
      name: 'date',
      description: 'Date: variables, properties, fields, keywords or date with format toDate("YYYY-MM-DD")',
      validTokenTypes: [TokenType.VARIABLE, TokenType.PROPERTY, TokenType.FIELD, TokenType.KEYWORD, TokenType.FUNCTION],
      validTokenValues: {
        [TokenType.FUNCTION]: [AmaliaFunctionKeys.toDate],
      },
      validFormats: [FormatsEnum.date, FormatsEnum['date-time']],
    },
  ],

  examples: [
    {
      desc: 'Returns 5 for Friday',
      formula: 'dayOfWeekNumber(toDate("2022-09-16"))' as AmaliaFormula,
      result: 5,
    },
    {
      desc: 'Returns the number of weekday of Close Date.',
      formula: 'dayOfWeekNumber(opportunity.closeDate)' as AmaliaFormula,
    },
  ],
});
