import { css } from '@emotion/react';
import { IconCheck } from '@tabler/icons-react';
import { useAsyncEffect } from 'ahooks';
import { Form, useFormikContext } from 'formik';
import { memo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Modal, Tabs } from '@amalia/design-system/components';
import { ComponentSwitch } from '@amalia/ext/react/components';
import { useDebouncedValue } from '@amalia/ext/react/hooks';
import { useCustomReportsList } from '@amalia/reporting/custom-reports/state';
import { DashboardChartDisplay } from '@amalia/reporting/dashboards-v2/components';
import { usePreviewDashboardCharts } from '@amalia/reporting/dashboards-v2/state';
import {
  ChartDisplayContext,
  ChartType,
  type DashboardChart,
  type DashboardChartConfiguration,
} from '@amalia/reporting/dashboards-v2/types';

import {
  DashboardChartConfigurationConsumer,
  DashboardChartConfigurationProvider,
} from './contexts/DashboardChartConfiguration.context';
import { DashboardChartConfigurationModalLayout } from './DashboardChartConfigurationModalLayout';
import { DashboardChartConfigurationFilters } from './filters/DashboardChartConfigurationFilters';
import { DashboardChartConfigurationBasicTab } from './tabs/basic-tab/DashboardChartConfigurationBasicTab';
import { DashboardConfigurationContentTab } from './tabs/content-tab/DashboardChartConfigurationContentTab';
import { type DashboardChartConfigurationTab } from './tabs/dashboard-chart-configuration-modal.types';
import { DashboardChartConfigurationFiltersTab } from './tabs/filters-tab/DashboardChartConfigurationFiltersTab';

interface DashboardChartConfigurationModalFormProps {
  readonly dashboardChart?: DashboardChart<ChartType> | null;
  readonly onChartTypeChange: (newType: ChartType) => void;
  // We don't care about the type of validationSchema
  readonly validationSchema: unknown;
}

export const DashboardChartConfigurationModalForm = memo(function DashboardChartConfigurationModalForm({
  dashboardChart,
  onChartTypeChange,
  validationSchema,
}: DashboardChartConfigurationModalFormProps) {
  const [selectedTab, setSelectedTab] = useState<DashboardChartConfigurationTab>('basic');

  const { dirty, isValid, values, validateForm } = useFormikContext<DashboardChartConfiguration<ChartType>>();

  const debouncedValues = useDebouncedValue(values, 300);

  const { data: customReports = [] } = useCustomReportsList();

  const { data: previewData, isLoading: isPreviewDataLoading } = usePreviewDashboardCharts(debouncedValues, isValid);

  useAsyncEffect(async () => {
    // Re-validate the form when the validation schema changes
    // We can't do it in the DashboardChartConfigurationContentTab because even if we update the state
    // the validation schema will not be updated yet because React will not render the new value immediately
    await validateForm();
  }, [validationSchema, validateForm]);

  const customReportIdSelected = values.customReportId;

  return (
    <DashboardChartConfigurationProvider
      forMode={dashboardChart ? 'edition' : 'creation'}
      onSwitchTab={setSelectedTab}
    >
      <Form
        css={css`
          display: contents;
        `}
      >
        <DashboardChartConfigurationModalLayout
          isFormValid={isValid}
          isLoading={isPreviewDataLoading}
          chartPreview={
            !!previewData && (
              <div
                css={css`
                  width: ${[ChartType.SIMPLE_BAR_CHART, ChartType.SIMPLE_LINE_CHART].includes(values.type)
                    ? '100%'
                    : 'initial'};
                  // For some chart the <ResponsiveContainer /> is based on the parent size, so we need to set at least a min-height
                  height: 350px;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  > :first-child {
                    flex: 1;
                  }
                `}
              >
                <DashboardChartDisplay
                  chartConfiguration={values}
                  chartData={previewData}
                  chartDisplayContext={ChartDisplayContext.IN_CONFIGURATION}
                  chartFilters={<DashboardChartConfigurationFilters />}
                  error={null}
                />
              </div>
            )
          }
          submitButton={
            <Modal.MainAction
              disabled={!isValid || !dirty}
              icon={<IconCheck />}
              type="submit"
            >
              {dashboardChart ? (
                <FormattedMessage defaultMessage="Save" />
              ) : (
                <FormattedMessage defaultMessage="Create" />
              )}
            </Modal.MainAction>
          }
          tabsContent={
            <ComponentSwitch value={selectedTab}>
              <ComponentSwitch.Item value="basic">
                <DashboardChartConfigurationBasicTab
                  customReportIdSelected={customReportIdSelected}
                  customReports={customReports}
                />
              </ComponentSwitch.Item>

              <ComponentSwitch.Item value="content">
                <DashboardConfigurationContentTab
                  customReports={customReports}
                  onChartTypeChange={onChartTypeChange}
                />
              </ComponentSwitch.Item>

              <ComponentSwitch.Item value="filters">
                <DashboardChartConfigurationFiltersTab />
              </ComponentSwitch.Item>
            </ComponentSwitch>
          }
          tabsMenu={
            <DashboardChartConfigurationConsumer>
              {({ isFirstTabValid, isSecondTabValid }) => (
                <Tabs<DashboardChartConfigurationTab>
                  value={selectedTab}
                  onChange={setSelectedTab}
                >
                  <Tabs.Tab<DashboardChartConfigurationTab>
                    label={<FormattedMessage defaultMessage="Basic" />}
                    value="basic"
                  />

                  <Tabs.Tab<DashboardChartConfigurationTab>
                    disabled={!isFirstTabValid}
                    label={<FormattedMessage defaultMessage="Content" />}
                    value="content"
                  />

                  <Tabs.Tab<DashboardChartConfigurationTab>
                    disabled={!isSecondTabValid}
                    label={<FormattedMessage defaultMessage="Filters" />}
                    value="filters"
                  />
                </Tabs>
              )}
            </DashboardChartConfigurationConsumer>
          }
          title={
            dashboardChart ? (
              <FormattedMessage
                defaultMessage="Edit chart {chartName}"
                values={{ chartName: dashboardChart.name }}
              />
            ) : (
              <FormattedMessage defaultMessage="Create chart" />
            )
          }
        />
      </Form>
    </DashboardChartConfigurationProvider>
  );
});
