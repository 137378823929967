import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { Modal, type ModalProps } from '@amalia/design-system/components';
import { useDeletePlan } from '@amalia/payout-definition/plans/state';
import { type Plan } from '@amalia/payout-definition/plans/types';

export type DeletePlanConfirmModalProps = Pick<ModalProps, 'isOpen' | 'onClose'> & {
  readonly plan: Pick<Plan, 'id' | 'name'>;
  readonly onSuccess?: () => void;
};

export const DeletePlanConfirmModal = memo(function DeletePlanConfirmModal({
  plan,
  isOpen,
  onClose,
  onSuccess,
}: DeletePlanConfirmModalProps) {
  const { mutate: deletePlan, isPending } = useDeletePlan();

  const handleDeletePlan = useCallback(() => deletePlan(plan.id, { onSuccess }), [plan.id, deletePlan, onSuccess]);

  return (
    <Modal
      isOpen={isOpen}
      variant={Modal.Variant.DANGER}
      onClose={onClose}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage
              defaultMessage="Delete plan “{planName}”"
              values={{ planName: plan.name }}
            />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FormattedMessage
            defaultMessage="Are you sure you want to delete the plan “<b>{planName}</b>”?"
            values={{ planName: plan.name, b: (chunks) => <strong>{chunks}</strong> }}
          />
        </Modal.Body>
      </Modal.Content>

      <Modal.Actions>
        <Modal.CancelAction />

        <Modal.MainAction
          isLoading={isPending}
          onClick={handleDeletePlan}
        >
          <FormattedMessage defaultMessage="Delete" />
        </Modal.MainAction>
      </Modal.Actions>
    </Modal>
  );
});
