import { pick } from 'lodash';

import { KPIChartDisplayMode, type ChartDisplaySettings, type ChartType } from '@amalia/reporting/dashboards-v2/types';

import { type ChartDisplaySettingsConverter } from './chart-display-settings-converter.types';

export class SimpleBarChartDisplayConverter implements ChartDisplaySettingsConverter<ChartType.SIMPLE_BAR_CHART> {
  public toKpiCardChart(
    displaySettings: ChartDisplaySettings<ChartType.SIMPLE_BAR_CHART>,
  ): ChartDisplaySettings<ChartType.KPI_CARD_CHART> {
    return {
      ...pick(displaySettings, ['color', 'filters']),
      kpi: {
        ...displaySettings.yAxis,
        joins: [],
      },
      displayMode: KPIChartDisplayMode.KPI,
    };
  }

  public toSimpleBarChart(
    displaySettings: ChartDisplaySettings<ChartType.SIMPLE_BAR_CHART>,
  ): ChartDisplaySettings<ChartType.SIMPLE_BAR_CHART> {
    return displaySettings;
  }

  public toSimpleLineChart(
    displaySettings: ChartDisplaySettings<ChartType.SIMPLE_BAR_CHART>,
  ): ChartDisplaySettings<ChartType.SIMPLE_LINE_CHART> {
    return displaySettings;
  }
}
