import { type LabelVariant } from '../types';

export const USER_NULL_VALUE = 'amalia-lang:user:none';

export type UserPrettyFormatSupportedLabelVariant = LabelVariant.ACCENTUATED | LabelVariant.BOLD | LabelVariant.DEFAULT;

export enum UserPrettyFormatSize {
  SMALL = 'small',
  MEDIUM = 'medium',
}
