import { type CSSProperties } from 'react';

import { centerX, centerY, innerRadius, outerRadius } from './gauge-chart-constants';

type NeedleProps = {
  readonly segmentsSum: number;
  readonly value: number;
  readonly needleColor: string;
};

export const needle = function needle({ needleColor, segmentsSum, value }: NeedleProps) {
  const RADIAN = Math.PI / 180;

  const endAngle = 180.0 * (value / segmentsSum);
  const startAngle = 180;
  const length = (innerRadius + 2 * outerRadius) / 3;
  const sin = Math.sin(-RADIAN * startAngle);
  const cos = Math.cos(-RADIAN * startAngle);
  const r = 3.5;
  const x0 = centerX + 5;
  const y0 = centerY + 5;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos;
  const yp = y0 + length * sin;

  return (
    <g
      data-needle
      style={{ '--end-angle': `${endAngle}deg`, transformOrigin: `${x0}px ${y0}px` } as CSSProperties}
    >
      <circle
        cx={x0}
        cy={y0}
        fill={needleColor}
        r={r}
        stroke="none"
      />
      <path
        d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
        fill={needleColor}
        stroke="#none"
      />
    </g>
  );
};
