import { css } from '@emotion/react';
import { IconPencil, IconPlus, IconShare3 } from '@tabler/icons-react';
import { isNil } from 'lodash';
import { memo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { Badge, Breadcrumbs, Button, PageHeader, QuickEdit, Stack } from '@amalia/design-system/components';
import { ActionsEnum, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { Can, useAbilityContext } from '@amalia/kernel/auth/state';
import { DashboardBreadcrumbSelector } from '@amalia/reporting/dashboards-v2/components';
import { useGetHomeDashboardId, usePatchDashboard } from '@amalia/reporting/dashboards-v2/state';
import { type Dashboard } from '@amalia/reporting/dashboards-v2/types';

import { DashboardV2DetailsHeaderMenu } from './DashboardV2DetailsHeaderMenu';

export type DashboardV2DetailsHeaderProps = {
  readonly dashboard?: Dashboard;
  readonly isCreateButtonDisplayed: boolean;
  readonly onOpenCreateModal: () => void;
  readonly onOpenShareModal: () => void;
  // readonly is true when this is render in the home page.
  readonly readOnly: boolean;
};

export const DashboardV2Header = memo(function DashboardV2Header({
  dashboard,
  isCreateButtonDisplayed,
  onOpenCreateModal,
  onOpenShareModal,
  readOnly,
}: DashboardV2DetailsHeaderProps) {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const ability = useAbilityContext();
  const canModifyDashboard = ability.can(ActionsEnum.modify, SubjectsEnum.DashboardsV2) && !readOnly;

  const { data: dashboardAsHome } = useGetHomeDashboardId();

  const isDashboardSetAsHome = !isNil(dashboard?.id) && dashboardAsHome?.dashboardId === dashboard.id;

  const { mutate: patchDashboard, isPending: isPatchDashboardPending } = usePatchDashboard(dashboard?.id ?? null);

  const handleClickGoBack = useCallback(() => {
    navigate(generatePath(routes.DASHBOARDS_V2));
  }, [navigate]);

  const handleClickSaveQuickEdit = useCallback(
    (newName: string) => {
      patchDashboard({
        name: newName,
      });
    },
    [patchDashboard],
  );

  const handleClickEdit = useCallback(() => {
    navigate(generatePath(routes.DASHBOARD_V2, { dashboardId: dashboard!.id }));
  }, [dashboard, navigate]);

  return (
    <PageHeader>
      {!readOnly && (
        <PageHeader.Row
          left={
            <Breadcrumbs
              back={
                <Breadcrumbs.BackButton
                  label={formatMessage({ defaultMessage: 'Go back' })}
                  css={css`
                    height: 36px;
                  `}
                  onClick={handleClickGoBack}
                />
              }
            >
              <DashboardBreadcrumbSelector dashboardId={dashboard?.id} />
            </Breadcrumbs>
          }
        />
      )}
      <PageHeader.Row
        left={
          <Stack gap={8}>
            <QuickEdit
              disabled={!canModifyDashboard || isPatchDashboardPending}
              value={dashboard?.name}
              variant={QuickEdit.Variant.HEADING_1_BOLD}
              onChange={handleClickSaveQuickEdit}
            />
            {!!isDashboardSetAsHome && !readOnly && (
              <div>
                <Badge variant={Badge.Color.PRIMARY}>
                  <FormattedMessage defaultMessage="Home" />
                </Badge>
              </div>
            )}
          </Stack>
        }
        right={
          <div
            css={css`
              display: flex;
              gap: 8px;
            `}
          >
            {!!canModifyDashboard && (
              <Button
                icon={<IconShare3 />}
                variant={Button.Variant.LIGHT}
                onClick={onOpenShareModal}
              >
                <FormattedMessage defaultMessage="Share" />
              </Button>
            )}
            {!!isCreateButtonDisplayed && !!canModifyDashboard && (
              <Button
                icon={<IconPlus />}
                onClick={onOpenCreateModal}
              >
                <FormattedMessage defaultMessage="Create a chart" />
              </Button>
            )}
            {!readOnly && (
              <DashboardV2DetailsHeaderMenu
                canModifyDashboard={canModifyDashboard}
                dashboard={dashboard}
                isDashboardSetAsHome={isDashboardSetAsHome}
              />
            )}
            {!!readOnly && (
              <Can
                a={SubjectsEnum.DashboardsV2}
                I={ActionsEnum.modify}
              >
                <Button
                  icon={<IconPencil />}
                  variant={Button.Variant.PRIMARY_LIGHT}
                  onClick={handleClickEdit}
                >
                  <FormattedMessage defaultMessage="Edit dashboard" />
                </Button>
              </Can>
            )}
          </div>
        }
      />
    </PageHeader>
  );
});
