import moment from 'moment';

import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { TokenType } from '@amalia/amalia-lang/tokens/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';
import { type ComputeEngineMomentInput } from '../dates.types';

export default new AmaliaFunctionDefault<[ComputeEngineMomentInput, ComputeEngineMomentInput], number>({
  name: AmaliaFunctionKeys.monthsTo,
  category: AmaliaFunctionCategory.DATES,

  nbParamsRequired: 2,
  description: 'Return the number of months between two dates (rounded up)',

  // https://github.com/moment/moment/issues/3713
  exec: (date1, date2) => Math.ceil(moment(date2, 'X').diff(moment(date1, 'X'), 'months', true)),

  params: [
    {
      name: 'date1',
      description:
        'Date to start counting at: variables, properties, fields, keywords or date with format toDate("YYYY-MM-DD")',
      validTokenTypes: [TokenType.VARIABLE, TokenType.PROPERTY, TokenType.FIELD, TokenType.KEYWORD, TokenType.FUNCTION],
      validTokenValues: {
        [TokenType.FUNCTION]: [AmaliaFunctionKeys.toDate],
      },
      validFormats: [FormatsEnum.date, FormatsEnum['date-time']],
    },
    {
      name: 'date2',
      description:
        'Date to end counting at: variables, properties, fields, keywords or date with format toDate("YYYY-MM-DD")',
      validTokenTypes: [TokenType.VARIABLE, TokenType.PROPERTY, TokenType.FIELD, TokenType.KEYWORD, TokenType.FUNCTION],
      validTokenValues: {
        [TokenType.FUNCTION]: [AmaliaFunctionKeys.toDate],
      },
      validFormats: [FormatsEnum.date, FormatsEnum['date-time']],
    },
  ],

  examples: [
    {
      desc: 'Returns 1 (rounded up)',
      formula: 'monthsTo(toDate("2023-05-01"), toDate("2023-05-06"))' as AmaliaFormula,
      result: 1,
    },
    {
      desc: 'Returns 1',
      formula: 'monthsTo(toDate("2023-05-19"), toDate("2023-06-19"))' as AmaliaFormula,
      result: 1,
    },
    {
      desc: 'Returns 8 (rounded up)',
      formula: 'monthsTo(toDate("2023-02-12"), toDate("2023-10-05"))' as AmaliaFormula,
      result: 8,
    },
    {
      desc: 'Returns the number of months between the statement start date and the Close Date rounded up.',
      formula: 'monthsTo(statementPeriod.startDate, opportunity.closeDate)' as AmaliaFormula,
    },
  ],
});
