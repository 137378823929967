import { type Dispatch, type SetStateAction, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import useAsyncEffect from 'use-async-effect';

import { type OverwriteTypesEnum, type OverwriteFiltersResponse } from '@amalia/core/types';
import { type FilterSelectOption } from '@amalia/design-system/components';
import { getDataFilters } from '@amalia/frontend/web-data-layers';

import { type FilterProps } from '../OverwritesContainer';
import { overwriteTypeMessages } from '../OverwritesTable/OverwritesTable.messages';

type UseOverwriteFiltersProps = {
  setFilter: Dispatch<SetStateAction<FilterProps>>;
};

export const useOverwriteFilters = ({ setFilter }: UseOverwriteFiltersProps) => {
  const { formatMessage } = useIntl();
  const statusOptions = [
    { label: formatMessage({ defaultMessage: 'Active' }), value: 'status_active' as const },
    { label: formatMessage({ defaultMessage: 'Inactive' }), value: 'status_inactive' as const },
  ];

  const [filtersOptions, setFiltersOptions] = useState<
    OverwriteFiltersResponse & { status: FilterSelectOption<'status_active' | 'status_inactive'>[] }
  >(null);

  useAsyncEffect(async () => {
    const overwriteFiltersOptions = await getDataFilters();
    setFiltersOptions({
      ...overwriteFiltersOptions,
      status: statusOptions,
      types: overwriteFiltersOptions.types.map(({ value }) => ({
        value,
        label: formatMessage(overwriteTypeMessages[value as OverwriteTypesEnum]),
      })),
    });
  }, []);

  const handleChangeFilter = useCallback(
    <K extends keyof FilterProps>(filterName: K) =>
      (values: FilterProps[K]) => {
        setFilter((currentFilter) => ({
          ...currentFilter,
          [filterName]: values,
        }));
      },
    [setFilter],
  );

  return {
    filtersOptions,
    handleChangeFilter,
  };
};
