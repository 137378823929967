import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';

export default new AmaliaFunctionDefault<[string | null | undefined], string>({
  name: AmaliaFunctionKeys.UPPER,
  category: AmaliaFunctionCategory.STRING,
  nbParamsRequired: 1,
  description: 'Return the string in upper case',

  exec: (stringToUpper) => stringToUpper?.toString().toUpperCase() || '',

  params: [{ name: 'string', description: 'String: variables, fields, properties, string' }],

  examples: [
    {
      desc: 'Returns HELLO.',
      formula: 'UPPER("Hello")' as AmaliaFormula,
      result: 'HELLO',
    },
    {
      desc: "Returns the user's first name in upper case.",
      formula: 'UPPER(user.firstName)',
    },
  ],
});
