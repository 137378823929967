import { ClassNames, css } from '@emotion/react';
import clsx from 'clsx';
import {
  type ForwardedRef,
  type ReactNode,
  cloneElement,
  forwardRef,
  memo,
  type ComponentPropsWithoutRef,
  type ReactElement,
} from 'react';

import { TypographyVariant } from '@amalia/design-system/meta';

import { type BadgeProps } from '../badge/Badge';
import { BadgeSize } from '../badge/Badge.types';
import { type CountBadgeProps } from '../count-badge/CountBadge';
import { CountBadgeSize, CountBadgeVariant } from '../count-badge/CountBadge.types';
import { IconLoading } from '../icon-loading/IconLoading';
import { type TablerIconElement } from '../icons/types';
import { Typography } from '../typography/Typography';

import * as styles from './Button.styles';
import { ButtonIconPosition, ButtonSize, ButtonVariant } from './Button.types';

const ButtonSizeToIconPxSize: Record<ButtonSize, number> = {
  [ButtonSize.SMALL]: 14,
  [ButtonSize.MEDIUM]: 16,
  [ButtonSize.LARGE]: 18,
} as const;

const ButtonSizeToTypographyVariant: Record<ButtonSize, TypographyVariant> = {
  [ButtonSize.SMALL]: TypographyVariant.BODY_SMALL_MEDIUM,
  [ButtonSize.MEDIUM]: TypographyVariant.BODY_BASE_MEDIUM,
  [ButtonSize.LARGE]: TypographyVariant.BODY_LARGE_MEDIUM,
} as const;

const ButtonSizeToCountBadgeSize: Record<ButtonSize, CountBadgeSize> = {
  [ButtonSize.SMALL]: CountBadgeSize.SMALL,
  [ButtonSize.MEDIUM]: CountBadgeSize.MEDIUM,
  [ButtonSize.LARGE]: CountBadgeSize.LARGE,
} as const;

const ButtonVariantToCountBadgeVariant: Record<ButtonVariant, CountBadgeVariant> = {
  [ButtonVariant.PRIMARY]: CountBadgeVariant.PRIMARY,
  [ButtonVariant.PRIMARY_LIGHT]: CountBadgeVariant.PRIMARY,
  [ButtonVariant.PRIMARY_TEXT]: CountBadgeVariant.PRIMARY,
  [ButtonVariant.LIGHT]: CountBadgeVariant.PRIMARY,
  [ButtonVariant.LIGHT_TEXT]: CountBadgeVariant.PRIMARY,
  [ButtonVariant.DANGER]: CountBadgeVariant.RED,
  [ButtonVariant.SUCCESS]: CountBadgeVariant.GREEN,
} as const;

const ButtonVariantToCountBadgeStroke: Record<ButtonVariant, boolean> = {
  [ButtonVariant.PRIMARY]: true,
  [ButtonVariant.PRIMARY_LIGHT]: false,
  [ButtonVariant.PRIMARY_TEXT]: false,
  [ButtonVariant.LIGHT]: false,
  [ButtonVariant.LIGHT_TEXT]: false,
  [ButtonVariant.DANGER]: true,
  [ButtonVariant.SUCCESS]: true,
} as const;

const ButtonSizeToBadgeSize: Record<ButtonSize, BadgeSize> = {
  [ButtonSize.SMALL]: BadgeSize.SMALL,
  [ButtonSize.MEDIUM]: BadgeSize.MEDIUM,
  [ButtonSize.LARGE]: BadgeSize.LARGE,
} as const;

export type ButtonProps = Omit<ComponentPropsWithoutRef<'button'>, 'children' | 'size'> & {
  /** Color. */
  readonly variant?: ButtonVariant;
  /** Size. */
  readonly size?: ButtonSize;
  /** Icon if any. Position with iconPosition. */
  readonly icon?: TablerIconElement;
  /** Icon position if icon is defined. */
  readonly iconPosition?: ButtonIconPosition;
  /** Force active state. */
  readonly isActive?: boolean;
  /** Loading state. Will disable the button. */
  readonly isLoading?: boolean;
  /** While in loading state, show this tooltip label instead. */
  readonly iconLoading?: TablerIconElement;
  /** Optional CountBadge element. Its variant depends on the Button variant but can be overridden. */
  readonly countBadge?: ReactElement<CountBadgeProps>;
  /** Optional Badge element. */
  readonly badge?: ReactElement<BadgeProps>;
  /** Label. */
  readonly children?: ReactNode;
};

const ButtonForwardRef = forwardRef(function Button(
  {
    type = 'button',
    icon = undefined,
    iconPosition = ButtonIconPosition.LEFT,
    variant = ButtonVariant.PRIMARY,
    size = ButtonSize.MEDIUM,
    className = undefined,
    isActive = false,
    isLoading = false,
    iconLoading = <IconLoading />,
    countBadge = undefined,
    badge = undefined,
    children = undefined,
    ...props
  }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const clonedIconWithTheme = icon ? (
    <div
      data-button-icon
      css={css`
        display: flex;
        flex: none;
      `}
    >
      <ClassNames>
        {({ css, cx }) =>
          cloneElement(isLoading ? iconLoading : icon, {
            size: ButtonSizeToIconPxSize[size],
            color: 'currentColor',
            className: cx(
              icon.props.className,
              css`
                flex: none;
              `,
            ),
          })
        }
      </ClassNames>
    </div>
  ) : null;

  const disabled = props.disabled || isLoading;

  return (
    <button
      {...props}
      ref={ref}
      className={clsx(className, size, variant, { [styles.IS_ACTIVE_CLASSNAME]: isActive })}
      css={styles.button}
      disabled={disabled}
      type={type}
    >
      {iconPosition === ButtonIconPosition.LEFT && clonedIconWithTheme}

      {!!children && (
        <Typography
          as="span"
          className={size}
          css={styles.text}
          variant={ButtonSizeToTypographyVariant[size]}
        >
          {children}
        </Typography>
      )}

      {!!countBadge && (
        <div data-button-badge>
          {cloneElement(countBadge, {
            disabled,
            size: ButtonSizeToCountBadgeSize[size],
            variant: countBadge.props.variant ?? ButtonVariantToCountBadgeVariant[variant],
            withStroke: !disabled && ButtonVariantToCountBadgeStroke[variant],
          })}
        </div>
      )}

      {!!badge && <div data-button-badge>{cloneElement(badge, { size: ButtonSizeToBadgeSize[size] })}</div>}

      {iconPosition === ButtonIconPosition.RIGHT && clonedIconWithTheme}
    </button>
  );
});

export const Button = Object.assign(memo(ButtonForwardRef), {
  Variant: ButtonVariant,
  Size: ButtonSize,
  IconPosition: ButtonIconPosition,
});
