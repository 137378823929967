import moment from 'moment';

import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { TokenType } from '@amalia/amalia-lang/tokens/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';
import { type ComputeEngineMomentInput } from '../dates.types';

export default new AmaliaFunctionDefault<[ComputeEngineMomentInput, ComputeEngineMomentInput], number | string>({
  name: AmaliaFunctionKeys.daysAgo,
  category: AmaliaFunctionCategory.DATES,
  nbParamsRequired: 2,
  description: 'Return a date n days ago from a given date',

  exec: (date, numberOfDays) => +moment(date, 'X').subtract(numberOfDays, 'days').format('X'),

  params: [
    {
      name: 'date',
      description: 'Date: variables, properties, fields, keywords or date with format toDate("YYYY-MM-DD")',
      validTokenTypes: [TokenType.VARIABLE, TokenType.PROPERTY, TokenType.FIELD, TokenType.KEYWORD, TokenType.FUNCTION],
      validTokenValues: {
        [TokenType.FUNCTION]: [AmaliaFunctionKeys.toDate],
      },
      validFormats: [FormatsEnum.date, FormatsEnum['date-time']],
    },
    { name: 'numberOfDaysAgo', description: 'Number of days ago, can be negative for days after' },
  ],

  examples: [
    {
      desc: 'Returns "2023-04-26" ',
      formula: 'daysAgo(toDate("2023-04-30"), 4)' as AmaliaFormula,
      result: 1_682_467_200,
    },
    {
      desc: 'Returns "2023-04-18" ',
      formula: 'daysAgo(toDate("2023-04-11"), -7)' as AmaliaFormula,
      result: 1_681_776_000,
    },
    {
      desc: 'Returns a date 30 days before the Close Date.',
      formula: 'daysAgo(opportunity.closeDate, 30)' as AmaliaFormula,
    },
    {
      desc: 'Returns a date 12 days after the statement start date.',
      formula: 'daysAgo(statementPeriod.startDate, -12)' as AmaliaFormula,
    },
  ],
});
