import { Table } from '@devexpress/dx-react-grid-material-ui';
import { makeStyles } from '@mui/styles';
import { IconDots } from '@tabler/icons-react';
import clsx from 'clsx';
import { noop } from 'lodash';
import { memo, useCallback, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { MenuDropdown } from '@amalia/design-system/components';
import { type AmaliaThemeType } from '@amalia/ext/mui/theme';

import { TableBuilderContext } from '../TableBuilderContext';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  cell: {
    padding: `0 ${theme.spacing(2.5)}`,
    backgroundColor: theme.palette.common.white,
    // In readonly mode, action buttons disappear and the cell are really tight.
    height: 50,

    '&:not(:last-child)': {
      borderRight: `2px solid ${theme.palette.divider}`,
    },
  },
  actionCell: {
    '&:first-child': {
      // Style on the datagrid component is so precise it makes this
      // impossible to override.
      padding: `0 !important`,
    },
    '&:not(:last-child)': {
      borderRight: 0,
    },
  },
}));

export const TableBuilderActionCell = memo(function TableBuilderActionCell(cellProps: Table.DataCellProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const { tableRow } = cellProps;
  const { onAddRow, onDeleteRow, nbRows } = useContext(TableBuilderContext);

  const onAddRowAbove = useCallback(
    () =>
      // Here diff is actually 0 because the new row will take the place of this one.
      onAddRow(tableRow.rowId as number, 0),
    [onAddRow, tableRow],
  );

  const onAddRowBelow = useCallback(() => onAddRow(tableRow.rowId as number, 1), [onAddRow, tableRow]);

  const onDeleteRowProxy = useCallback(() => onDeleteRow(tableRow.rowId as number), [onDeleteRow, tableRow]);

  return (
    <Table.Cell
      name={`${tableRow.rowId}.values[actions]`}
      {...cellProps}
      className={clsx(classes.cell, classes.actionCell)}
      // Avoid the action cell to become editable by all means.
      onClick={noop}
      onFocus={noop}
    >
      <MenuDropdown
        id="column_actions_menu"
        content={
          <div>
            <MenuDropdown.Item
              data-testid={`insert-row-above-${tableRow.rowId}`}
              label={<FormattedMessage defaultMessage="Insert row above" />}
              onClick={onAddRowAbove}
            />

            <MenuDropdown.Item
              data-testid={`insert-row-below-${tableRow.rowId}`}
              label={<FormattedMessage defaultMessage="Insert row below" />}
              onClick={onAddRowBelow}
            />

            {nbRows > 1 && (
              <MenuDropdown.Item
                data-testid={`delete-row-${tableRow.rowId}`}
                label={<FormattedMessage defaultMessage="Delete row" />}
                onClick={onDeleteRowProxy}
              />
            )}
          </div>
        }
      >
        <MenuDropdown.IconButton
          icon={<IconDots />}
          label={formatMessage({ defaultMessage: 'Actions on row {rowId}' }, { rowId: tableRow.rowId })}
        />
      </MenuDropdown>
    </Table.Cell>
  );
});
