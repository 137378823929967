import { isArray, lowerCase, orderBy } from 'lodash';

import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { type RecordContent } from '@amalia/data-capture/connectors/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { assert } from '@amalia/ext/typescript';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';

/**
 * Check that order argument input is valid.
 * @param order
 */
const validateOrderInput = (order: string): 'asc' | 'desc' => {
  const lowercaseOrder = lowerCase(order);
  switch (lowercaseOrder) {
    case 'desc':
    case 'asc':
      return lowercaseOrder;
    default:
      return 'asc';
  }
};

export default new AmaliaFunctionDefault<
  [rows: RecordContent[], sortFields: string[] | string, orders?: string[] | string],
  RecordContent[]
>({
  name: AmaliaFunctionKeys.SORT,
  category: AmaliaFunctionCategory.ARRAY,
  nbParamsRequired: 2,
  description: 'Sort an array on one or more fields',

  exec: (rows, sortFields, orders) => {
    assert(Array.isArray(rows), 'Rows given to SORT is not an array');
    let sort: ('asc' | 'desc')[] | 'asc' | 'desc' = 'asc';
    if (orders) {
      sort = isArray(orders) ? orders.map((o) => validateOrderInput(o)) : validateOrderInput(orders);
    }

    return orderBy(rows, sortFields, sort);
  },

  params: [
    { name: 'array', description: 'Array, Filters or Links', validFormats: [FormatsEnum.table] },
    { name: 'sortFields', description: 'Field(s) to sort dataset by' },
    { name: 'orders', description: 'Order(s) to order fields by ("ASC" or "DESC")', defaultValue: 'ASC' },
  ],

  examples: [
    {
      desc: 'Returns the filtered dataset sorted by closeDate in descending order.',
      formula: 'SORT(filter.oppsByRepInPeriod, "closeDate", "DESC")' as AmaliaFormula,
    },
    {
      desc: 'Returns the filtered dataset sorted by closeDate in ascending order then by invoiceDate in descending order.',
      formula:
        'SORT(opportunity.oppToOpportunityLineItemLink, ["closeDate","invoiceDate"], ["ASC", "DESC"])' as AmaliaFormula,
    },
  ],
});
