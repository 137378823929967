import { memo, useState } from 'react';
import useAsyncEffect from 'use-async-effect';

import { type RefreshmentOptions } from '@amalia/core/types';
import { type DataConnector } from '@amalia/data-capture/connectors/types';

import { DataModal } from './DataModal';

interface DataModalContainerProps {
  readonly isOpen: boolean;
  readonly handleRefreshment: (
    dataConnectorObjectNames: RefreshmentOptions['dataConnectorObjectsNames'],
    params: {
      willLaunchCalculation: boolean;
      willPrune: boolean;
      willFullSync: boolean;
      startOffset: number;
    },
  ) => void;
  readonly onClose: () => void;
  readonly defaultDataConnectorObjectName: string;
  readonly listConnectors: () => Promise<DataConnector[]>;
}

export const DataModalContainer = memo(function DataModalContainer({
  isOpen,
  handleRefreshment,
  onClose,
  defaultDataConnectorObjectName,
  listConnectors,
}: DataModalContainerProps) {
  const [connectors, setConnectors] = useState<DataConnector[]>([]);

  useAsyncEffect(async () => {
    const connectorsList = await listConnectors();
    setConnectors(connectorsList);
  }, []);

  return (
    <DataModal
      connectors={connectors}
      defaultDataConnectorObjectName={defaultDataConnectorObjectName}
      handleRefreshment={handleRefreshment}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
});
