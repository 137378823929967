import { useAuth0 } from '@auth0/auth0-react';
import { memo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';

import { hideLoadingScreen } from '@amalia/core/layout/loading-screen';
import { toError } from '@amalia/ext/typescript';
import { AuthenticationErrorPage } from '@amalia/kernel/auth/components';

/**
 * Init Auth0 authentication on a given connection. This is used for SSO as URL for Initiate SignIn flow.
 */
export const InitiatedSSOAuthentication = memo(function InitiatedSSOAuthentication() {
  const { loginWithRedirect, isLoading } = useAuth0();
  const [error, setError] = useState<string>('');
  const { search } = useLocation();

  useAsyncEffect(async () => {
    if (!isLoading) {
      const params = new URLSearchParams(search);
      const tenant = params.get('tenant') ?? undefined;
      try {
        await loginWithRedirect({
          authorizationParams: {
            connection: tenant,
          },
        });
      } catch (e) {
        hideLoadingScreen();
        setError(toError(e).message);
      }
    }
  }, [isLoading, search, loginWithRedirect]);

  return error ? <AuthenticationErrorPage message={error} /> : null;
});
