import { subject } from '@casl/ability';
import { css } from '@emotion/react';
import {
  IconDots,
  IconHelp,
  IconMessage,
  IconMessageCheck,
  IconMessagePlus,
  IconPencil,
  IconPlusMinus,
} from '@tabler/icons-react';
import { isNil } from 'lodash';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, Link } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { formatCurrencyAmount, PaymentCategory, roundNumber, showPayment } from '@amalia/core/types';
import { Group, IconButton, MenuDropdown, Tooltip, Typography } from '@amalia/design-system/components';
import { objectToQs } from '@amalia/ext/web';
import { ActionsEnum, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { Can } from '@amalia/kernel/auth/state';
import { ForecastAmount, ForecastSimulationPopover } from '@amalia/payout-calculation/forecasts/components';
import { paymentCategoryMessages, paymentCategoryTooltipMessages } from '@amalia/payout-calculation/payments/intl';
import { useCurrentCompany } from '@amalia/tenants/companies/state';

import { StatementDetailHeaderActionsCompute } from './StatementDetailHeaderActionsCompute';
import {
  type StatementDetailHeaderActionsProps,
  useStatementDetailHeaderActions,
} from './useStatementDetailHeaderActions';

export const StatementDetailHeaderStickyRow = memo(function StatementDetailHeaderStickyRow(
  props: StatementDetailHeaderActionsProps,
) {
  const { data: company } = useCurrentCompany();

  const {
    statement,
    ability,
    isWorkflowBegun,
    formatMessage,
    handleNewAdjustment,
    statementCommentThread,
    onStatementCommentsClicked,
    menuIconsList,
    paymentTotalByType,
    isForecastedView,
  } = useStatementDetailHeaderActions(props);

  const formattedAchievedValue = showPayment(paymentTotalByType, PaymentCategory.achievement)
    ? formatCurrencyAmount({
        amount: roundNumber(paymentTotalByType.achievement) || 0,
        currencyRate: statement.rate,
        currencySymbol: statement.currency,
      })
    : null;

  const formattedForecastedValue = formatCurrencyAmount({
    amount: roundNumber(statement.total) || 0,
    currencySymbol: statement.currency,
  });

  return (
    <Group
      align="center"
      gap={18}
      justify="flex-end"
    >
      {!(isNil(formattedForecastedValue) && isNil(formattedAchievedValue)) && (
        <Group
          align="center"
          gap={12}
        >
          <Typography
            variant={Typography.Variant.BODY_SMALL_MEDIUM}
            css={(theme) => css`
              color: ${theme.ds.colors.gray[800]};
            `}
          >
            <Group
              align="center"
              gap={6}
            >
              <span>
                {isForecastedView ? (
                  <FormattedMessage defaultMessage="Total forecasted" />
                ) : (
                  company.customization?.totalCommissionLabel || (
                    <FormattedMessage {...paymentCategoryMessages[PaymentCategory.achievement]} />
                  )
                )}
              </span>

              <Tooltip content={<FormattedMessage {...paymentCategoryTooltipMessages[PaymentCategory.achievement]} />}>
                <IconHelp size={14} />
              </Tooltip>
            </Group>
          </Typography>

          <ForecastSimulationPopover
            disabled={!isForecastedView}
            formattedAchievedValue={formattedAchievedValue}
            formattedForecastedValue={formattedForecastedValue}
          >
            {isForecastedView && formattedForecastedValue !== formattedAchievedValue ? (
              <ForecastAmount variant={Typography.Variant.HEADING_4_MEDIUM}>{formattedForecastedValue}</ForecastAmount>
            ) : (
              <Typography variant={Typography.Variant.HEADING_4_MEDIUM}>{formattedAchievedValue}</Typography>
            )}
          </ForecastSimulationPopover>
        </Group>
      )}

      <Group gap={8}>
        {ability.can(ActionsEnum.calculate, SubjectsEnum.Statement) && (
          <StatementDetailHeaderActionsCompute {...props} />
        )}
        {ability.can(ActionsEnum.adjust, SubjectsEnum.Statement) && !isForecastedView && (
          <IconButton
            withBackground
            data-testid="new-adjustment-sticky"
            disabled={isWorkflowBegun}
            icon={<IconPlusMinus />}
            label={formatMessage({ defaultMessage: 'Make an adjustment' })}
            onClick={handleNewAdjustment}
          />
        )}
        {!isForecastedView &&
          ability.can(ActionsEnum.view_statement_threads, subject(SubjectsEnum.Statement, statement)) && (
            <IconButton
              withBackground
              data-testid="comment-statement-sticky"
              label={formatMessage({ defaultMessage: 'Comments' })}
              icon={
                statementCommentThread ? (
                  statementCommentThread.thread.isReviewed ? (
                    <IconMessageCheck />
                  ) : (
                    <IconMessagePlus />
                  )
                ) : (
                  <IconMessage />
                )
              }
              onClick={onStatementCommentsClicked}
            />
          )}

        <Can
          a={SubjectsEnum.Plan}
          I={ActionsEnum.modify}
        >
          <Link
            to={{
              pathname: generatePath(routes.DESIGNER_PLAN, {
                planId: statement.plan.id,
              }),
              search: `?${objectToQs({
                userId: statement.user.id,
                periodId: statement.period.id,
              })}`,
            }}
          >
            <IconButton
              withBackground
              icon={<IconPencil />}
              label={formatMessage({ defaultMessage: 'Edit in Designer' })}
            />
          </Link>
        </Can>

        <MenuDropdown
          content={
            <div>
              {menuIconsList.map((action) => (
                <MenuDropdown.Item
                  key={action.label}
                  icon={action.icon}
                  label={action.label}
                  onClick={action.handleClick}
                />
              ))}
            </div>
          }
        >
          <MenuDropdown.IconButton
            withBackground
            icon={<IconDots />}
            label={formatMessage({ defaultMessage: 'More actions' })}
          />
        </MenuDropdown>
      </Group>
    </Group>
  );
});
