import { css } from '@emotion/react';
import { IconChartBar, IconChartLine, IconNumber123 } from '@tabler/icons-react';
import { useFormikContext } from 'formik';
import { Fragment, memo, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { FormLayout, Group, IconButton, Typography } from '@amalia/design-system/components';
import { ComponentSwitch } from '@amalia/ext/react/components';
import { type CustomReport } from '@amalia/reporting/custom-reports/shared';
import { DividerHorizontal } from '@amalia/reporting/dashboards-v2/components';
import { UnsupportedChartTypeException } from '@amalia/reporting/dashboards-v2/shared';
import {
  type ChartColor,
  ChartType,
  type DashboardChartConfiguration,
  KPIChartDisplayMode,
} from '@amalia/reporting/dashboards-v2/types';

import { useDashboardChartConfigurationContext } from '../../contexts/DashboardChartConfiguration.context';
import { DashboardChartConfigurationNextStepButton } from '../../cta/DashboardChartConfigurationNextStepButton';
import { DashboardChartConfigurationModalLabel } from '../../DashboardChartConfigurationModalLabel';
import { ChartDisplaySettingsConverterFactory } from '../../display-settings-converter/chart-display-settings-converter.factory';

import { ChartColorPicker } from './ChartColorPicker';
import { KPICardChartContent } from './kpi-card-chart/KPICardChartContent';
import { SimpleBarChartContent } from './simple-bar-chart/SimpleBarChartContent';
import { SimpleLineChartContent } from './simple-line-chart/SimpleLineChartContent';

interface DashboardConfigurationContentTabProps {
  readonly customReports: CustomReport[];
  readonly onChartTypeChange: (newType: ChartType) => void;
}

export const DashboardConfigurationContentTab = memo(function DashboardConfigurationContentTab({
  customReports,
  onChartTypeChange,
}: DashboardConfigurationContentTabProps) {
  const { values, setValues, setFieldValue } = useFormikContext<DashboardChartConfiguration<ChartType>>();

  const { isSecondTabValid, onSwitchTab } = useDashboardChartConfigurationContext();

  const handleClickNextStepButton = useCallback(() => {
    onSwitchTab('filters');
  }, [onSwitchTab]);

  const handleChangeChartType = useCallback(
    (newChartType: ChartType) => async () => {
      const currentChartType = values.type;
      const currentDisplaySettings = values.displaySettings;
      const displaySettingsConverter = ChartDisplaySettingsConverterFactory.getConverterForChartType(currentChartType);

      const newDisplaySettings = (() => {
        switch (newChartType) {
          case ChartType.KPI_CARD_CHART:
            // @ts-expect-error - TS is not able to infer but were are type safe thanks to the factory
            return displaySettingsConverter.toKpiCardChart(currentDisplaySettings);
          case ChartType.SIMPLE_BAR_CHART:
            // @ts-expect-error - TS is not able to infer but were are type safe thanks to the factory
            return displaySettingsConverter.toSimpleBarChart(currentDisplaySettings);
          case ChartType.SIMPLE_LINE_CHART:
            // @ts-expect-error - TS is not able to infer but were are type safe thanks to the factory
            return displaySettingsConverter.toSimpleLineChart(currentDisplaySettings);
          default:
            throw new UnsupportedChartTypeException(newChartType);
        }
      })();

      await setValues({
        ...values,
        type: newChartType,
        displaySettings: newDisplaySettings,
      });

      onChartTypeChange(newChartType);
    },
    [setValues, values, onChartTypeChange],
  );

  const handleChangeChartColor = useCallback(
    async (chartColor: ChartColor) => {
      await setFieldValue('displaySettings.color', chartColor);
    },
    [setFieldValue],
  );

  const shouldRenderColorPicker = useMemo(
    () =>
      !(
        values.type === ChartType.KPI_CARD_CHART &&
        (values as DashboardChartConfiguration<ChartType.KPI_CARD_CHART>).displaySettings.displayMode !==
          KPIChartDisplayMode.DOUGHNUT
      ),
    [values],
  );

  return (
    <FormLayout
      size={FormLayout.Size.SMALL}
      css={css`
        height: 100%;
      `}
    >
      <FormLayout.Group>
        <DashboardChartConfigurationModalLabel variant={Typography.Variant.BODY_BASE_MEDIUM}>
          <FormattedMessage defaultMessage="Chart type" />
        </DashboardChartConfigurationModalLabel>

        <Group gap={16}>
          <IconButton
            withBackground
            icon={<IconChartBar />}
            isActive={values.type === ChartType.SIMPLE_BAR_CHART}
            label={<FormattedMessage defaultMessage="Simple bar chart" />}
            size={IconButton.Size.LARGE}
            onClick={handleChangeChartType(ChartType.SIMPLE_BAR_CHART)}
          />

          <IconButton
            withBackground
            icon={<IconChartLine />}
            isActive={values.type === ChartType.SIMPLE_LINE_CHART}
            label={<FormattedMessage defaultMessage="Simple line chart" />}
            size={IconButton.Size.LARGE}
            onClick={handleChangeChartType(ChartType.SIMPLE_LINE_CHART)}
          />

          <IconButton
            withBackground
            icon={<IconNumber123 />}
            isActive={values.type === ChartType.KPI_CARD_CHART}
            label={<FormattedMessage defaultMessage="KPI card chart" />}
            size={IconButton.Size.LARGE}
            onClick={handleChangeChartType(ChartType.KPI_CARD_CHART)}
          />
        </Group>
      </FormLayout.Group>

      <DividerHorizontal />

      <ComponentSwitch value={values.type}>
        <ComponentSwitch.Item value={ChartType.KPI_CARD_CHART}>
          <KPICardChartContent customReports={customReports} />
        </ComponentSwitch.Item>

        <ComponentSwitch.Item value={ChartType.SIMPLE_BAR_CHART}>
          <SimpleBarChartContent customReports={customReports} />
        </ComponentSwitch.Item>

        <ComponentSwitch.Item value={ChartType.SIMPLE_LINE_CHART}>
          <SimpleLineChartContent customReports={customReports} />
        </ComponentSwitch.Item>
      </ComponentSwitch>

      {!!shouldRenderColorPicker && (
        <Fragment>
          <DividerHorizontal />

          <ChartColorPicker
            chartColor={values.displaySettings.color}
            onChangeChartColor={handleChangeChartColor}
          />
        </Fragment>
      )}

      <DashboardChartConfigurationNextStepButton
        isTabValid={isSecondTabValid}
        onClick={handleClickNextStepButton}
      />
    </FormLayout>
  );
});
