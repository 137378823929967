import { css } from '@emotion/react';
import { Fragment, memo, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { formatCurrencyAmount, formatTotalAmount, PaymentCategory, roundNumber } from '@amalia/core/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { FatTab, Trend } from '@amalia/design-system/components';
import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { paymentCategoryMessages, paymentCategoryTooltipMessages } from '@amalia/payout-calculation/payments/intl';

interface MultiPayoutProps {
  readonly view: PaymentCategory;
  readonly setView: (view: PaymentCategory) => void;
  readonly hideHoldAndRelease: boolean;
  readonly values: {
    [PaymentCategory.achievement]: number | undefined;
    [PaymentCategory.hold]: number | undefined;
    [PaymentCategory.paid]: number | undefined;
  };
  readonly averages?: {
    [PaymentCategory.achievement]: number | undefined;
    [PaymentCategory.hold]: number | undefined;
    [PaymentCategory.paid]: number | undefined;
  };
  readonly trendPreviousValue?: number;
  readonly trendNextValue?: number;
  readonly currencyRate: number;
  readonly currencySymbol: CurrencySymbolsEnum;
  readonly total?: {
    label: string;
    value: number | null | undefined;
    format: FormatsEnum;
  };
  readonly overridesLabels?: {
    tabs?: Partial<Record<PaymentCategory, string>>;
  };
  readonly isStatementEvolutionEnabled?: boolean;
  readonly withBorderTop?: boolean;
  readonly className?: string;
}

export const MultiPayout = memo(function MultiPayout({
  view,
  setView,
  values,
  averages,
  currencyRate,
  currencySymbol,
  hideHoldAndRelease,
  total,
  overridesLabels,
  trendPreviousValue,
  trendNextValue,
  isStatementEvolutionEnabled = false,
  withBorderTop,
  className,
}: MultiPayoutProps) {
  const { formatMessage } = useIntl();

  const totalFormattedAmount = useMemo(() => {
    const value = total ? total.value || 0 : (values as Record<PaymentCategory, number>)[PaymentCategory.achievement];
    const totalFormat = total ? total.format : FormatsEnum.currency;
    return formatTotalAmount(value, totalFormat, currencySymbol, currencyRate);
  }, [currencyRate, currencySymbol, total, values]);

  const totalLabel =
    total?.label ||
    overridesLabels?.tabs?.[PaymentCategory.achievement] ||
    formatMessage(paymentCategoryMessages[PaymentCategory.achievement]);

  return (
    <div
      className={className}
      data-cy="multi-payout-view-selector"
      css={css`
        display: flex;
        align-items: stretch;
        flex-grow: 1;
      `}
    >
      <FatTab
        isSelected={view === PaymentCategory.achievement}
        label={totalLabel}
        tooltip={formatMessage(paymentCategoryTooltipMessages[PaymentCategory.achievement])}
        withBorderTop={withBorderTop}
        data={
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
            `}
          >
            <div data-cy={`payout-${PaymentCategory.achievement}`}>{totalFormattedAmount}</div>

            {!!(isStatementEvolutionEnabled && trendPreviousValue && trendNextValue) && (
              <Trend
                currentValue={trendNextValue}
                previousValue={trendPreviousValue}
              />
            )}
          </div>
        }
        subtitle={
          Number.isFinite(averages?.[PaymentCategory.achievement]) ? (
            <FormattedMessage
              defaultMessage="AVG: {amount}"
              values={{
                amount: formatCurrencyAmount({
                  amount: roundNumber(averages?.[PaymentCategory.achievement]),
                  currencyRate,
                  currencySymbol,
                }),
              }}
            />
          ) : undefined
        }
        onClick={() => setView(PaymentCategory.achievement)}
      />

      {!hideHoldAndRelease && !total && (
        <Fragment>
          <FatTab
            isSelected={view === PaymentCategory.paid}
            tooltip={formatMessage(paymentCategoryTooltipMessages[PaymentCategory.paid])}
            withBorderTop={withBorderTop}
            data={
              <div data-cy={`payout-${PaymentCategory.paid}`}>
                {formatTotalAmount(values[PaymentCategory.paid], FormatsEnum.currency, currencySymbol, currencyRate)}
              </div>
            }
            label={
              overridesLabels?.tabs && PaymentCategory.paid in overridesLabels.tabs
                ? overridesLabels.tabs[PaymentCategory.paid]
                : formatMessage(paymentCategoryMessages[PaymentCategory.paid])
            }
            subtitle={
              Number.isFinite(averages?.[PaymentCategory.paid]) ? (
                <FormattedMessage
                  defaultMessage="AVG: {amount}"
                  values={{
                    amount: formatCurrencyAmount({
                      amount: roundNumber(averages?.[PaymentCategory.paid]),
                      currencyRate,
                      currencySymbol,
                    }),
                  }}
                />
              ) : undefined
            }
            onClick={() => setView(PaymentCategory.paid)}
          />

          <FatTab
            isSelected={view === PaymentCategory.hold}
            tooltip={formatMessage(paymentCategoryTooltipMessages[PaymentCategory.hold])}
            withBorderTop={withBorderTop}
            data={
              <div data-cy={`payout-${PaymentCategory.hold}`}>
                {formatTotalAmount(values[PaymentCategory.hold], FormatsEnum.currency, currencySymbol, currencyRate)}
              </div>
            }
            label={
              overridesLabels?.tabs && PaymentCategory.hold in overridesLabels.tabs
                ? overridesLabels.tabs[PaymentCategory.hold]
                : formatMessage(paymentCategoryMessages[PaymentCategory.hold])
            }
            subtitle={
              Number.isFinite(averages?.[PaymentCategory.hold]) ? (
                <FormattedMessage
                  defaultMessage="AVG: {amount}"
                  values={{
                    amount: formatCurrencyAmount({
                      amount: roundNumber(averages?.[PaymentCategory.hold]),
                      currencyRate,
                      currencySymbol,
                    }),
                  }}
                />
              ) : undefined
            }
            onClick={() => setView(PaymentCategory.hold)}
          />
        </Fragment>
      )}
    </div>
  );
});
