import moment from 'moment';

import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { TokenType } from '@amalia/amalia-lang/tokens/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';
import { type ComputeEngineMomentInput } from '../dates.types';

export default new AmaliaFunctionDefault<[ComputeEngineMomentInput], number>({
  name: AmaliaFunctionKeys.startOfQuarter,
  category: AmaliaFunctionCategory.DATES,
  nbParamsRequired: 1,
  description: 'Return the first day of quarter of a given date',

  exec: (date) => +moment(date, 'X').startOf('quarter').format('X'),

  params: [
    {
      name: 'date',
      description:
        'Date to apply function on: variables, properties, fields, keywords or date with format toDate("YYYY-MM-DD")',
      validTokenTypes: [TokenType.VARIABLE, TokenType.PROPERTY, TokenType.FIELD, TokenType.KEYWORD, TokenType.FUNCTION],
      validTokenValues: {
        [TokenType.FUNCTION]: [AmaliaFunctionKeys.toDate],
      },
      validFormats: [FormatsEnum.date, FormatsEnum['date-time']],
    },
  ],

  examples: [
    {
      desc: 'Returns "2023-04-01"',
      formula: 'startOfQuarter(toDate("2023-05-19"))' as AmaliaFormula,
      result: 1_680_307_200,
    },
    {
      desc: "Returns first day of quarter of the user's plan assignment start date.",
      formula: 'startOfQuarter(planAssignement.effectiveAsOf)' as AmaliaFormula,
    },
    {
      desc: 'Returns first day of quarter of opportunity close date.',
      formula: 'startOfQuarter(opportunity.closeDate)' as AmaliaFormula,
    },
  ],
});
