import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { Filters, type FilterSelectOption } from '@amalia/design-system/components';
import { usePeriodSelector } from '@amalia/lib-ui-business';
import { type Period } from '@amalia/payout-definition/periods/types';
import { type ChartType, type DashboardChartConfiguration } from '@amalia/reporting/dashboards-v2/types';
import { type UserProfile } from '@amalia/tenants/users/profile/types';

import { type ChartFilterOptionsAndUserPreferences } from './hooks/useChartFiltersPreferences';

type DashboardChartFiltersProps = ChartFilterOptionsAndUserPreferences & {
  readonly chartConfiguration: DashboardChartConfiguration<ChartType>;
};

export const DashboardChartFilters = memo(function DashboardChartFilters({
  chartConfiguration,
  options,
  filters,
  setSelectedPeriods,
  setSelectedTeams,
  setSelectedUsers,
}: DashboardChartFiltersProps) {
  const handleChangePeriods = useCallback(
    (periodIds: Period['id'][]) => setSelectedPeriods(periodIds),
    [setSelectedPeriods],
  );

  const { onChange: handleChangePeriodsProxy } = usePeriodSelector({
    onChange: handleChangePeriods,
  });

  const shouldDisplayPeriodsFilter = chartConfiguration.displaySettings.filters.periods.isEnabled;
  const shouldDisplayTeamsFilter = chartConfiguration.displaySettings.filters.teams.isEnabled;
  const shouldDisplayUsersFilter = chartConfiguration.displaySettings.filters.users.isEnabled;

  return (
    <Filters>
      {!!shouldDisplayPeriodsFilter && (
        <Filters.FilterSelect<FilterSelectOption<Period['id']>, true>
          isMultiple
          isStatic
          allSelectedLabel={<FormattedMessage defaultMessage="All periods" />}
          id="filter-chart-periods"
          label={<FormattedMessage defaultMessage="Periods" />}
          options={options.periods}
          value={filters.periods}
          onChange={handleChangePeriodsProxy}
        />
      )}

      {!!shouldDisplayTeamsFilter && (
        <Filters.FilterSelect<FilterSelectOption<string>, true>
          isMultiple
          isStatic
          allSelectedLabel={<FormattedMessage defaultMessage="All teams" />}
          id="filter-chart-teams"
          label={<FormattedMessage defaultMessage="Teams" />}
          options={options.teams}
          value={filters.teams}
          onChange={setSelectedTeams}
        />
      )}
      {!!shouldDisplayUsersFilter && (
        <Filters.FilterSelect<FilterSelectOption<UserProfile['id']>, true>
          isMultiple
          isStatic
          allSelectedLabel={<FormattedMessage defaultMessage="All users" />}
          id="filter-chart-users"
          label={<FormattedMessage defaultMessage="Users" />}
          options={options.users}
          value={filters.users}
          onChange={setSelectedUsers}
        />
      )}
    </Filters>
  );
});
