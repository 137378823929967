import { sortBy, stubTrue } from 'lodash';
import { useMemo } from 'react';

import { FORMULA_KEYWORDS } from '@amalia/amalia-lang/formula/keywords/shared';
import {
  ValueOrAttributeType,
  type FormulaKeyword,
  type AttributeValueProperty,
  type AttributeValueVariable,
  type AttributeValueQuota,
  type AttributeValueKeyword,
  type AttributeValueRelationship,
  type AttributeValue,
  type AttributeValueField,
} from '@amalia/amalia-lang/formula/types';
import { TokenType, type Variable } from '@amalia/amalia-lang/tokens/types';
import { type TracingTypes } from '@amalia/core/types';
import { formatToIcon, propertyRefToIcon } from '@amalia/data-capture/fields/components';
import { type PropertyRef, type FormatsEnum } from '@amalia/data-capture/fields/types';
import { type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';
import { type SelectDropdownOption } from '@amalia/design-system/components';
import { useShallowObjectMemo } from '@amalia/ext/react/hooks';
import { type Relationship } from '@amalia/payout-definition/plans/types';

import { useFormulaBuilderContext } from '../../components/formula-builder/FormulaBuilder.context';
import {
  useGetFormulaBuilderAttributeLabel,
  type UseGetFormulaBuilderAttributeLabelValue,
} from '../use-get-formula-builder-attribute-label/useGetFormulaBuilderAttributeLabel';

export type AttributeSelectOption<TAttributeValue extends AttributeValue> = SelectDropdownOption<string> & {
  format?: FormatsEnum;
  ref?: PropertyRef;
  attribute: TAttributeValue;
};

const getOptionIcon = ({ format, ref }: { format?: FormatsEnum; ref?: PropertyRef }) =>
  ref ? propertyRefToIcon[ref] : format ? formatToIcon[format] : undefined;

const mapAttributeToSelectOption = <TAttributeValue extends AttributeValue>(
  attributeOption: Omit<AttributeSelectOption<TAttributeValue>, 'label'>,
  getFormulaBuilderAttributeLabel: UseGetFormulaBuilderAttributeLabelValue,
) => ({
  ...attributeOption,
  label: getFormulaBuilderAttributeLabel(attributeOption.attribute),
});

export const mapPropertyToAttributeSelectOption = (
  property: TracingTypes.Property,
  fieldType: TokenType.PROPERTY | TokenType.VIRTUAL_PROPERTY,
  getFormulaBuilderAttributeLabel: UseGetFormulaBuilderAttributeLabelValue,
  isPropertyDisabled: boolean,
): AttributeSelectOption<AttributeValueProperty> =>
  mapAttributeToSelectOption(
    {
      value: `${property.definition.machineName}.${property.machineName}`,
      format: property.format,
      ref: property.ref,
      disabled: isPropertyDisabled,
      icon: getOptionIcon(property),
      attribute: {
        type: ValueOrAttributeType.ATTRIBUTE,
        fieldType,
        objectMachineName: property.definition.machineName,
        propertyMachineName: property.machineName,
      },
    },
    getFormulaBuilderAttributeLabel,
  );

export const mapRelationshipPropertyToAttributeSelectOption = (
  customObjectDefinition: CustomObjectDefinition,
  relationship: Relationship,
  property: TracingTypes.Property,
  getFormulaBuilderAttributeLabel: UseGetFormulaBuilderAttributeLabelValue,
  isPropertyDisabled: boolean,
): AttributeSelectOption<AttributeValueRelationship> =>
  mapAttributeToSelectOption(
    {
      value: `${customObjectDefinition.machineName}.${relationship.name}.${property.machineName}`,
      format: property.format,
      ref: property.ref,
      disabled: isPropertyDisabled,
      icon: getOptionIcon(property),
      attribute: {
        type: ValueOrAttributeType.ATTRIBUTE,
        fieldType: TokenType.LINK,
        objectMachineName: customObjectDefinition.machineName,
        relationshipMachineName: relationship.name,
        propertyMachineName: property.machineName,
      },
    },
    getFormulaBuilderAttributeLabel,
  );

export const mapFieldToAttributeSelectOption = (
  field: Variable,
  getFormulaBuilderAttributeLabel: UseGetFormulaBuilderAttributeLabelValue,
  isFieldDisabled: boolean,
): AttributeSelectOption<AttributeValueField> =>
  mapAttributeToSelectOption(
    {
      value: `${field.object!.machineName}.${field.machineName}`,
      format: field.format,
      disabled: isFieldDisabled,
      icon: getOptionIcon(field),
      attribute: {
        type: ValueOrAttributeType.ATTRIBUTE,
        fieldType: TokenType.FIELD,
        // Same type is used for variables and fields but fields have an object attribute.
        objectMachineName: field.object!.machineName,
        propertyMachineName: field.machineName,
      },
    },
    getFormulaBuilderAttributeLabel,
  );

export const mapKeywordToAttributeSelectOption = (
  keyword: FormulaKeyword,
  getFormulaBuilderAttributeLabel: UseGetFormulaBuilderAttributeLabelValue,
): AttributeSelectOption<AttributeValueKeyword> =>
  mapAttributeToSelectOption(
    {
      value: FORMULA_KEYWORDS[keyword].formula,
      format: FORMULA_KEYWORDS[keyword].format,
      icon: getOptionIcon(FORMULA_KEYWORDS[keyword]),
      attribute: {
        type: ValueOrAttributeType.ATTRIBUTE,
        fieldType: TokenType.KEYWORD,
        keyword,
      },
    },
    getFormulaBuilderAttributeLabel,
  );

export const mapQuotaToAttributeSelectOption = (
  quota: Variable,
  getFormulaBuilderAttributeLabel: UseGetFormulaBuilderAttributeLabelValue,
  isQuotaDisabled: boolean,
): AttributeSelectOption<AttributeValueQuota> =>
  mapAttributeToSelectOption(
    {
      value: `${quota.type}.${quota.machineName}`,
      format: quota.format,
      disabled: isQuotaDisabled,
      icon: getOptionIcon(quota),
      attribute: {
        type: ValueOrAttributeType.ATTRIBUTE,
        fieldType: TokenType.QUOTA,
        quotaType: quota.type,
        machineName: quota.machineName,
      },
    },
    getFormulaBuilderAttributeLabel,
  );

export const mapVariableToAttributeSelectOption = (
  variable: Variable,
  getFormulaBuilderAttributeLabel: UseGetFormulaBuilderAttributeLabelValue,
  isVariableDisabled: boolean,
): AttributeSelectOption<AttributeValueVariable> =>
  mapAttributeToSelectOption(
    {
      value: `statement.${variable.machineName}`,
      format: variable.format,
      disabled: isVariableDisabled,
      icon: getOptionIcon(variable),
      attribute: {
        type: ValueOrAttributeType.ATTRIBUTE,
        fieldType: TokenType.VARIABLE,
        machineName: variable.machineName,
      },
    },
    getFormulaBuilderAttributeLabel,
  );

export type UseAttributesOptionsOptions = {
  filterVariable?: (variable: Variable) => boolean;
  filterProperty?: (property: TracingTypes.Property) => boolean;
  keywords?: FormulaKeyword[];
  showFilteredAsDisabled?: boolean;
};

export type AttributesOptions = {
  [TokenType.FIELD]: AttributeSelectOption<AttributeValueField>[];
  [TokenType.KEYWORD]: AttributeSelectOption<AttributeValueKeyword>[];
  [TokenType.PROPERTY]: AttributeSelectOption<AttributeValueProperty>[];
  [TokenType.QUOTA]: AttributeSelectOption<AttributeValueQuota>[];
  [TokenType.VARIABLE]: AttributeSelectOption<AttributeValueVariable>[];
  [TokenType.VIRTUAL_PROPERTY]: AttributeSelectOption<AttributeValueProperty>[];
  [TokenType.LINK]: {
    relationship: Relationship;
    [TokenType.PROPERTY]: AttributeSelectOption<AttributeValueRelationship>[];
    [TokenType.VIRTUAL_PROPERTY]: AttributeSelectOption<AttributeValueRelationship>[];
  }[];
};

/**
 * Given the current formula builder context (all tokens, custom object definition, etc.), returns the list of all attributes that can be used in a formula.
 * Filters can be passed to filter the list of attributes. By default all attributes are returned.
 */
export const useAttributesOptions = ({
  filterVariable = stubTrue,
  filterProperty = stubTrue,
  keywords = undefined,
  showFilteredAsDisabled = false,
}: UseAttributesOptionsOptions = {}): AttributesOptions => {
  const { planObjects, customObjectDefinition } = useFormulaBuilderContext();
  const getFormulaBuilderAttributeLabel = useGetFormulaBuilderAttributeLabel();

  const fieldsOptions: AttributesOptions[TokenType.FIELD] = useMemo(
    () =>
      sortBy(
        ((planObjects?.[TokenType.FIELD] || []) as Variable[])
          .filter(
            (field) =>
              field.object!.machineName === customObjectDefinition?.machineName &&
              (showFilteredAsDisabled || filterVariable(field)),
          )
          .map((field) =>
            mapFieldToAttributeSelectOption(
              field,
              getFormulaBuilderAttributeLabel,
              showFilteredAsDisabled && !filterVariable(field),
            ),
          ),
        'label',
      ),
    [
      customObjectDefinition?.machineName,
      planObjects,
      showFilteredAsDisabled,
      filterVariable,
      getFormulaBuilderAttributeLabel,
    ],
  );

  const keywordsOptions: AttributesOptions[TokenType.KEYWORD] = useMemo(
    () =>
      sortBy(
        (keywords || []).map((keyword) => mapKeywordToAttributeSelectOption(keyword, getFormulaBuilderAttributeLabel)),
        'label',
      ),
    [keywords, getFormulaBuilderAttributeLabel],
  );

  // For properties, show all (non virtual) properties that are on the current selected object.
  const propertiesOptions: AttributesOptions[TokenType.PROPERTY] = useMemo(
    () =>
      customObjectDefinition
        ? sortBy(
            ((planObjects?.[TokenType.PROPERTY] || []) as TracingTypes.Property[])
              .filter(
                (property) =>
                  property.definition.machineName === customObjectDefinition.machineName &&
                  (showFilteredAsDisabled || filterProperty(property)),
              )
              .map((property) =>
                mapPropertyToAttributeSelectOption(
                  property,
                  TokenType.PROPERTY,
                  getFormulaBuilderAttributeLabel,
                  showFilteredAsDisabled && !filterProperty(property),
                ),
              ),
            'label',
          )
        : [],
    [customObjectDefinition, planObjects, showFilteredAsDisabled, filterProperty, getFormulaBuilderAttributeLabel],
  );

  // For virtual properties, show all properties that are on the current selected object.
  const virtualPropertiesOptions: AttributesOptions[TokenType.VIRTUAL_PROPERTY] = useMemo(
    () =>
      customObjectDefinition
        ? sortBy(
            ((planObjects?.[TokenType.VIRTUAL_PROPERTY] || []) as TracingTypes.Property[])
              .filter(
                (property) =>
                  property.definition.machineName === customObjectDefinition.machineName &&
                  (showFilteredAsDisabled || filterProperty(property)),
              )
              .map((property) =>
                mapPropertyToAttributeSelectOption(
                  property,
                  TokenType.VIRTUAL_PROPERTY,
                  getFormulaBuilderAttributeLabel,
                  showFilteredAsDisabled && !filterProperty(property),
                ),
              ),
            'label',
          )
        : [],
    [customObjectDefinition, planObjects, showFilteredAsDisabled, filterProperty, getFormulaBuilderAttributeLabel],
  );

  const quotasOptions: AttributesOptions[TokenType.QUOTA] = useMemo(
    () =>
      sortBy(
        ((planObjects?.[TokenType.QUOTA] || []) as Variable[])
          .filter((quota) => showFilteredAsDisabled || filterVariable(quota))
          .map((quota) =>
            mapQuotaToAttributeSelectOption(
              quota,
              getFormulaBuilderAttributeLabel,
              showFilteredAsDisabled && !filterVariable(quota),
            ),
          ),
        'label',
      ),
    [planObjects, showFilteredAsDisabled, filterVariable, getFormulaBuilderAttributeLabel],
  );

  const variablesOptions: AttributesOptions[TokenType.VARIABLE] = useMemo(
    () =>
      sortBy(
        ((planObjects?.[TokenType.VARIABLE] || []) as Variable[])
          .filter((variable) => showFilteredAsDisabled || filterVariable(variable))
          .map((variable) =>
            mapVariableToAttributeSelectOption(
              variable,
              getFormulaBuilderAttributeLabel,
              showFilteredAsDisabled && !filterVariable(variable),
            ),
          ),
        'label',
      ),
    [planObjects, showFilteredAsDisabled, filterVariable, getFormulaBuilderAttributeLabel],
  );

  const linksOptions: AttributesOptions[TokenType.LINK] = useMemo(
    () =>
      customObjectDefinition
        ? ((planObjects?.[TokenType.LINK] || []) as Relationship[])
            // Only keep relationships that are on the current selected object.
            .filter((relationship) => relationship.fromDefinitionMachineName === customObjectDefinition.machineName)
            .map((relationship) => ({
              relationship,

              // Filter properties that are on the relationship's destination object, and filter on filterProperty.
              [TokenType.PROPERTY]: sortBy(
                ((planObjects?.[TokenType.PROPERTY] || []) as TracingTypes.Property[])
                  .filter(
                    (property) =>
                      property.definition.machineName === relationship.toDefinitionMachineName &&
                      (showFilteredAsDisabled || filterProperty(property)),
                  )
                  .map((property) =>
                    mapRelationshipPropertyToAttributeSelectOption(
                      customObjectDefinition,
                      relationship,
                      property,
                      getFormulaBuilderAttributeLabel,
                      showFilteredAsDisabled && !filterProperty(property),
                    ),
                  ),
                'label',
              ),

              // Same with virtual properties.
              [TokenType.VIRTUAL_PROPERTY]: sortBy(
                ((planObjects?.[TokenType.VIRTUAL_PROPERTY] || []) as TracingTypes.Property[])
                  .filter(
                    (property) =>
                      property.definition.machineName === relationship.toDefinitionMachineName &&
                      (showFilteredAsDisabled || filterProperty(property)),
                  )
                  .map((property) =>
                    mapRelationshipPropertyToAttributeSelectOption(
                      customObjectDefinition,
                      relationship,
                      property,
                      getFormulaBuilderAttributeLabel,
                      showFilteredAsDisabled && !filterProperty(property),
                    ),
                  ),
                'label',
              ),
            }))
            .filter(
              (linkAttributes) =>
                linkAttributes[TokenType.PROPERTY].length || linkAttributes[TokenType.VIRTUAL_PROPERTY].length,
            )
        : [],
    [customObjectDefinition, planObjects, showFilteredAsDisabled, filterProperty, getFormulaBuilderAttributeLabel],
  );

  return useShallowObjectMemo<AttributesOptions>({
    [TokenType.FIELD]: fieldsOptions,
    [TokenType.KEYWORD]: keywordsOptions,
    [TokenType.PROPERTY]: propertiesOptions,
    [TokenType.VIRTUAL_PROPERTY]: virtualPropertiesOptions,
    [TokenType.QUOTA]: quotasOptions,
    [TokenType.VARIABLE]: variablesOptions,
    [TokenType.LINK]: linksOptions,
  });
};
