import { isEmpty } from 'lodash';
import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { PaymentCategory } from '@amalia/core/types';
import { AlertBanner } from '@amalia/design-system/components';
import { selectCurrentPaymentCategory } from '@amalia/frontend/web-data-layers';
import { useStatementDetailContext } from '@amalia/lib-ui';
import { useCurrentCompany } from '@amalia/tenants/companies/state';

import { type StatementSummaryPayoutAndPerformanceChartProps } from '../../../common/statement-summary-layout/histogram/chart/StatementSummaryPayoutAndPerformanceChart';
import { StatementSummaryPayoutAndPerformanceChartContainer } from '../../../common/statement-summary-layout/histogram/chart-container/StatementSummaryPayoutAndPerformanceChartContainer';
import { StatementSummaryLayoutHistogram } from '../../../common/statement-summary-layout/histogram/StatementSummaryLayoutHistogram';
import { useStatementDetailStatistics } from '../hooks/useStatementDetailStatistics';

export type StatementDetailSummaryHistogramProps = {
  readonly onClickGoToStatement: (id: string) => void;
};

export const StatementDetailSummaryHistogram = memo(function StatementDetailSummaryHistogram({
  onClickGoToStatement,
}: StatementDetailSummaryHistogramProps) {
  const { statement, isForecastedView } = useStatementDetailContext();
  const currentPaymentCategory = useSelector(selectCurrentPaymentCategory);

  const { statistics } = useStatementDetailStatistics(statement);
  const { data: company } = useCurrentCompany();

  const handleClickBar: Required<StatementSummaryPayoutAndPerformanceChartProps>['onClickBar'] = useCallback(
    (record) => onClickGoToStatement(record.RULE_METRIC__statementId as string),
    [onClickGoToStatement],
  );

  const alert = isForecastedView ? (
    <AlertBanner inline>
      <FormattedMessage defaultMessage="This chart is not available on forecast." />
    </AlertBanner>
  ) : isEmpty(statistics?.records) ? (
    <AlertBanner inline>
      <FormattedMessage defaultMessage="No data available for this year." />
    </AlertBanner>
  ) : undefined;

  return (
    <StatementSummaryLayoutHistogram disabled={!!alert}>
      <StatementSummaryPayoutAndPerformanceChartContainer
        alert={alert}
        currency={statement.currency || company.currency} // Sometimes the app crashes in forecasted mode because of the currency so we fallback to the company currency but the types are most likely not correct.
        frequency={statement.plan.frequency}
        period={statement.period}
        shouldDimChart={currentPaymentCategory !== PaymentCategory.achievement}
        statistics={statistics}
        onClickBar={handleClickBar}
      />
    </StatementSummaryLayoutHistogram>
  );
});
