import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  type AssignmentStatus,
  type AssignmentDates,
  getAssignmentStatus,
} from '@amalia/assignments/common/status/shared';
import { type RadioButtonOptionShape } from '@amalia/design-system/components';

import { AssignmentStatusMessages } from '../intl/assignment-status.messages';

import { getAssignmentsWithStatus } from './use-assignments-with-status';

export type AssignmentStatusFilter = AssignmentStatus | 'all';

export const useAssignmentsStatusFilter = <TAssignment extends AssignmentDates>(assignments?: TAssignment[]) => {
  const { formatMessage } = useIntl();
  const [statusFilter, setStatusFilter] = useState<AssignmentStatusFilter>('active');

  const statusOptions: RadioButtonOptionShape<AssignmentStatusFilter>[] = useMemo(
    () => [
      ...(['active', 'expired', 'future'] as const).map((status) => {
        const count = getAssignmentsWithStatus(assignments ?? [], status).length;

        return {
          value: status,
          disabled: !count,
          label: formatMessage(AssignmentStatusMessages[status]),
          count: count || undefined, // Reset to undefined to hide "0" count.
        };
      }),
      { value: 'all', label: formatMessage({ defaultMessage: 'All' }), count: assignments?.length ?? 0 },
    ],
    [assignments, formatMessage],
  );

  const filteredAssignments = useMemo(
    () =>
      statusFilter === 'all'
        ? assignments
        : assignments?.filter((assignment) => getAssignmentStatus(assignment) === statusFilter),
    [assignments, statusFilter],
  );

  return {
    statusFilter,
    setStatusFilter,
    statusOptions,
    filteredAssignments,
  };
};
