import { useDndContext, type UniqueIdentifier } from '@dnd-kit/core';
import { css, useTheme } from '@emotion/react';
import { IconGripVertical, IconPencil, IconTableShortcut } from '@tabler/icons-react';
import { memo } from 'react';
import { useIntl } from 'react-intl';

import { Divider, IconButton, IconButtonLink, Stack, TextOverflow, Typography } from '@amalia/design-system/components';
import { DashboardChartDisplay } from '@amalia/reporting/dashboards-v2/components';
import { useGetChartData } from '@amalia/reporting/dashboards-v2/state';
import {
  ChartDisplayContext,
  type ChartLayoutConfig,
  type ChartType,
  type DashboardChart,
} from '@amalia/reporting/dashboards-v2/types';

import { DashboardChartFilters } from './DashboardChartFilters';
import { ChartCard, ChartCardHeader, ChartCardHeaderActions } from './DashboardChartWrapper';
import { useChartFiltersPreferences } from './hooks/useChartFiltersPreferences';

type DashboardChartWrapperDragOverlayProps = {
  readonly dashboardChart: DashboardChart<ChartType>;
  readonly chartLayoutConfig: ChartLayoutConfig;
};

export const DashboardChartWrapperDragOverlay = memo(function DashboardChartWrapperDragOverlay({
  dashboardChart,
  chartLayoutConfig,
}: DashboardChartWrapperDragOverlayProps) {
  const { filters, options, setSelectedPeriods, setSelectedTeams, setSelectedUsers } = useChartFiltersPreferences(
    dashboardChart.dashboard.id,
    dashboardChart.id,
    dashboardChart,
  );
  const { data, error } = useGetChartData(dashboardChart.dashboard.id, dashboardChart.id, filters);
  const { formatMessage } = useIntl();
  const isReallyActive = useDndIsReallyActiveId(chartLayoutConfig.id);
  const theme = useTheme();

  return (
    <ChartCard
      key={dashboardChart.id}
      chartLayoutConfig={chartLayoutConfig}
      isEditModeActive={false}
      css={css`
        transform: ${isReallyActive ? 'scale(1.02)' : 'scale(1)'};
        height: 100%;
      `}
    >
      <ChartCardHeader isEditModeActive>
        <div className="name-container">
          <div
            css={css`
              cursor: grab;
              display: flex;
              align-items: center;
              outline: none;
            `}
          >
            <IconGripVertical
              color={theme.ds.colors.gray[600]}
              size={16}
            />
          </div>
          <Typography
            as={TextOverflow}
            variant={Typography.Variant.BODY_BASE_MEDIUM}
          >
            {dashboardChart.name}
          </Typography>
        </div>
        <ChartCardHeaderActions>
          <IconButtonLink
            withBackground
            icon={<IconTableShortcut />}
            label={formatMessage({ defaultMessage: 'View report' })}
          />
          <IconButton
            withBackground
            icon={<IconPencil />}
            label={formatMessage({ defaultMessage: 'Edit chart' })}
          />
        </ChartCardHeaderActions>
      </ChartCardHeader>
      <Divider.Horizontal
        css={css`
          background-color: ${theme.ds.colors.gray[50]};
        `}
      />

      <Stack
        css={css`
          height: 100%;
        `}
      >
        <div
          css={css`
            padding: 16px 32px;
            height: 100%;
          `}
        >
          <DashboardChartDisplay
            chartConfiguration={dashboardChart}
            chartData={data}
            chartDisplayContext={ChartDisplayContext.IN_DASHBOARD}
            error={error}
            isAnimationActive={false}
            chartFilters={
              <DashboardChartFilters
                chartConfiguration={dashboardChart}
                filters={filters}
                options={options}
                setSelectedPeriods={setSelectedPeriods}
                setSelectedTeams={setSelectedTeams}
                setSelectedUsers={setSelectedUsers}
              />
            }
          />
        </div>
      </Stack>
    </ChartCard>
  );
});

const useDndIsReallyActiveId = (id: UniqueIdentifier | null) => {
  const context = useDndContext();
  return id !== null && context.active?.id === id;
};
