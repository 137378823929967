import { ClassNames, css } from '@emotion/react';
import clsx from 'clsx';
import { isFunction, merge } from 'lodash';
import { type ForwardedRef, forwardRef, memo, type ReactElement, type ReactNode, useCallback, useMemo } from 'react';
import ReactDatePicker, { type ReactDatePickerProps } from 'react-datepicker';
import { FormattedMessage, useIntl } from 'react-intl';
import 'react-datepicker/dist/react-datepicker.css';

import { useBoolState, useForwardedRef } from '@amalia/ext/react/hooks';
import { type MergeAll } from '@amalia/ext/typescript';

import { Button } from '../../general/button/Button';

import { DatePickerBaseHeader } from './date-picker-base-header/DatePickerBaseHeader';
import { DATE_PICKER_CALENDAR_DEFAULT_PORTAL_ELEMENT_ID } from './DatePickerBase.constants';
import * as styles from './DatePickerBase.styles';
import { type DatePickerValueProps } from './DatePickerBase.types';
import { useMapDatePickerProps } from './hooks/useMapDatePickerProps';

const YEARS_IN_PERIOD = 12;

export type DatePickerBaseRef<TWithRange extends boolean | undefined = undefined> = ReactDatePicker<TWithRange, false>;

export type DatePickerBaseProps<TWithRange extends boolean | undefined = undefined> = MergeAll<
  [
    Pick<
      ReactDatePickerProps<TWithRange, false>,
      | 'dateFormat'
      | 'disabled'
      | 'isClearable'
      | 'maxDate'
      | 'minDate'
      | 'onCalendarClose'
      | 'onCalendarOpen'
      | 'open'
      | 'popperClassName'
      | 'popperPlacement'
      | 'popperProps'
      | 'required'
      | 'selectsRange'
      | 'showMonthYearPicker'
      | 'showQuarterYearPicker'
      | 'showYearPicker'
      | 'timeFormat'
      | 'wrapperClassName'
    >,
    DatePickerValueProps<TWithRange>,
    {
      onChange?: (value: Parameters<ReactDatePickerProps<TWithRange, false>['onChange']>[0]) => void;
      /** Custom input. If a function, will be passed the open state. */
      children?: ReactNode | ((props: { isOpen: boolean }) => ReactNode);
      /** Custom clear button label. */
      clearButtonLabel?: ReactNode;
      /** Make portalId nullable so we can opt-out of using portalId. */
      portalId?: ReactDatePickerProps<TWithRange, false>['portalId'] | null;
    },
  ]
>;

const defaultPopperProps: DatePickerBaseProps['popperProps'] = {
  strategy: 'fixed',
} as const;

const DatePickerBaseForwardRef = forwardRef(function DatePickerBase<TWithRange extends boolean | undefined = undefined>(
  {
    popperPlacement = 'bottom',
    portalId = DATE_PICKER_CALENDAR_DEFAULT_PORTAL_ELEMENT_ID,
    dateFormat = 'P',
    timeFormat = 'p',
    popperProps = undefined,
    onChange,
    selectsRange,
    isClearable,
    clearButtonLabel,
    value,
    placeholder,
    endDate,
    startDate,
    showMonthYearPicker,
    showQuarterYearPicker,
    showYearPicker,
    children,
    open,
    onCalendarClose,
    onCalendarOpen,
    ...props
  }: DatePickerBaseProps<TWithRange>,
  ref: ForwardedRef<DatePickerBaseRef<TWithRange>>,
) {
  const forwardedRef = useForwardedRef(ref);
  const { isOpen, setOpenTrue, setOpenFalse } = useBoolState(open ?? false, 'open');

  const handleCalendarOpen = useCallback(() => {
    onCalendarOpen?.();
    setOpenTrue();
  }, [onCalendarOpen, setOpenTrue]);

  const handleCalendarClose = useCallback(() => {
    onCalendarClose?.();
    setOpenFalse();
  }, [onCalendarClose, setOpenFalse]);

  const { locale } = useIntl();

  const mergedPopperProps = useMemo(() => merge({}, defaultPopperProps, popperProps), [popperProps]);

  const hasValue = Array.isArray(value) ? value.some((date) => date !== null) : value !== null;

  // onChange passes the event as a second parameter so we ignore it by wrapping it.
  const handleChange = useCallback<NonNullable<typeof onChange>>((value) => onChange?.(value), [onChange]);

  const handleResetValue = useCallback(() => {
    handleChange((selectsRange ? [null, null] : null) as Parameters<typeof handleChange>[0]);

    // There is a bug with the open prop because react-datepicker is dumb af so we need to do it manually :melting-face:.
    forwardedRef.current?.setOpen(false);
  }, [forwardedRef, handleChange, selectsRange]);

  const mappedDatePickerProps = useMapDatePickerProps({
    value,
    placeholder,
    endDate,
    startDate,
  });

  const renderCustomHeader: Required<ReactDatePickerProps<TWithRange, false>>['renderCustomHeader'] = useCallback(
    (headerProps) => (
      <DatePickerBaseHeader
        {...headerProps}
        showMonthYearPicker={showMonthYearPicker}
        showQuarterYearPicker={showQuarterYearPicker}
        showYearPicker={showYearPicker}
      />
    ),
    [showMonthYearPicker, showQuarterYearPicker, showYearPicker],
  );

  return (
    <ClassNames>
      {(classNamesContent) => (
        <ReactDatePicker<TWithRange, false>
          {...props}
          {...mappedDatePickerProps}
          ref={forwardedRef}
          showFourColumnMonthYearPicker
          customInput={isFunction(children) ? children({ isOpen }) : children}
          dateFormat={dateFormat}
          locale={locale}
          popperClassName={clsx(props.popperClassName, styles.datePickerBasePopover(classNamesContent))}
          popperPlacement={popperPlacement}
          popperProps={mergedPopperProps}
          portalId={portalId ?? undefined}
          renderCustomHeader={renderCustomHeader}
          selectsRange={selectsRange}
          showMonthYearPicker={showMonthYearPicker}
          showPopperArrow={false}
          showQuarterYearPicker={showQuarterYearPicker}
          showYearPicker={showYearPicker}
          timeFormat={timeFormat}
          yearItemNumber={YEARS_IN_PERIOD}
          onCalendarClose={handleCalendarClose}
          onCalendarOpen={handleCalendarOpen}
          onChange={handleChange}
        >
          {!!hasValue && !!isClearable && (
            <Button
              size={Button.Size.MEDIUM}
              variant={Button.Variant.PRIMARY_TEXT}
              css={css`
                width: 100%;
                justify-content: center;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
              `}
              onClick={handleResetValue}
            >
              {clearButtonLabel || <FormattedMessage defaultMessage="Clear value" />}
            </Button>
          )}
        </ReactDatePicker>
      )}
    </ClassNames>
  );
});

export const DatePickerBase = memo(DatePickerBaseForwardRef) as <TWithRange extends boolean | undefined = undefined>(
  props: DatePickerBaseProps<TWithRange> & { ref?: ForwardedRef<DatePickerBaseRef<TWithRange>> },
) => ReactElement | null;
