import { type Period } from '@amalia/payout-definition/periods/types';
import { type CustomReport, type CustomReportConfigurationField } from '@amalia/reporting/custom-reports/shared';
import { type Company } from '@amalia/tenants/companies/types';
import { type TeamContract } from '@amalia/tenants/teams/types';
import { type UserContract } from '@amalia/tenants/users/types';

import { type Dashboard } from '../dashboard/dashboard.types';

export enum ChartColor {
  DARK_BLUE = 'DARK_BLUE',
  BLUE = 'BLUE',
  CYAN = 'CYAN',
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  ORANGE = 'ORANGE',
  BROWN = 'BROWN',
  RED = 'RED',
  MAGENTA = 'MAGENTA',
  PURPLE = 'PURPLE',
}

export enum ChartDisplayContext {
  IN_CONFIGURATION = 'IN_CONFIGURATION',
  IN_DASHBOARD = 'IN_DASHBOARD',
}

type ChartDisplaySettingsFilters = {
  users: {
    isEnabled: boolean;
    values: UserContract['id'][];
  };
  teams: {
    isEnabled: boolean;
    values: TeamContract['id'][];
  };
  periods: {
    isEnabled: boolean;
    values: Period['id'][];
  };
};

export const ChartDisplaySettingsXAxis = {
  PERIOD: 'periodStartDate',
  USER: 'userId',
} as const;

export type ChartDisplaySettingsXAxisIdentifier =
  (typeof ChartDisplaySettingsXAxis)[keyof typeof ChartDisplaySettingsXAxis];

export enum ChartType {
  KPI_CARD_CHART = 'KPI_CARD_CHART',
  SIMPLE_BAR_CHART = 'SIMPLE_BAR_CHART',
  SIMPLE_LINE_CHART = 'SIMPLE_LINE_CHART',
}

export enum KPIChartDisplayMode {
  KPI = 'KPI',
  DOUGHNUT = 'DOUGHNUT',
  GAUGE = 'GAUGE',
}

export type ChartDisplaySettingsForChartType<TChartType extends ChartType> = TChartType extends
  | ChartType.SIMPLE_BAR_CHART
  | ChartType.SIMPLE_LINE_CHART
  ? {
      xAxis: {
        identifier: ChartDisplaySettingsXAxisIdentifier;
      };
      yAxis: CustomReportConfigurationField;
    }
  : TChartType extends ChartType.KPI_CARD_CHART
    ?
        | {
            displayMode: KPIChartDisplayMode.DOUGHNUT | KPIChartDisplayMode.KPI;
            kpi: CustomReportConfigurationField;
            segmentsValue?: never;
          }
        | {
            displayMode: KPIChartDisplayMode.GAUGE;
            kpi: CustomReportConfigurationField;
            segmentsValue: number[];
          }
    : never;

export type ChartDisplaySettings<TChartType extends ChartType> = ChartDisplaySettingsForChartType<TChartType> & {
  filters: ChartDisplaySettingsFilters;
  color: ChartColor;
};

export interface DashboardChart<TChartType extends ChartType> {
  id: string;

  createdAt: Date;

  updatedAt: Date;

  company: Company;

  name: string;

  dashboard: Dashboard;

  dashboardId: Dashboard['id'];

  customReport?: CustomReport;

  customReportId: CustomReport['id'];

  type: TChartType;

  displaySettings: ChartDisplaySettings<TChartType>;
}

export const defaultGaugeSegmentsValue = [60, 80, 100];
