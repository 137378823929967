import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';

export default new AmaliaFunctionDefault<[number, number], number>({
  name: AmaliaFunctionKeys.MOD,
  category: AmaliaFunctionCategory.NUMBERS,
  nbParamsRequired: 2,
  description: 'Return the remainder of a division',

  exec: (divident, divisor) => divident % divisor,

  params: [
    { name: 'dividend', description: 'Dividend: numbers, fields, properties, variables' },
    { name: 'divisor', description: 'Divisor: numbers, fields, properties, variables' },
  ],

  examples: [
    {
      desc: 'Returns 2.',
      formula: 'MOD(8, 3)' as AmaliaFormula,
      result: 2,
    },
    {
      desc: 'Returns 0.',
      formula: 'MOD(10, 2)' as AmaliaFormula,
      result: 0,
    },
    {
      desc: 'Returns the remainder of Amount divided by 2.',
      formula: 'MOD(opportunity.amount, 2)',
    },
  ],
});
