import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { type ComputeEngineResult } from '@amalia/payout-calculation/types';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';

export default new AmaliaFunctionDefault<[ComputeEngineResult], number>({
  name: AmaliaFunctionKeys.COUNT,
  category: AmaliaFunctionCategory.ARRAY,

  exec: (array) => (Array.isArray(array) ? array.length : 0),

  nbParamsRequired: 1,

  description: 'Get the number of records in an array',

  params: [{ name: 'array', description: 'Array, Filters or Links', validFormats: [FormatsEnum.table] }],

  examples: [
    {
      desc: 'Returns 2',
      formula: 'COUNT([0, 1])' as AmaliaFormula,
    },
    {
      desc: 'Returns the number of records in the filtered dataset',
      formula: 'COUNT(filter.closedOppsInPeriod)' as AmaliaFormula,
    },
  ],
});
