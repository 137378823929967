import { css } from '@emotion/react';
import { makeStyles } from '@mui/styles';
import { differenceBy, isEmpty } from 'lodash';
import { memo, type SetStateAction, useCallback, useMemo, type Dispatch, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { type Option } from '@amalia/core/types';
import { Checkbox, CircularLoader, Divider, Group, Stack, Typography } from '@amalia/design-system/components';
import { type AmaliaThemeType } from '@amalia/ext/mui/theme';

const styles = makeStyles((theme: AmaliaThemeType) => ({
  loadingContainer: {
    padding: theme.spacing(2),
    minHeight: 100,
  },
  drawerItem: {
    position: 'relative',

    '&, &$listElement': {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.grey['100'],
      padding: theme.spacing(1, 1.5),

      '&:hover $drawerItemActions, &:hover $drawerItemAction': {
        opacity: 1,
      },
    },
  },
}));

type SearchRecordResultsProps = Readonly<{
  foundRecords: Option[];
  isSearchRecordsLoading: boolean;
  selectedRecords: Option[];

  setSelectedRecords: Dispatch<SetStateAction<Option[]>>;
}>;

export const SearchRecordResults = memo(function SearchRecordResults({
  foundRecords,
  isSearchRecordsLoading,
  selectedRecords,
  setSelectedRecords,
}: SearchRecordResultsProps) {
  const classes = styles();

  const availableRecords = useMemo(
    () => differenceBy(foundRecords, selectedRecords, 'value'),
    [foundRecords, selectedRecords],
  );

  /**
   * Select record from search results. Record will appear in selected records.
   */
  const selectRecord = useCallback(
    (record: Option) => {
      setSelectedRecords((currentSelectedRecords) => [...currentSelectedRecords, record]);
    },
    [setSelectedRecords],
  );

  /**
   * Unselect record from selected records.
   */
  const unSelectRecord = useCallback(
    (record: Option) => {
      setSelectedRecords((currentSelectedRecords) => differenceBy(currentSelectedRecords, [record], 'value'));
    },
    [setSelectedRecords],
  );

  return (
    <Stack gap={16}>
      {isSearchRecordsLoading ? (
        <Group
          align="center"
          className={classes.loadingContainer}
          justify="center"
        >
          <CircularLoader />
        </Group>
      ) : (
        <Stack
          as="ul"
          gap={4}
          css={css`
            list-style-type: none;
          `}
        >
          {availableRecords.map((record) => (
            <li
              key={record.value}
              className={classes.drawerItem}
            >
              <Checkbox
                label={record.label}
                onChange={() => selectRecord(record)}
              />
            </li>
          ))}
        </Stack>
      )}

      {!isEmpty(selectedRecords) && (
        <Fragment>
          <Divider.Horizontal />

          <Stack gap={8}>
            <Typography variant={Typography.Variant.BODY_LARGE_MEDIUM}>
              <FormattedMessage defaultMessage="Selected records" />
            </Typography>

            <Stack
              as="ul"
              gap={4}
              css={css`
                list-style-type: none;
              `}
            >
              {selectedRecords.map((record) => (
                <li
                  key={record.value}
                  className={classes.drawerItem}
                >
                  <Checkbox
                    checked
                    label={record.label}
                    onChange={() => unSelectRecord(record)}
                  />
                </li>
              ))}
            </Stack>
          </Stack>
        </Fragment>
      )}
    </Stack>
  );
});
