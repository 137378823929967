import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { assert } from '@amalia/ext/typescript';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';

export default new AmaliaFunctionDefault<[string | null | undefined, number, number?], string | null>({
  name: AmaliaFunctionKeys.SLICE,
  category: AmaliaFunctionCategory.STRING,
  nbParamsRequired: 2,
  description: 'Return a part of a string',

  exec: (text, indexStart, indexEnd) => {
    assert(text !== null && text !== undefined, `${AmaliaFunctionKeys.SLICE}: source string is null or undefined`);
    return text.toString().slice(indexStart, indexEnd);
  },

  params: [
    { name: 'string', description: 'String: variables, fields, properties, string' },
    {
      name: 'startPosition',
      description: 'Position to start at. Starting at 0. Can be negative to start from the right',
    },
    {
      name: 'endPosition',
      description: 'Position to end at. By default it takes everything left in the string',
      defaultValue: null,
    },
  ],

  examples: [
    {
      desc: 'Returns the lazy dog. because end position is undefined.',
      formula: 'SLICE("The quick brown fox jumps over the lazy dog.", 31)' as AmaliaFormula,
      result: 'the lazy dog.',
    },
    {
      desc: 'Returns quick brown fox.',
      formula: 'SLICE("The quick brown fox jumps over the lazy dog.", 4, 19)' as AmaliaFormula,
      result: 'quick brown fox',
    },
    {
      desc: 'Returns dog. because it starts from the right since the startPosition is negative.',
      formula: 'SLICE("The quick brown fox jumps over the lazy dog.", -4)' as AmaliaFormula,
      result: 'dog.',
    },
    {
      desc: 'Returns dog. because it starts from the right at -9 and ends at -5.',
      formula: 'SLICE("The quick brown fox jumps over the lazy dog.", -9, -5)' as AmaliaFormula,
      result: 'lazy',
    },
  ],
});
