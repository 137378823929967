import { useFormikContext } from 'formik';
import { memo, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { formatPeriodName, formatUserFullName } from '@amalia/core/types';
import { UserPrettyFormat } from '@amalia/data-capture/fields/components';
import { Filters, type FilterSelectOption, type SelectOptionValue } from '@amalia/design-system/components';
import { usePeriodOptions, usePeriodSelector } from '@amalia/lib-ui-business';
import { usePeriods } from '@amalia/payout-definition/periods/components';
import { RelativePeriodKeyword, type Period } from '@amalia/payout-definition/periods/types';
import { useCustomReportFacet, useCustomReportsList } from '@amalia/reporting/custom-reports/state';
import { type ChartType, type DashboardChartConfiguration } from '@amalia/reporting/dashboards-v2/types';
import { useTeams } from '@amalia/tenants/teams/state';
import { useAuthorizedProfiles } from '@amalia/tenants/users/profile/state';
import { type UserProfile } from '@amalia/tenants/users/profile/types';

export const DashboardChartConfigurationFilters = memo(function DashboardChartConfigurationFilters() {
  const { values, setFieldValue } = useFormikContext<DashboardChartConfiguration<ChartType>>();

  const { data: customReportList } = useCustomReportsList();

  const selectedReport = useMemo(
    () => customReportList.find((report) => report.id === values.customReportId),
    [customReportList, values.customReportId],
  );

  const { periodsList } = usePeriods();

  const { data: periodsFacet } = useCustomReportFacet(
    selectedReport?.id ?? null,
    selectedReport
      ? {
          configuration: selectedReport.configuration,
          field: {
            identifier: 'periodId',
          },
        }
      : null,
  );

  const periodOptions = usePeriodOptions({
    overridesPeriodOptions: useMemo(
      () =>
        periodsList
          .filter((period) => periodsFacet.find((facet) => facet.value === period.id))
          .map((period) => ({
            label: formatPeriodName(period.frequency, period.startDate),
            value: period.id,
          }))
          .toReversed(),
      [periodsList, periodsFacet],
    ),
    periodList: periodsList,
    relativePeriodToOmit: [RelativePeriodKeyword.YEAR_TO_DATE, RelativePeriodKeyword.LAST_12_MONTHS],
  });

  const handleChangePeriods = useCallback(
    (periodIds: SelectOptionValue[]) => setFieldValue('displaySettings.filters.periods.values', periodIds),
    [setFieldValue],
  );

  const { onChange: handleChangePeriodsProxy } = usePeriodSelector({
    onChange: handleChangePeriods,
  });

  const { teamsList } = useTeams();

  const { data: teamsFacet } = useCustomReportFacet(
    selectedReport?.id ?? null,
    selectedReport
      ? {
          configuration: selectedReport.configuration,
          field: {
            identifier: 'teamId',
          },
        }
      : null,
  );

  const teamOptions = useMemo(
    () =>
      teamsList
        .filter((team) => teamsFacet.find((facet) => facet.value === team.id))
        .map((team) => ({
          label: team.name,
          value: team.id,
        })),
    [teamsList, teamsFacet],
  );

  const handleChangeTeams = useCallback(
    (teamIds: SelectOptionValue[]) => setFieldValue('displaySettings.filters.teams.values', teamIds),
    [setFieldValue],
  );

  const { data: allUsersCurrentUserHasAccessTo } = useAuthorizedProfiles();

  const { data: usersFacet } = useCustomReportFacet(
    selectedReport?.id ?? null,
    selectedReport
      ? {
          configuration: selectedReport.configuration,
          field: {
            identifier: 'userId',
          },
        }
      : null,
  );

  const userOptions = useMemo(
    () =>
      allUsersCurrentUserHasAccessTo
        .filter((user) => usersFacet.find((facet) => facet.value === user.id))
        .map((user) => ({
          label: (
            <UserPrettyFormat
              firstName={user.firstName}
              lastName={user.lastName}
              pictureURL={user.pictureURL}
            />
          ),
          filterLabel: formatUserFullName(user),
          valueLabel: formatUserFullName(user),
          value: user.id,
        })),
    [allUsersCurrentUserHasAccessTo, usersFacet],
  );

  const handleChangeUsers = useCallback(
    (userIds: SelectOptionValue[]) => setFieldValue('displaySettings.filters.users.values', userIds),
    [setFieldValue],
  );

  const shouldDisplayPeriodsFilter = values.displaySettings.filters.periods.isEnabled;
  const shouldDisplayTeamsFilter = values.displaySettings.filters.teams.isEnabled;
  const shouldDisplayUsersFilter = values.displaySettings.filters.users.isEnabled;

  return (
    <Filters>
      {!!shouldDisplayPeriodsFilter && (
        <Filters.FilterSelect<FilterSelectOption<Period['id']>, true>
          isMultiple
          isStatic
          allSelectedLabel={<FormattedMessage defaultMessage="All periods" />}
          id="filter-chart-periods"
          label={<FormattedMessage defaultMessage="Periods" />}
          options={periodOptions}
          value={values.displaySettings.filters.periods.values}
          onChange={handleChangePeriodsProxy}
        />
      )}

      {!!shouldDisplayTeamsFilter && (
        <Filters.FilterSelect<FilterSelectOption<string>, true>
          isMultiple
          isStatic
          allSelectedLabel={<FormattedMessage defaultMessage="All teams" />}
          id="filter-chart-teams"
          label={<FormattedMessage defaultMessage="Teams" />}
          options={teamOptions}
          value={values.displaySettings.filters.teams.values}
          onChange={handleChangeTeams}
        />
      )}
      {!!shouldDisplayUsersFilter && (
        <Filters.FilterSelect<FilterSelectOption<UserProfile['id']>, true>
          isMultiple
          isStatic
          allSelectedLabel={<FormattedMessage defaultMessage="All users" />}
          id="filter-chart-users"
          label={<FormattedMessage defaultMessage="Users" />}
          options={userOptions}
          value={values.displaySettings.filters.users.values}
          onChange={handleChangeUsers}
        />
      )}
    </Filters>
  );
});
