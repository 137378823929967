import { type Command, type Content, type Range } from '@tiptap/core';
import { type Node } from 'prosemirror-model';

import { FORMULA_TOKEN_NODE_NAME, type FormulaEditorToken } from '@amalia/amalia-lang/formula/components';
import { AmaliaFunction } from '@amalia/amalia-lang/formula/evaluate/shared';
import { type AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { TokenType } from '@amalia/amalia-lang/tokens/types';

const appendFunctionSignatureToContent = (content: Content[], token: FormulaEditorToken): Content[] => {
  if (token.type !== TokenType.FUNCTION) {
    return content;
  }

  const func = AmaliaFunction.getAllFunctions()[token.formula as AmaliaFunctionKeys];

  return [
    ...content,
    {
      type: 'text',
      text: `(${Array(func.nbParamsRequired)
        .map(() => '')
        .join(', ')})`,
    },
  ];
};

const getExistingObjectTokenNodeForCurrentPosition = (
  range: Range,
  editorContent: Node,
): { node: Node | null; position: number } | null => {
  const currentNode = editorContent.nodeAt(range.from + 1);
  const previousNode = editorContent.nodeAt(range.from);
  if (currentNode?.text?.startsWith('.') && previousNode?.type.name === FORMULA_TOKEN_NODE_NAME) {
    return { node: previousNode, position: range.from };
  }

  return null;
};

/**
 * Append a formula token in editor content. Move the cursor to the end of the inserted token.
 * @param token
 * @param range
 * @param editor
 */
export const appendToken =
  ({ token, range }: { token: FormulaEditorToken; range: Range }): Command =>
  ({ chain, state }) => {
    const objectTokenNode = getExistingObjectTokenNodeForCurrentPosition(range, state.doc);
    let content: Content[] = [
      {
        type: FORMULA_TOKEN_NODE_NAME,
        attrs: { formula: objectTokenNode ? `${objectTokenNode.node?.attrs.formula}.${token.formula}` : token.formula },
      },
    ];

    // For functions, we want to append the function signature as well.
    if (token.type === TokenType.FUNCTION) {
      content = appendFunctionSignatureToContent(content, token);
    }

    const insertionRange = objectTokenNode ? { from: objectTokenNode.position, to: range.to } : range;

    return (
      chain()
        .focus()
        .insertContentAt(insertionRange, content)
        // Move the cursor to the end of inserted content.
        .setTextSelection(insertionRange.from + content.length)
        .run()
    );
  };
