import { css } from '@emotion/react';
import { IconChevronDown, IconInfoCircle } from '@tabler/icons-react';
import clsx from 'clsx';
import {
  type ReactNode,
  memo,
  type ReactElement,
  type JSXElementConstructor,
  cloneElement,
  type KeyboardEvent,
  Fragment,
  useMemo,
  useRef,
} from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import { type MainKpi } from '@amalia/core/types';
import {
  Divider,
  Typography,
  type GrabHandleProps,
  Tooltip,
  TextOverflow,
  QuickEdit,
} from '@amalia/design-system/components';
import { eventStopPropagation, ignoreOutsideEvents } from '@amalia/ext/web';
import { ForecastSimulationPopover } from '@amalia/payout-calculation/forecasts/components';
import { PlanRuleCategoryBadge } from '@amalia/payout-definition/plans/rules/categories/components';
import { type PlanCategory, RuleType } from '@amalia/payout-definition/plans/types';

import { KpiRuleItem } from '../kpi-rule-item/KpiRuleItem';

import * as styles from './RuleAccordionHeader.styles';

export type RuleAccordionHeaderProps<TIsEditable extends boolean | undefined = undefined> = {
  readonly label: TIsEditable extends false | undefined ? ReactNode : string;
  readonly helpLabel?: string;
  readonly machineName: string;
  readonly mainKpi?: MainKpi | MainKpi[];
  readonly isSubSummary?: boolean;
  readonly actions?: ReactNode;
  readonly category?: PlanCategory;
  readonly grabHandle?: ReactElement<GrabHandleProps, JSXElementConstructor<GrabHandleProps>>;
  readonly isExpanded: boolean;
  readonly isLabelEditable?: TIsEditable;
  readonly hideActions?: boolean;
  readonly onToggleExpanded: () => void;
  readonly challengeNotifyButton?: ReactElement;
  readonly onChangeLabel?: (newLabel: string) => Promise<void> | void;
};

const labelSchema = Yup.string().required().min(2).max(100);

const RuleAccordionHeaderBase = function RuleAccordionHeader<TIsEditable extends boolean | undefined = undefined>({
  label,
  helpLabel,
  machineName,
  mainKpi,
  isSubSummary,
  actions,
  category,
  grabHandle,
  isExpanded,
  isLabelEditable = false as TIsEditable,
  hideActions = false,
  challengeNotifyButton,
  onToggleExpanded,
  onChangeLabel,
}: RuleAccordionHeaderProps<TIsEditable>) {
  const { formatMessage } = useIntl();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const interactiveProps = isSubSummary
    ? {}
    : ({
        role: 'button',
        tabIndex: 0,
        onClick: ignoreOutsideEvents(containerRef, onToggleExpanded),
        onKeyDown: ignoreOutsideEvents<KeyboardEvent<HTMLDivElement>>(containerRef, (event) => {
          if (event.key === 'Enter' || event.key === ' ') {
            onToggleExpanded();
          }
        }),
      } as const);

  const kpis = useMemo((): MainKpi[] => ([] as MainKpi[]).concat(mainKpi).filter(Boolean), [mainKpi]);

  const isNonPayoutRule = kpis[0]?.type === RuleType.NON_PAYOUT;
  const isHideTotal = kpis[0]?.hideTotal;

  return (
    <div
      {...interactiveProps}
      ref={containerRef}
      aria-expanded={isExpanded}
      className={clsx({ [styles.IS_SUB_SUMMARY_CLASSNAME]: isSubSummary })}
      css={styles.ruleAccordionHeader}
      data-hide-actions={hideActions}
      id={`${machineName}-bh-header`}
    >
      <div css={styles.leftContainer}>
        {!!grabHandle && cloneElement(grabHandle, { size: 18 })}

        <div css={styles.labelContainer}>
          {!isSubSummary && (
            <IconChevronDown
              size={20}
              css={[
                styles.chevronIcon,
                css`
                  transform: rotate(${isExpanded ? -180 : 0}deg);
                `,
              ]}
            />
          )}

          <div css={styles.labelDescriptionContainer}>
            {isLabelEditable ? (
              <QuickEdit
                editTooltip={formatMessage({ defaultMessage: 'Edit rule name' })}
                schema={labelSchema}
                value={label as string}
                variant={isSubSummary ? QuickEdit.Variant.BODY_LARGE_REGULAR : QuickEdit.Variant.HEADING_4_MEDIUM}
                css={css`
                  // For the TextOverflow component to work properly.
                  min-width: 0;
                `}
                formattedValue={
                  <span
                    css={(theme) => css`
                      padding-left: ${isSubSummary ? 8 : 12}px;
                      color: ${isNonPayoutRule ? theme.ds.colors.gray[500] : theme.ds.colors.gray[900]};
                    `}
                  >
                    {label}
                  </span>
                }
                onChange={onChangeLabel}
                onClick={eventStopPropagation}
                onKeyDown={eventStopPropagation}
              />
            ) : (
              <Typography
                as={TextOverflow}
                variant={isSubSummary ? Typography.Variant.BODY_LARGE_REGULAR : Typography.Variant.HEADING_4_MEDIUM}
                css={(theme) => css`
                  color: ${isNonPayoutRule ? theme.ds.colors.gray[500] : theme.ds.colors.gray[900]};
                `}
              >
                {label}
              </Typography>
            )}

            {!!helpLabel && (
              <Tooltip content={helpLabel}>
                <IconInfoCircle
                  size={16}
                  css={css`
                    flex: none;
                  `}
                />
              </Tooltip>
            )}
          </div>

          {!!category && (
            <PlanRuleCategoryBadge
              category={category}
              size={PlanRuleCategoryBadge.Size.SMALL}
            />
          )}
        </div>
      </div>

      {!isHideTotal && (
        <div css={styles.rightContainer}>
          {challengeNotifyButton}

          <div css={styles.kpisContainer}>
            {kpis.map((kpi, index) => (
              <Fragment key={kpi.label || kpi.type}>
                {index > 0 && <Divider.Vertical />}

                <ForecastSimulationPopover
                  disabled={!kpi.isForecastedView || !kpi.isValueForecasted}
                  formattedAchievedValue={kpi.formattedOriginalValue}
                  formattedForecastedValue={kpi.formattedForecastedValue}
                >
                  <KpiRuleItem
                    mainKpi={kpi}
                    variant={isSubSummary ? 'small' : 'default'}
                  />
                </ForecastSimulationPopover>
              </Fragment>
            ))}
          </div>

          <div css={styles.actionsContainer}>{actions}</div>
        </div>
      )}
    </div>
  );
};

export const RuleAccordionHeader = memo(RuleAccordionHeaderBase) as typeof RuleAccordionHeaderBase;
