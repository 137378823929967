import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { HttpStatusCode } from 'axios';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { isHttpError } from '@amalia/core/http/client';
import { useSnackbars } from '@amalia/design-system/components';
import { toError } from '@amalia/ext/typescript';
import { TeamsApiClient } from '@amalia/tenants/teams/api-client';
import { type TeamContract } from '@amalia/tenants/teams/types';

import { TEAM_QUERY_KEYS } from './queries.keys';

export const useTeam = (teamId: string) => {
  const { snackError } = useSnackbars();

  const {
    data: team,
    isError,
    error,
    isSuccess,
  } = useQuery({
    queryKey: [TEAM_QUERY_KEYS.TEAMS, teamId],
    queryFn: () => TeamsApiClient.getTeam(teamId),
  });

  useEffect(() => {
    if (isError) {
      snackError(toError(error).message);
    }
  }, [isError, error, snackError]);

  return { team, isSuccess, isError };
};

export const useDeleteTeam = () => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: [TEAM_QUERY_KEYS.TEAMS, 'delete'],
    mutationFn: (team: Pick<TeamContract, 'id' | 'name'>) => TeamsApiClient.deleteTeam(team.id),
    onSuccess: async (_, team) => {
      snackSuccess(
        formatMessage({ defaultMessage: '“{teamName}” has been deleted successfully.' }, { teamName: team.name }),
      );

      await queryClient.invalidateQueries({ queryKey: [TEAM_QUERY_KEYS.TEAMS] });
    },
    onError: (err, team) => {
      snackError(
        formatMessage(
          { defaultMessage: '“{teamName}” could not be deleted: {errorMessage}.' },
          { teamName: team.name, errorMessage: toError(err).message },
        ),
      );
    },
  });
};

export const useCreateTeam = () => {
  const queryClient = useQueryClient();
  const { snackSuccess } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: [TEAM_QUERY_KEYS.TEAMS, 'create'],
    mutationFn: TeamsApiClient.createTeam,
    onSuccess: async (team: TeamContract) => {
      snackSuccess(
        formatMessage({ defaultMessage: '“{teamName}” has been created successfully.' }, { teamName: team.name }),
      );

      await queryClient.invalidateQueries({ queryKey: [TEAM_QUERY_KEYS.TEAMS] });
    },
  });
};

export const useSetTeamName = () => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: [TEAM_QUERY_KEYS.TEAMS, 'set-team-name'],
    mutationFn: TeamsApiClient.setTeamName,
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Team name updated successfully.' }));
      await queryClient.invalidateQueries({ queryKey: [TEAM_QUERY_KEYS.TEAMS] });
    },
    onError: (err) => {
      if (!isHttpError(err) || err.statusCode !== HttpStatusCode.Conflict) {
        snackError(
          formatMessage(
            { defaultMessage: 'Team name could not be updated: {errorMessage}.' },
            { errorMessage: err.message },
          ),
        );
      }
    },
  });
};

export const useLinkExistingTeams = () => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: [TEAM_QUERY_KEYS.TEAMS, 'link-existing-teams'],
    mutationFn: TeamsApiClient.linkExistingTeams,
    onSuccess: async (_, { childrenTeamIds }) => {
      snackSuccess(
        formatMessage(
          { defaultMessage: '{count, plural, one {Child team} other {Children teams}} linked.' },
          { count: childrenTeamIds.length },
        ),
      );

      await queryClient.invalidateQueries({ queryKey: [TEAM_QUERY_KEYS.TEAMS] });
    },
    onError: (error, { childrenTeamIds }) => {
      snackError(
        formatMessage(
          {
            defaultMessage:
              '{count, plural, one {Child team} other {Children teams}} could not be linked: {errorMessage}.',
          },
          { count: childrenTeamIds.length, errorMessage: error.message },
        ),
      );
    },
  });
};

export const useUnlinkFromParentTeam = () => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: [TEAM_QUERY_KEYS.TEAMS, 'unlink-from-parent-team'],
    mutationFn: TeamsApiClient.unlinkFromParentTeam,
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Team has been successfully unlinked from its parent team.' }));
      await queryClient.invalidateQueries({ queryKey: [TEAM_QUERY_KEYS.TEAMS] });
    },
    onError: (err) => {
      snackError(
        formatMessage(
          { defaultMessage: 'Team could not be unlinked from its parent team: {errorMessage}.' },
          { errorMessage: toError(err).message },
        ),
      );
    },
  });
};

export const useArchiveTeam = () => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: [TEAM_QUERY_KEYS.TEAMS, 'archive'],
    mutationFn: TeamsApiClient.archiveTeam,
    onSuccess: async ({ archivedTeamsCount }) => {
      snackSuccess(
        formatMessage(
          { defaultMessage: '{teamsCount, plural, one {# team has} other {# teams have}} been successfully archived.' },
          { teamsCount: archivedTeamsCount },
        ),
      );

      // Invalidate teams and teams assignments.
      await queryClient.invalidateQueries({ queryKey: [TEAM_QUERY_KEYS.TEAMS] });
    },
    onError: (err) => {
      snackError(
        formatMessage({ defaultMessage: 'Team could not be archived: {errorMessage}.' }, { errorMessage: err.message }),
      );
    },
  });
};

export const useUnarchiveTeam = () => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: [TEAM_QUERY_KEYS.TEAMS, 'unarchive'],
    mutationFn: TeamsApiClient.unarchiveTeam,
    onSuccess: async ({ unarchivedTeamsCount }) => {
      snackSuccess(
        formatMessage(
          {
            defaultMessage: '{teamsCount, plural, one {# team has} other {# teams have}} been successfully unarchived.',
          },
          { teamsCount: unarchivedTeamsCount },
        ),
      );

      await queryClient.invalidateQueries({ queryKey: [TEAM_QUERY_KEYS.TEAMS] });
    },
    onError: (err) => {
      snackError(
        formatMessage(
          { defaultMessage: 'Team could not be unarchived: {errorMessage}.' },
          { errorMessage: err.message },
        ),
      );
    },
  });
};
