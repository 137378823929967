import { useTheme } from '@emotion/react';
import { defineMessages } from 'react-intl';

import { HueCategory } from '@amalia/design-system/meta';
import { ChartColor } from '@amalia/reporting/dashboards-v2/types';

export const useChartColor = (): ChartColorMap => {
  const theme = useTheme();
  return {
    [ChartColor.DARK_BLUE]: theme.ds.hues[HueCategory.BLUE][900],
    [ChartColor.BLUE]: theme.ds.hues[HueCategory.BLUE][500],
    [ChartColor.CYAN]: theme.ds.hues[HueCategory.CYAN][300],
    [ChartColor.GREEN]: theme.ds.hues[HueCategory.GREEN][500],
    [ChartColor.YELLOW]: theme.ds.hues[HueCategory.YELLOW][300],
    [ChartColor.ORANGE]: theme.ds.hues[HueCategory.ORANGE][300],
    [ChartColor.BROWN]: theme.ds.hues[HueCategory.BROWN][300],
    [ChartColor.RED]: theme.ds.hues[HueCategory.RED][500],
    [ChartColor.MAGENTA]: theme.ds.hues[HueCategory.MAGENTA][300],
    [ChartColor.PURPLE]: theme.ds.hues[HueCategory.PURPLE][300],
  };
};

export type ChartColorMap = Record<ChartColor, string>;

export const colorsLabels = defineMessages<ChartColor>({
  [ChartColor.DARK_BLUE]: { defaultMessage: 'Dark blue' },
  [ChartColor.BLUE]: { defaultMessage: 'Blue' },
  [ChartColor.CYAN]: { defaultMessage: 'Cyan' },
  [ChartColor.GREEN]: { defaultMessage: 'Green' },
  [ChartColor.YELLOW]: { defaultMessage: 'Yellow' },
  [ChartColor.ORANGE]: { defaultMessage: 'Orange' },
  [ChartColor.BROWN]: { defaultMessage: 'Brown' },
  [ChartColor.RED]: { defaultMessage: 'Red' },
  [ChartColor.MAGENTA]: { defaultMessage: 'Magenta' },
  [ChartColor.PURPLE]: { defaultMessage: 'Purple' },
});
