import { css } from '@emotion/react';

import { TypographyVariant } from '@amalia/design-system/meta';

import { LabelDisplay, LabelVariant } from '../../types';
import { UserPrettyFormatSize, type UserPrettyFormatSupportedLabelVariant } from '../types';

export const NAME_TYPOGRAPHY_VARIANT: Record<
  UserPrettyFormatSize,
  Record<UserPrettyFormatSupportedLabelVariant, TypographyVariant>
> = {
  [UserPrettyFormatSize.SMALL]: {
    [LabelVariant.DEFAULT]: TypographyVariant.BODY_SMALL_REGULAR,
    [LabelVariant.ACCENTUATED]: TypographyVariant.BODY_SMALL_MEDIUM,
    [LabelVariant.BOLD]: TypographyVariant.BODY_SMALL_MEDIUM,
  },
  [UserPrettyFormatSize.MEDIUM]: {
    [LabelVariant.DEFAULT]: TypographyVariant.BODY_BASE_REGULAR,
    [LabelVariant.ACCENTUATED]: TypographyVariant.BODY_BASE_MEDIUM,
    [LabelVariant.BOLD]: TypographyVariant.BODY_BASE_MEDIUM,
  },
} as const;

export const EXTERNAL_ID_TYPOGRAPHY_VARIANT = TypographyVariant.BODY_XSMALL_REGULAR;

export const styles = {
  container: css`
    --row-gap: 0px;

    display: grid;
    grid-template-areas: 'avatar user-info';
    justify-content: flex-start;
    gap: var(--row-gap) var(--column-gap);

    line-height: initial; // Need to reset the line-height here or both text lines will have incorrect line height.
  `,
  containerBlockDisplay: css`
    align-items: center;
  `,
  containerInlineDisplay: css`
    align-items: center;
  `,
  avatarSkeleton: css`
    grid-area: avatar;

    /** Don't allow the skeleton & avatar to shrink when the full name is long */
    flex-shrink: 0;
  `,
  userInfoContainer: css`
    grid-area: user-info;

    display: grid;
    gap: var(--row-gap) var(--column-gap);
    grid-template-areas: 'name' 'sub-label';

    align-items: baseline;
    word-break: break-word;
  `,
  userInfoContainerInlineDisplay: css`
    grid-template-areas: 'name sub-label';
    overflow: hidden;
    white-space: nowrap;
  `,
  nameSkeleton: css`
    grid-area: name;
    // For text overflow.
    min-width: 0;
  `,
  subLabel: css`
    // For text overflow.
    min-width: 0;
    grid-area: sub-label;
  `,
} as const;

export const userPrettyFormatGap: Record<
  LabelDisplay,
  Record<UserPrettyFormatSize, Record<'false' | 'true', number>>
> = {
  [LabelDisplay.BLOCK]: {
    [UserPrettyFormatSize.SMALL]: {
      true: 8,
      false: 6,
    },
    [UserPrettyFormatSize.MEDIUM]: {
      true: 12,
      false: 8,
    },
  },
  [LabelDisplay.INLINE]: {
    [UserPrettyFormatSize.SMALL]: {
      true: 6,
      false: 6,
    },
    [UserPrettyFormatSize.MEDIUM]: {
      true: 8,
      false: 8,
    },
  },
} as const;

export const userPrettyFormatAvatarSize: Record<
  LabelDisplay,
  Record<UserPrettyFormatSize, Record<'false' | 'true', number>>
> = {
  [LabelDisplay.BLOCK]: {
    [UserPrettyFormatSize.SMALL]: {
      true: 28,
      false: 16,
    },
    [UserPrettyFormatSize.MEDIUM]: {
      true: 30,
      false: 20,
    },
  },
  [LabelDisplay.INLINE]: {
    [UserPrettyFormatSize.SMALL]: {
      true: 16,
      false: 16,
    },
    [UserPrettyFormatSize.MEDIUM]: {
      true: 20,
      false: 20,
    },
  },
} as const;
