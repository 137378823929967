import { css } from '@emotion/react';
import { type ComponentPropsWithoutRef, type ForwardedRef, forwardRef, memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { formatUserFullName } from '@amalia/core/types';
import { Avatar, Skeleton, TextOverflow, Typography, useAvatarUser } from '@amalia/design-system/components';
import { BorderRadiusVariant } from '@amalia/design-system/meta';

import { accentuatedLabel, defaultLabel, dimmedLabel } from '../../FieldValuePrettyFormat.styles';
import { LabelDisplay, LabelVariant } from '../../types';
import { UserPrettyFormatSize, type UserPrettyFormatSupportedLabelVariant } from '../types';

import {
  EXTERNAL_ID_TYPOGRAPHY_VARIANT,
  NAME_TYPOGRAPHY_VARIANT,
  styles,
  userPrettyFormatAvatarSize,
  userPrettyFormatGap,
} from './UserPrettyFormat.styles';

export type UserPrettyFormatProps = ComponentPropsWithoutRef<'div'> & {
  /** Whether the user info is loading. This will display a Skeleton over the avatar and the full name. */
  readonly isLoading?: boolean;

  /**
   * What is displayed under the full name.
   * For example, the user's external id or the email.
   */
  readonly subLabel?: string;
  /** The user's first name */
  readonly firstName?: string | null;
  /** The user's last name */
  readonly lastName?: string | null;
  /** The user's avatar url */
  readonly pictureURL?: string | null;

  /**
   * How the label is displayed.
   * BLOCK is multiline, INLINE is on a single line.
   */
  readonly display?: LabelDisplay.BLOCK | LabelDisplay.INLINE;
  /**
   * The variant of the label.
   * Accentuated is bolder and colored.
   * Note: DIMMED is not supported and you should fall back to DEFAULT.
   */
  readonly variant?: LabelVariant;
  /**
   * Size of the user pretty format.
   * This is specific to UserPrettyFormat (for now at least) and not for all FieldValuePrettyFormat so it's not LabelSize.
   * When using FieldValuePrettyFormat, the size will be the default one (MEDIUM).
   */
  readonly size?: UserPrettyFormatSize;
};

const UserPrettyFormatBase = forwardRef(function UserPrettyFormat(
  {
    isLoading = false,
    firstName,
    lastName,
    subLabel,
    pictureURL,
    display = LabelDisplay.BLOCK,
    variant = LabelVariant.DEFAULT,
    size = UserPrettyFormatSize.MEDIUM,
    ...props
  }: UserPrettyFormatProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  // DIMMED variant is not supported yet
  const supportedVariant: UserPrettyFormatSupportedLabelVariant =
    variant === LabelVariant.DIMMED ? LabelVariant.DEFAULT : variant;

  const avatarUser = useAvatarUser({
    firstName,
    lastName,
    pictureURL,
  });

  return (
    <div
      ref={ref}
      {...props}
      css={[
        css`
          --column-gap: ${userPrettyFormatGap[display][size][`${!!subLabel}`]}px;
        `,
        styles.container,
        display === LabelDisplay.BLOCK && styles.containerBlockDisplay,
        display === LabelDisplay.INLINE && styles.containerInlineDisplay,
      ]}
    >
      <Skeleton
        css={[styles.avatarSkeleton]}
        data-testid="user-field-label-avatar-skeleton"
        shape={BorderRadiusVariant.ROUND}
        visible={isLoading}
      >
        <Avatar
          size={userPrettyFormatAvatarSize[display][size][`${!!subLabel}`]}
          user={avatarUser}
        />
      </Skeleton>

      <div css={[styles.userInfoContainer, display === LabelDisplay.INLINE && styles.userInfoContainerInlineDisplay]}>
        <Skeleton
          css={[styles.nameSkeleton, (theme) => theme.ds.typographies[NAME_TYPOGRAPHY_VARIANT[size][supportedVariant]]]}
          data-testid="user-field-label-name-skeleton"
          visible={isLoading}
        >
          <Typography
            as={TextOverflow}
            variant={NAME_TYPOGRAPHY_VARIANT[size][supportedVariant]}
            css={[
              supportedVariant === LabelVariant.DEFAULT && defaultLabel,
              supportedVariant === LabelVariant.ACCENTUATED && accentuatedLabel,
            ]}
          >
            {formatUserFullName({ firstName, lastName }) || <FormattedMessage defaultMessage="Unsynced user" />}
          </Typography>
        </Skeleton>

        {!!subLabel && (
          <div css={[dimmedLabel, styles.subLabel]}>
            <Typography
              as={TextOverflow}
              variant={EXTERNAL_ID_TYPOGRAPHY_VARIANT}
            >
              {subLabel}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
});

export const UserPrettyFormat = memo(UserPrettyFormatBase) as typeof UserPrettyFormatBase;

/*
 * There are multiple possible states for this component:
 *
 * - Loading: We show a loading indicator using a Skeleton.
 * Only the subLabel (externalId) is shown as it is the only prop we have.
 *
 * - User Is Not Found: We show a message saying that the user
 * was not synced (yet/anymore).
 * We show the subLabel (externalId) and * "User not synced" as the fullName.
 * Fallback avatar should be a random "default" avatar.
 * (TODO): default avatar should be handled by Avatar component
 *
 * - Idle: We show the fullName, the subLabel (externalId) and the avatar.
 */
