import { css, type Theme } from '@emotion/react';
import { type ForwardedRef, forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { UnstyledButton } from '@amalia/design-system/components';

import {
  AVATAR_OVERLAP,
  UsersAvatarStackSizeToAvatarSize,
  UsersAvatarStackSizeToTypographyVariant,
} from '../UsersAvatarStack.constants';
import { type UsersAvatarStackSize } from '../UsersAvatarStack.types';

export type MoreUsersButtonProps = {
  readonly as?: 'button' | 'div';
  readonly usersCount: number;
  readonly maxUsers: number;
  readonly size: UsersAvatarStackSize;
};

const style = (maxUsers: number, size: UsersAvatarStackSize) => (theme: Theme) => css`
  ${theme.ds.typographies[UsersAvatarStackSizeToTypographyVariant[size]]};

  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  box-sizing: border-box;

  width: ${UsersAvatarStackSizeToAvatarSize[size]}px;
  height: ${UsersAvatarStackSizeToAvatarSize[size]}px;

  border: 1px solid ${theme.ds.colors.gray[0]};
  border-radius: ${theme.ds.borderRadiuses.round};

  transition: ${theme.ds.transitions.default('height', 'width', 'font-size')};
  overflow: hidden;

  background-color: ${theme.ds.colors.graySecondary[50]};
  color: ${theme.ds.colors.primary[700]};

  position: relative;
  left: ${(maxUsers - 1) * AVATAR_OVERLAP * -1}px;
`;

export const MoreUsersButton = forwardRef(function MoreUsersButton(
  { as = 'button', usersCount, maxUsers, size }: MoreUsersButtonProps,
  ref: ForwardedRef<HTMLButtonElement | HTMLDivElement>,
) {
  const message = (
    <FormattedMessage
      defaultMessage="+{count, number}"
      values={{ count: usersCount - maxUsers + 1 }}
    />
  );

  return as === 'button' ? (
    <UnstyledButton
      ref={ref as ForwardedRef<HTMLButtonElement>}
      css={style(maxUsers, size)}
    >
      {message}
    </UnstyledButton>
  ) : (
    <div
      ref={ref as ForwardedRef<HTMLDivElement>}
      css={style(maxUsers, size)}
    >
      {message}
    </div>
  );
});
