import { useTheme } from '@emotion/react';
import { memo, useMemo } from 'react';
import { Cell, Pie, PieChart } from 'recharts';

import { ColorCategory } from '@amalia/design-system/meta';
import { type CustomReportColumn } from '@amalia/reporting/custom-reports/shared';
import {
  type DashboardChartResult,
  type ChartType,
  type DashboardChartConfiguration,
} from '@amalia/reporting/dashboards-v2/types';

import { useChartColor } from '../../../common/hooks/charts-colors';
import { CHART_SIZE } from '../kpi-card-chart.constants';

type DoughnutChartProps = {
  readonly chartConfiguration: DashboardChartConfiguration<ChartType.KPI_CARD_CHART>;
  readonly chartData?: DashboardChartResult;
  readonly column?: CustomReportColumn;
};

export const DoughnutChart = memo(function DoughnutChart({
  chartData,
  chartConfiguration,
  column,
}: DoughnutChartProps) {
  const chartColors = useChartColor();
  const theme = useTheme();

  const chartValues = useMemo(() => {
    if (!chartData || !column) {
      return [{ value: 0 }, { value: 0 }];
    }

    const accomplished = ((chartData.items[0]?.[column.identifier] as number | null) ?? 0) * 100;
    return [{ value: Math.min(accomplished, 100) }, { value: Math.max(100 - accomplished, 0) }];
  }, [chartData, column]);

  return (
    <PieChart
      height={CHART_SIZE}
      width={CHART_SIZE}
    >
      <Pie
        blendStroke
        data={chartValues}
        dataKey="value"
        endAngle={-90}
        innerRadius="75%"
        outerRadius="100%"
        startAngle={270}
      >
        <Cell
          key="accomplished"
          fill={chartColors[chartConfiguration.displaySettings.color]}
          style={{ outline: 'none' }}
        />
        <Cell
          key="remaining"
          fill={theme.ds.colors[ColorCategory.GRAY_SECONDARY][100]}
          style={{ outline: 'none' }}
        />
      </Pie>
    </PieChart>
  );
});
