import { createContext, useContext } from 'react';

import { type SelectOption } from '@amalia/design-system/components';
import { assert } from '@amalia/ext/typescript';

export interface SARouterContextInterface {
  selectedCompanies: string[];
  companies: SelectOption<string>[];
}

export const SARouterContext = createContext<SARouterContextInterface>({} as SARouterContextInterface);

export const useSARouterContext = () => {
  const value = useContext(SARouterContext);
  assert(value, 'useSARouterContext must be used within a SARouterContextProvider');
  return value;
};
