import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  addCompany,
  saCreateCalculation,
  saModifyCompanyProperty,
  saSetCalculationStatus,
  saSetImpersonateUser,
  saSetMasterSettings,
  saSetRefreshmentStatus,
} from '@amalia/superadmin/api-client';

import { SUPER_ADMIN_QUERY_KEYS, getSaCompanyKey } from './queries.key';

export const useSetMasterSettingsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: Parameters<typeof saSetMasterSettings>) => saSetMasterSettings(...params),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [SUPER_ADMIN_QUERY_KEYS.SA_GET_MASTER_SETTINGS],
      }),
  });
};

export const useAddCompanyMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addCompany,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [SUPER_ADMIN_QUERY_KEYS.SA_LIST_COMPANIES],
      }),
  });
};

export const useUpdateCompanyPropertyMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: Parameters<typeof saModifyCompanyProperty>) => saModifyCompanyProperty(...params),
    onSuccess: async (_, [companyId]) => {
      await queryClient.invalidateQueries({
        queryKey: [SUPER_ADMIN_QUERY_KEYS.SA_LIST_COMPANIES],
      });
      await queryClient.invalidateQueries({
        queryKey: [SUPER_ADMIN_QUERY_KEYS.SA_GET_COMPANY, getSaCompanyKey(companyId)],
      });
    },
  });
};

export const useImpersonateUserMutation = ({ redirectToHome = false }: { redirectToHome?: boolean } = {}) =>
  useMutation({
    mutationFn: (email: string) => saSetImpersonateUser(email),
    onSuccess: () => {
      if (redirectToHome) {
        window.location.replace('/');
        return;
      }
      window.location.reload();
    },
  });

export const useSetCalculationStatusMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: Parameters<typeof saSetCalculationStatus>) => saSetCalculationStatus(...params),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [SUPER_ADMIN_QUERY_KEYS.SA_ON_GOING_CALCULATIONS],
      }),
  });
};

export const useLaunchCalculationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: Parameters<typeof saCreateCalculation>) => saCreateCalculation(...params),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [SUPER_ADMIN_QUERY_KEYS.SA_ON_GOING_CALCULATIONS],
      });
      await queryClient.invalidateQueries({
        queryKey: [SUPER_ADMIN_QUERY_KEYS.SA_LAST_CALCULATIONS],
      });
    },
  });
};

export const useStopRefreshmentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: Parameters<typeof saSetRefreshmentStatus>) => saSetRefreshmentStatus(...params),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [SUPER_ADMIN_QUERY_KEYS.SA_LAST_REFRESHMENTS],
      });
      await queryClient.invalidateQueries({
        queryKey: [SUPER_ADMIN_QUERY_KEYS.SA_ON_GOING_REFRESHMENTS],
      });
    },
  });
};
