import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { isConflictError } from '@amalia/core/http/client';
import { useSnackbars } from '@amalia/design-system/components';
import { toError } from '@amalia/ext/typescript';
import { PlanSyncActions, editCurrentPlan, useThunkDispatch } from '@amalia/frontend/web-data-layers';
import { PlansApiClient } from '@amalia/payout-definition/api-client';
import { type PatchPlanVisibility, type Plan } from '@amalia/payout-definition/plans/types';

import { PLAN_QUERY_KEYS } from './queries.keys';

export const usePlansList = (
  filters?: Parameters<(typeof PlansApiClient)['list']>[0],
  options?: { enabled?: boolean },
) =>
  useQuery({
    queryKey: [PLAN_QUERY_KEYS.PLAN, 'list', filters],
    queryFn: () => PlansApiClient.list(filters),
    ...options,
  });

export const usePlanTemplate = (planId?: Plan['id']) =>
  useQuery({
    queryKey: [PLAN_QUERY_KEYS.PLAN, 'template', planId],
    queryFn: () => PlansApiClient.getPlanTemplate(planId!),
    enabled: !!planId,
  });

export const useCreatePlan = ({
  ignoreConflictError = true,
}: {
  ignoreConflictError?: boolean;
} = {}) => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: [PLAN_QUERY_KEYS.PLAN, 'create'],
    mutationFn: PlansApiClient.create,
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Plan created successfully.' }));
      await queryClient.invalidateQueries({ queryKey: [PLAN_QUERY_KEYS.PLAN, 'list'] });
    },
    onError: (err) => {
      if (!ignoreConflictError || !isConflictError(err)) {
        snackError(
          formatMessage(
            { defaultMessage: 'Plan could not be created: {errorMessage}.' },
            { errorMessage: toError(err).message },
          ),
        );
      }
    },
  });
};

export const useDuplicatePlan = ({
  ignoreConflictError = true,
}: {
  ignoreConflictError?: boolean;
} = {}) => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: [PLAN_QUERY_KEYS.PLAN, 'duplicate'],
    mutationFn: PlansApiClient.duplicatePlan,
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Plan has been duplicated successfully.' }));
      await queryClient.invalidateQueries({ queryKey: [PLAN_QUERY_KEYS.PLAN, 'list'] });
    },
    onError: (err) => {
      if (!ignoreConflictError || !isConflictError(err)) {
        snackError(
          formatMessage(
            { defaultMessage: 'Plan could not be duplicated: {errorMessage}.' },
            { errorMessage: toError(err).message },
          ),
        );
      }
    },
  });
};

export const useArchivePlan = () => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: [PLAN_QUERY_KEYS.PLAN, 'archive'],
    mutationFn: PlansApiClient.archive,
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Plan has been archived successfully.' }));
      await queryClient.invalidateQueries({ queryKey: [PLAN_QUERY_KEYS.PLAN, 'list'] });
    },
    onError: (err) => {
      snackError(
        formatMessage(
          { defaultMessage: 'Plan could not be archived: {errorMessage}.' },
          { errorMessage: toError(err).message },
        ),
      );
    },
  });
};

export const useUnarchivePlan = () => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: [PLAN_QUERY_KEYS.PLAN, 'archive'],
    mutationFn: PlansApiClient.unarchive,
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Plan has been unarchived successfully.' }));
      await queryClient.invalidateQueries({ queryKey: [PLAN_QUERY_KEYS.PLAN, 'list'] });
    },
    onError: (err) => {
      snackError(
        formatMessage(
          { defaultMessage: 'Plan could not be unarchived: {errorMessage}.' },
          { errorMessage: toError(err).message },
        ),
      );
    },
  });
};

export const useDeletePlan = () => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: [PLAN_QUERY_KEYS.PLAN, 'delete'],
    mutationFn: PlansApiClient.delete,
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Plan has been deleted successfully.' }));
      await queryClient.invalidateQueries({ queryKey: [PLAN_QUERY_KEYS.PLAN, 'list'] });
    },
    onError: (err) => {
      snackError(
        formatMessage(
          { defaultMessage: 'Plan could not be deleted: {errorMessage}.' },
          { errorMessage: toError(err).message },
        ),
      );
    },
  });
};

export const usePatchPlanVisibility = (planId: string) => {
  const queryClient = useQueryClient();
  const dispatch = useThunkDispatch();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: [PLAN_QUERY_KEYS.PLAN, 'patch-visibility', planId],
    mutationFn: (patchPlanVisibility: PatchPlanVisibility) =>
      PlansApiClient.patchPlanVisibility(planId, patchPlanVisibility),
    onSuccess: async (plan: Plan) => {
      // TODO: to remove when the designer is out of redux
      await dispatch(editCurrentPlan(PlanSyncActions.editVisibility(planId, plan.isHidden)));

      snackSuccess(
        plan.isHidden
          ? formatMessage({ defaultMessage: 'Plan has been successfully hidden.' })
          : formatMessage({ defaultMessage: 'Plan has been successfully published.' }),
      );

      await queryClient.invalidateQueries({ queryKey: [PLAN_QUERY_KEYS.PLAN, planId] });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};
