import { css } from '@emotion/react';
import { IconSearch, IconTrash } from '@tabler/icons-react';
import { capitalize } from 'lodash';
import { Fragment, memo, type MouseEventHandler, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { VENDOR_LOGOS } from '@amalia/data-capture/connectors/assets';
import {
  type CustomObjectDefinitionType,
  CustomObjectDefToDataConnectorType,
} from '@amalia/data-capture/record-models/types';
import { type FilterOverwriteRemoveCreationRequestDetails } from '@amalia/data-correction/overwrites/components';
import { IconButton, IconButtonVariant } from '@amalia/design-system/components';
import { openInNewTab } from '@amalia/ext/web';

interface RowsTableCellActionsProps {
  readonly openDealTracing: () => void;
  readonly url?: string;
  readonly connector?: CustomObjectDefinitionType;
  readonly openFilterOverwriteRemoveModal?: (details: FilterOverwriteRemoveCreationRequestDetails) => void;
  readonly clearOverwrite: MouseEventHandler<HTMLButtonElement>;
  readonly details: FilterOverwriteRemoveCreationRequestDetails;
}

export const RowsTableCellActions = memo(function RowsTableCellLeftActions({
  openDealTracing,
  url,
  connector,
  openFilterOverwriteRemoveModal,
  clearOverwrite,
  details,
}: RowsTableCellActionsProps) {
  const { formatMessage } = useIntl();

  const openDealTracingProxy: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e?) => {
      e?.stopPropagation();
      openDealTracing();
    },
    [openDealTracing],
  );

  const goToDeal: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e?) => {
      e?.stopPropagation();

      if (url) {
        openInNewTab(url);
      }
    },
    [url],
  );

  const Logo = connector && VENDOR_LOGOS[CustomObjectDefToDataConnectorType[connector]]?.CompactLogo;

  const openFilterOverwriteRemoveProxy: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e?) => {
      e?.stopPropagation();
      if (openFilterOverwriteRemoveModal) {
        openFilterOverwriteRemoveModal(details);
      }
    },
    [openFilterOverwriteRemoveModal, details],
  );

  return (
    <div
      css={(theme) => css`
        display: flex;
        background-color: ${theme.ds.colors.gray[0]};
        gap: 6px;
      `}
    >
      {details.isAdditionalRow ? (
        <IconButton
          withBackground
          icon={<IconTrash />}
          label={formatMessage({ defaultMessage: 'Remove deal added from simulation' })}
          onClick={clearOverwrite}
        />
      ) : (
        <Fragment>
          {url && connector && Logo ? (
            <IconButton
              withBackground
              icon={
                <div
                  css={css`
                    --icon-size: 20px;

                    width: var(--icon-size);
                    height: var(--icon-size);
                  `}
                >
                  <Logo />
                </div>
              }
              label={formatMessage(
                { defaultMessage: 'View deal in {connector}' },
                { connector: capitalize(connector) },
              )}
              onClick={goToDeal}
            />
          ) : null}
          <IconButton
            withBackground
            icon={<IconSearch />}
            label={formatMessage({ defaultMessage: 'Open tracing' })}
            onClick={openDealTracingProxy}
          />
          {!!openFilterOverwriteRemoveModal && (
            <IconButton
              withBackground
              icon={<IconTrash />}
              label={formatMessage({ defaultMessage: 'Remove this deal via an overwrite' })}
              variant={IconButtonVariant.DANGER}
              onClick={openFilterOverwriteRemoveProxy}
            />
          )}
        </Fragment>
      )}
    </div>
  );
});
