import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';

export default new AmaliaFunctionDefault<[string | null | undefined], number>({
  name: AmaliaFunctionKeys.toNumber,
  category: AmaliaFunctionCategory.STRING,

  nbParamsRequired: 0,
  description: 'Convert a string to a number',

  exec: (stringToConvert) => {
    const number = Number(stringToConvert);
    return Number.isNaN(number) ? 0 : number;
  },

  params: [{ name: 'string', description: 'String: variables, fields, properties, string', defaultValue: null }],

  examples: [
    {
      desc: 'Returns 5',
      formula: 'toNumber("5")' as AmaliaFormula,
      result: 5,
    },
    {
      desc: 'Returns 3.32',
      formula: 'toNumber("3.32")' as AmaliaFormula,
      result: 3.32,
    },
    {
      desc: 'Returns -12',
      formula: 'toNumber("-12")' as AmaliaFormula,
      result: -12,
    },
    {
      desc: 'Returns 0 when string cannot be converted to number',
      formula: 'toNumber("hello")' as AmaliaFormula,
      result: 0,
    },
  ],
});
