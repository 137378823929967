import { omit } from 'lodash';
import { type SetRequired } from 'type-fest';

import { http } from '@amalia/core/http/client';
import { type PlanTemplateDehydrated } from '@amalia/payout-calculation/types';
import {
  type PatchPlanVisibility,
  type CreatePlanRequest,
  type HighlightedKpi,
  type HighlightedKpiIdentifier,
  type Plan,
  type CreateHighlightedKpiRequest,
  type PlanTemplateError,
  type DuplicatePlanRequest,
} from '@amalia/payout-definition/plans/types';

export class PlansApiClient {
  public static async list(filters?: {
    userIds?: string[];
    planIds?: string[];
    periodId?: string;
  }): Promise<SetRequired<Plan, 'planAssignements'>[]> {
    let qs = '';
    if (filters) {
      const { userIds, planIds, periodId } = filters;

      qs = [
        ...(userIds ? userIds.map((u) => `userIds=${u}`) : []),
        ...(planIds ? planIds.map((u) => `planIds=${u}`) : []),
        ...(periodId ? [`periodId=${periodId}`] : [null]),
      ]
        .filter(Boolean)
        .join('&');
    }
    const { data } = await http.get<SetRequired<Plan, 'planAssignements'>[]>(`/plans?${qs}`);
    return data;
  }

  public static async get(planId: string): Promise<Plan> {
    const relations =
      'planRules,planRules.rule,planAssignements,highlightedKpis,highlightedKpis.variable,badgeConfigurations';
    const { data } = await http.get<Plan>(`/plans/${planId}?relations=${relations}`);
    return data;
  }

  public static async getPlanTemplate(
    planId: string,
  ): Promise<{ template?: PlanTemplateDehydrated; error?: PlanTemplateError }> {
    const { data } = await http.get<{ template?: PlanTemplateDehydrated; error?: PlanTemplateError }>(
      `/plans/${planId}/template`,
    );

    return data;
  }

  public static async searchKeyword(keyword: string): Promise<Plan[]> {
    const { data } = await http.get<Plan[]>(`/plans/search/${encodeURI(keyword)}`);
    return data;
  }

  public static async create(plan: CreatePlanRequest): Promise<Plan> {
    const { data } = await http.post<Plan>('/plans/', plan);
    return data;
  }

  public static async update(plan: Plan): Promise<Plan> {
    const { data } = await http.put<Plan>(`/plans/${plan.id}`, plan);
    return data;
  }

  public static async patchPlanVisibility(planId: string, patch: PatchPlanVisibility): Promise<Plan> {
    const { data } = await http.patch<Plan>(`/plans/${planId}/visibility`, patch);
    return data;
  }

  public static async archive(planId: Plan['id']): Promise<Plan> {
    const { data } = await http.put<Plan>(`/plans/archive/${encodeURIComponent(planId)}`, { archived: true });
    return data;
  }

  public static async unarchive(planId: Plan['id']): Promise<Plan> {
    const { data } = await http.put<Plan>(`/plans/archive/${encodeURIComponent(planId)}`, { archived: false });
    return data;
  }

  public static async delete(planId: Plan['id']): Promise<void> {
    await http.delete(`/plans/${encodeURIComponent(planId)}`);
  }

  public static async upsertHighlightedKpi(
    planId: string,
    highlightedKpi: CreateHighlightedKpiRequest & { identifier: HighlightedKpiIdentifier },
  ): Promise<HighlightedKpi> {
    const { data } = await http.put<HighlightedKpi>(
      `/plans/${planId}/highlighted-kpis/${highlightedKpi.identifier}`,
      omit(highlightedKpi, 'identifier'),
    );

    return data;
  }

  public static async deleteHighlightedKpi(planId: string, identifier: HighlightedKpiIdentifier) {
    await http.delete(`/plans/${planId}/highlighted-kpis/${identifier}`);
  }

  public static async duplicatePlan({
    planId,
    ...duplicatePlanRequest
  }: DuplicatePlanRequest & { planId: Plan['id'] }): Promise<Plan> {
    const { data: newPlan } = await http.post<Plan>(
      `/plans/${encodeURIComponent(planId)}/duplicate`,
      duplicatePlanRequest,
    );
    return newPlan;
  }
}
