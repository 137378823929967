import { defineMessages } from 'react-intl';

import { TokenType } from '@amalia/amalia-lang/tokens/types';

import { type NewObjectSupportedTokenType } from './DesignerNewObjectModal.types';

export const designerNewObjectModalItemMessages = defineMessages<NewObjectSupportedTokenType>({
  [TokenType.FIELD]: {
    defaultMessage: 'Create new field',
  },

  [TokenType.FILTER]: {
    defaultMessage: 'Create new filter',
  },

  [TokenType.LINK]: {
    defaultMessage: 'Create new link',
  },

  [TokenType.QUOTA]: {
    defaultMessage: 'Create new quota',
  },

  [TokenType.VARIABLE]: {
    defaultMessage: 'Create new variable',
  },
} as const);
