import { css } from '@emotion/react';
import { IconPencil } from '@tabler/icons-react';
import { groupBy, partition } from 'lodash';
import { memo, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { ButtonLink, FormLayout, FormikInput, FormikSelect } from '@amalia/design-system/components';
import { Link } from '@amalia/ext/react-router-dom';
import { CustomReportSourceIdentifier, type CustomReport } from '@amalia/reporting/custom-reports/shared';

import { useDashboardChartConfigurationContext } from '../../contexts/DashboardChartConfiguration.context';
import { DashboardChartConfigurationNextStepButton } from '../../cta/DashboardChartConfigurationNextStepButton';
import { DropDownActionCreateReport } from '../DropDownActionCreateReport';

interface DashboardChartConfigurationBasicTabProps {
  readonly customReports: CustomReport[];
  readonly customReportIdSelected: string;
}

export const DashboardChartConfigurationBasicTab = memo(function DashboardChartConfigurationBasicTab({
  customReports,
  customReportIdSelected,
}: DashboardChartConfigurationBasicTabProps) {
  const { formatMessage } = useIntl();

  const { isFirstTabValid, onSwitchTab } = useDashboardChartConfigurationContext();

  const handleClickNextStepButton = useCallback(() => {
    onSwitchTab('content');
  }, [onSwitchTab]);

  const reportsOptions = useMemo(() => {
    const [reportsWithoutFolder, reportsWithFolder] = partition(
      customReports.filter(
        (c) =>
          [CustomReportSourceIdentifier.STATEMENT, CustomReportSourceIdentifier.RULE_METRIC].includes(c.source) &&
          !c.isPreset,
      ),
      (cu) => !cu.folderId,
    );

    const reportsGroupedByFolder = groupBy(reportsWithFolder, (cu) => cu.folder?.name);

    return [
      {
        label: formatMessage({ defaultMessage: 'Not in folder' }),
        options: reportsWithoutFolder.map((report) => ({ value: report.id, label: report.name })),
      },
      ...Object.entries(reportsGroupedByFolder).map(([folderName, reports]) => ({
        label: folderName,
        options: reports.map((report) => ({ value: report.id, label: report.name })),
      })),
    ];
  }, [customReports, formatMessage]);

  return (
    <FormLayout
      size={FormLayout.Size.SMALL}
      css={css`
        height: 100%;
      `}
    >
      <FormLayout.Group>
        <FormikInput
          required
          id="name"
          label={<FormattedMessage defaultMessage="Name" />}
          name="name"
        />

        <FormikSelect
          required
          action={<DropDownActionCreateReport />}
          id="customReportId"
          label={<FormattedMessage defaultMessage="Report" />}
          name="customReportId"
          options={reportsOptions}
        />
        {!!customReportIdSelected && (
          <ButtonLink
            icon={<IconPencil />}
            variant={ButtonLink.Variant.LIGHT}
            to={
              <Link
                openInNewTab
                to={generatePath(routes.CUSTOM_REPORT_ITEM, { reportId: customReportIdSelected })}
              />
            }
          >
            <FormattedMessage defaultMessage="Edit report" />
          </ButtonLink>
        )}
      </FormLayout.Group>

      <DashboardChartConfigurationNextStepButton
        isTabValid={isFirstTabValid}
        onClick={handleClickNextStepButton}
      />
    </FormLayout>
  );
});
