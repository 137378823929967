import { useFormikContext } from 'formik';
import { createContext, Fragment, memo, useContext, type ReactNode } from 'react';

import { useShallowObjectMemo } from '@amalia/ext/react/hooks';
import { assert } from '@amalia/ext/typescript';
import { type ChartType, type DashboardChartConfiguration } from '@amalia/reporting/dashboards-v2/types';

import { type DashboardChartConfigurationTab } from '../tabs/dashboard-chart-configuration-modal.types';

export interface DashboardChartConfigurationContextProps {
  readonly isFirstTabValid: boolean;
  readonly isSecondTabValid: boolean;
  readonly forMode: 'creation' | 'edition';
  readonly onSwitchTab: (tab: DashboardChartConfigurationTab) => void;
}

const DashboardChartConfigurationContext = createContext<DashboardChartConfigurationContextProps | null>(null);

export const useDashboardChartConfigurationContext = () => {
  const context = useContext(DashboardChartConfigurationContext);
  assert(context, 'useDashboardChartConfigurationContext must be used within a DashboardChartConfigurationProvider');
  return context;
};

export const DashboardChartConfigurationProvider = memo(function DashboardChartConfigurationProvider<
  TChartType extends ChartType,
>({
  children,
  forMode,
  onSwitchTab,
}: {
  readonly children: ReactNode;
  readonly forMode: 'creation' | 'edition';
  readonly onSwitchTab: (tab: DashboardChartConfigurationTab) => void;
}) {
  const { errors } = useFormikContext<DashboardChartConfiguration<TChartType>>();

  const isFirstTabValid = !errors.name && !errors.customReportId;
  const isSecondTabValid = !!isFirstTabValid && !errors.displaySettings;

  const renderProps = useShallowObjectMemo({
    isFirstTabValid,
    isSecondTabValid,
    forMode,
    onSwitchTab,
  });

  return (
    <DashboardChartConfigurationContext.Provider value={renderProps}>
      {children}
    </DashboardChartConfigurationContext.Provider>
  );
});

export const DashboardChartConfigurationConsumer = memo(function DashboardChartConfigurationConsumer({
  children,
}: {
  readonly children: (props: DashboardChartConfigurationContextProps) => ReactNode;
}) {
  const context = useDashboardChartConfigurationContext();
  return <Fragment>{children(context)}</Fragment>;
});
