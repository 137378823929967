import { groupBy } from 'lodash';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { formatUserFullName } from '@amalia/core/types';
import { UserPrettyFormat } from '@amalia/data-capture/fields/components';
import { useFindDashboardShareCandidates } from '@amalia/reporting/dashboards-v2/state';
import { type Dashboard, type DashboardShareCandidate } from '@amalia/reporting/dashboards-v2/types';
import { UserStatus } from '@amalia/tenants/users/types';

export const useDashboardShareCandidatesOptions = (dashboardId: Dashboard['id']) => {
  const { formatMessage } = useIntl();
  const { data: shareCandidates } = useFindDashboardShareCandidates(dashboardId);

  const shareCandidatesGroupByStatus = useMemo(() => groupBy(shareCandidates || [], 'status'), [shareCandidates]);

  return useMemo(
    () => [
      {
        label: formatMessage({ defaultMessage: 'Active' }),
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        options: (shareCandidatesGroupByStatus[UserStatus.ACTIVE] || []).map(formatShareCandidateOption),
      },
      {
        label: formatMessage({ defaultMessage: 'Invited' }),
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        options: (shareCandidatesGroupByStatus[UserStatus.INVITED] || []).map(formatShareCandidateOption),
      },
      {
        label: formatMessage({ defaultMessage: 'Inactive' }),
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        options: (shareCandidatesGroupByStatus[UserStatus.INACTIVE] || []).map(formatShareCandidateOption),
      },
    ],
    [formatMessage, shareCandidatesGroupByStatus],
  );
};

const formatShareCandidateOption = (candidate: DashboardShareCandidate) => ({
  label: (
    <UserPrettyFormat
      firstName={candidate.firstName}
      lastName={candidate.lastName}
      pictureURL={candidate.pictureURL}
    />
  ),
  filterLabel: formatUserFullName(candidate),
  value: candidate.id,
});
