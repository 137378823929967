import { UnsupportedChartTypeException } from '@amalia/reporting/dashboards-v2/shared';
import { ChartType } from '@amalia/reporting/dashboards-v2/types';

import { KpiCardChartDisplayConverter } from './kpi-card-chart-display-settings.converter';
import { SimpleBarChartDisplayConverter } from './simple-bar-chart-display-settings.converter';
import { SimpleLineChartDisplayConverter } from './simple-line-chart-display-settings.converter';

export class ChartDisplaySettingsConverterFactory {
  public static getConverterForChartType(chartType: ChartType) {
    switch (chartType) {
      case ChartType.KPI_CARD_CHART:
        return new KpiCardChartDisplayConverter();
      case ChartType.SIMPLE_BAR_CHART:
        return new SimpleBarChartDisplayConverter();
      case ChartType.SIMPLE_LINE_CHART:
        return new SimpleLineChartDisplayConverter();
      default:
        throw new UnsupportedChartTypeException(chartType);
    }
  }
}
