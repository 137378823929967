import { floor } from 'lodash';

import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';

export default new AmaliaFunctionDefault<[number, number], number>({
  name: AmaliaFunctionKeys.FLOOR,
  category: AmaliaFunctionCategory.NUMBERS,

  nbParamsRequired: 1,
  description: 'Round a number down',

  exec: (numberInput, nbDecimals) => floor(numberInput, nbDecimals),

  params: [
    {
      name: 'input',
      description: 'Input number to get its floor: numbers, fields, properties, variables',
    },
    {
      name: 'nbDecimals',
      description: 'The number of decimal places of the output. By default it is 0.',
      defaultValue: '0',
    },
  ],

  examples: [
    {
      desc: 'Returns 5',
      formula: 'FLOOR(5.3)' as AmaliaFormula,
      result: 5,
    },
    {
      desc: 'Returns 1.6',
      formula: 'FLOOR(1.68, 1)' as AmaliaFormula,
      result: 1.6,
    },
    {
      desc: 'Returns 1.57',
      formula: 'FLOOR(1.573, 2)' as AmaliaFormula,
      result: 1.57,
    },
    {
      desc: 'Returns the closet floor to Commission with 2 decimal places.',
      formula: 'FLOOR(statement.commission, 2)' as AmaliaFormula,
    },
    {
      desc: 'Returns 150',
      formula: 'FLOOR(154, -1)' as AmaliaFormula,
      result: 150,
    },
  ],
});
