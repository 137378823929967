import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { TokenType } from '@amalia/amalia-lang/tokens/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';

import { CalculationParser } from '../../../CalculationParser';
import { SanitizeFormula } from '../../../sanitizeFormula';
import { AmaliaFunctionRawArgs } from '../../AmaliaFunction';
import { getValueOrFormula } from '../../utils';

export default new AmaliaFunctionRawArgs<number>({
  name: AmaliaFunctionKeys.SUM,
  category: AmaliaFunctionCategory.MISC,
  nbParamsRequired: 2,
  description: 'Sum a parameter based on a filtered dataset',

  generateComputedFunctionResult: (args) => ({
    array: getValueOrFormula(args[0]),
    formula: SanitizeFormula.amaliaFormulaToMathJs(getValueOrFormula(args[1])) as AmaliaFormula,
  }),

  parametersToEscapeOnParse: [0, 1],

  execMock: (): number => 1,
  exec: (args, _, scope) => CalculationParser.getFunctionResult(args, scope, AmaliaFunctionKeys.SUM),

  params: [
    {
      name: 'dataset',
      description: 'Filters or Links',
      validTokenTypes: [TokenType.FILTER, TokenType.LINK],
      validFormats: [FormatsEnum.table],
    },
    {
      name: 'parameter',
      description: 'Variables, fields or properties to sum.',
      validTokenTypes: [
        TokenType.VARIABLE,
        TokenType.FIELD,
        TokenType.PROPERTY,
        TokenType.FUNCTION,
        TokenType.VIRTUAL_PROPERTY,
      ],
      validTokenValues: {
        [TokenType.FUNCTION]: [AmaliaFunctionKeys.IF, AmaliaFunctionKeys.DEFAULT],
      },
    },
  ],

  examples: [
    {
      desc: 'Returns the summed amount of filtered opportunities.',
      formula: 'SUM(filter.closedInQuarter, opportunity.amount)' as AmaliaFormula,
    },
    {
      desc: 'Returns the summed amount of filtered opportunity line items based on its link with the opportunity.',
      formula: 'SUM(opportunity.oppToLineItemsLink, opportunity.oppToLineItemsLink.amount)' as AmaliaFormula,
    },
    {
      desc: 'Returns the summed amount of filtered opportunities when the type is not renewal.',
      formula: 'SUM(filter.closedInQuarter, IF(opportunity.type="Renewal", 0, opportunity.amount)' as AmaliaFormula,
    },
  ],
});
