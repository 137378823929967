import { type ChartDisplaySettingsXAxisIdentifier } from '@amalia/reporting/dashboards-v2/types';

/**
 * Exception thrown when an unsupported chart X-Axis is encountered.
 *
 * It happens because we use type-guards everywhere and we need to
 * handle a new case at multiple places when a new X-Axis is added.
 */
export class UnsupportedChartXAxisException extends Error {
  public constructor(chartXAxisIdentifier: ChartDisplaySettingsXAxisIdentifier) {
    super(`Unsupported chart X-axis: ${chartXAxisIdentifier}`);
    Error.captureStackTrace(this, UnsupportedChartXAxisException);
  }
}
