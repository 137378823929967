import { useLocalStorageState } from 'ahooks';
import { useMemo } from 'react';

import { formatPeriodName, formatUserFullName } from '@amalia/core/types';
import { UserPrettyFormat } from '@amalia/data-capture/fields/components';
import { type FilterSelectOption } from '@amalia/design-system/components';
import { type UsePeriodOptionsReturnType, usePeriodOptions } from '@amalia/lib-ui-business';
import { usePeriods } from '@amalia/payout-definition/periods/components';
import { RelativePeriodKeyword, type Period } from '@amalia/payout-definition/periods/types';
import { useCustomReportsList } from '@amalia/reporting/custom-reports/state';
import { useGetChartFacet } from '@amalia/reporting/dashboards-v2/state';
import {
  type ChartType,
  type Dashboard,
  type DashboardChart,
  type DashboardChartConfiguration,
  type DashboardChartUserFilters,
} from '@amalia/reporting/dashboards-v2/types';
import { type TeamContract } from '@amalia/tenants/teams/types';
import { type UserProfile } from '@amalia/tenants/users/profile/types';

export interface ChartFilterOptionsAndUserPreferences {
  filters: DashboardChartUserFilters;
  options: {
    periods: UsePeriodOptionsReturnType<false>;
    teams: FilterSelectOption<string>[];
    users: FilterSelectOption<string>[];
  };
  setSelectedPeriods: (periodIds: Period['id'][]) => void;
  setSelectedTeams: (teamIds: TeamContract['id'][]) => void;
  setSelectedUsers: (userIds: UserProfile['id'][]) => void;
}

/**
 * Hook to handle the chart filters user preferences.
 *
 * Also help to avoid leaking data, let's say a user select specific user to filter the chart,
 * but then the admin decide to add more constraints on the custom report side, we must ensure that we filter out
 * the data selected previously by the user.
 *
 * For each filter, we will retrieve the facet, build the options thanks to the facet and filter out from
 * the user preferences the values that are not in the final options.
 *
 * Process:
 * - Retrieve all the data for a "facet" (like all the periods, all the teams, all the users)
 * - Retrieve the facet from the backend for the specific field
 * - Construct the options by filtering out the data that are not in the facet and also not in the chart settings (if any set)
 * - Sanitize the user preferences by filtering out the data that are not in the options
 */
export const useChartFiltersPreferences = (
  dashboardId: Dashboard['id'],
  chartId: DashboardChart<ChartType>['id'],
  chartConfiguration: DashboardChartConfiguration<ChartType>,
): ChartFilterOptionsAndUserPreferences => {
  // Maybe we could only select a single report but for now let's keep it as an array.
  // If different charts depends on different reports it'll save same calls to the API.
  const { data: customReportList } = useCustomReportsList();

  const selectedReport = useMemo(
    () => customReportList.find((report) => report.id === chartConfiguration.customReportId),
    [customReportList, chartConfiguration.customReportId],
  );

  const selectedFilterValuesOnReport = useMemo(() => {
    if (!selectedReport) {
      return {
        periods: [],
        teams: [],
        users: [],
      };
    }

    const { displaySettings } = chartConfiguration;

    return {
      periods: displaySettings.filters.periods.values,
      teams: displaySettings.filters.teams.values,
      users: displaySettings.filters.users.values,
    };
  }, [selectedReport, chartConfiguration]);

  const { periodsList } = usePeriods();

  const { data: periodsFacet } = useGetChartFacet(dashboardId, chartId, {
    identifier: 'periodId',
  });

  const periodOptions = usePeriodOptions({
    overridesPeriodOptions: useMemo(
      () =>
        periodsList
          .filter((period) => periodsFacet.find((facet) => facet.value === period.id))
          .filter((period) => {
            const selectedPeriodsInChartConfiguration = chartConfiguration.displaySettings.filters.periods.values;
            if (!selectedPeriodsInChartConfiguration.length) {
              return true;
            }

            return selectedPeriodsInChartConfiguration.includes(period.id);
          })
          .map((period) => ({
            label: formatPeriodName(period.frequency, period.startDate),
            value: period.id,
          }))
          .toReversed(),
      [periodsList, periodsFacet, chartConfiguration.displaySettings.filters.periods.values],
    ),
    periodList: periodsList,
    withNoneOption: false,
    relativePeriodToOmit: [RelativePeriodKeyword.LAST_12_MONTHS, RelativePeriodKeyword.YEAR_TO_DATE],
  });

  const [periodsUserPreferences, setPeriodsUserPreferences] = useLocalStorageState<string[]>(
    `dashboard-chart-${chartId}-periods`,
  );

  const periodsUserPreferencesSanitized = useMemo(
    () =>
      (periodsUserPreferences ?? selectedFilterValuesOnReport.periods).filter((periodId) =>
        periodOptions.find((period) => period.options.find((option) => option.value === periodId)),
      ),
    [periodOptions, periodsUserPreferences, selectedFilterValuesOnReport.periods],
  );

  const { data: teamsFacet } = useGetChartFacet(dashboardId, chartId, {
    identifier: 'teamId',
  });

  const teamOptions = useMemo(
    () =>
      teamsFacet.filter((teamFacet) => {
        const selectedTeamsInChartConfiguration = chartConfiguration.displaySettings.filters.teams.values;
        if (!selectedTeamsInChartConfiguration.length) {
          return true;
        }

        return selectedTeamsInChartConfiguration.includes(teamFacet.value);
      }),
    [teamsFacet, chartConfiguration.displaySettings.filters.teams.values],
  );

  const [teamsUserPreferences, setTeamsUserPreferences] = useLocalStorageState<string[]>(
    `dashboard-chart-${chartId}-teams`,
  );

  const teamsUserPreferencesSanitized = useMemo(
    () =>
      (teamsUserPreferences ?? selectedFilterValuesOnReport.teams).filter((teamId) =>
        teamOptions.find((team) => team.value === teamId),
      ),
    [teamOptions, teamsUserPreferences, selectedFilterValuesOnReport.teams],
  );

  const { data: usersFacet } = useGetChartFacet<ChartType, 'user'>(dashboardId, chartId, {
    identifier: 'userId',
  });

  const userOptions = useMemo(
    () =>
      usersFacet
        .filter((userFacet) => {
          const selectedUsersInChartConfiguration = chartConfiguration.displaySettings.filters.users.values;
          if (!selectedUsersInChartConfiguration.length) {
            return true;
          }

          return selectedUsersInChartConfiguration.includes(userFacet.value);
        })
        .map((userFacet) => ({
          label: (
            <UserPrettyFormat
              firstName={userFacet.userData.firstName}
              lastName={userFacet.userData.lastName}
              pictureURL={userFacet.userData.pictureURL}
            />
          ),
          filterLabel: formatUserFullName({
            firstName: userFacet.userData.firstName,
            lastName: userFacet.userData.lastName,
          }),
          valueLabel: formatUserFullName({
            firstName: userFacet.userData.firstName,
            lastName: userFacet.userData.lastName,
          }),
          value: userFacet.value,
        })),
    [usersFacet, chartConfiguration.displaySettings.filters.users.values],
  );

  const [usersUserPreferences, setUsersUserPreferences] = useLocalStorageState<string[]>(
    `dashboard-chart-${chartId}-users`,
  );

  const usersUserPreferencesSanitized = useMemo(
    () =>
      (usersUserPreferences ?? selectedFilterValuesOnReport.users).filter((userId) =>
        userOptions.find((user) => user.value === userId),
      ),
    [userOptions, usersUserPreferences, selectedFilterValuesOnReport.users],
  );

  return useMemo(
    () => ({
      filters: {
        periods: periodsUserPreferencesSanitized,
        teams: teamsUserPreferencesSanitized,
        users: usersUserPreferencesSanitized,
      },
      options: {
        periods: periodOptions,
        teams: teamOptions,
        users: userOptions,
      },
      setSelectedPeriods: setPeriodsUserPreferences,
      setSelectedTeams: setTeamsUserPreferences,
      setSelectedUsers: setUsersUserPreferences,
    }),
    [
      periodOptions,
      teamOptions,
      userOptions,
      periodsUserPreferencesSanitized,
      teamsUserPreferencesSanitized,
      usersUserPreferencesSanitized,
      setPeriodsUserPreferences,
      setTeamsUserPreferences,
      setUsersUserPreferences,
    ],
  );
};
