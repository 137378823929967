import { css } from '@emotion/react';
import { IconCircle } from '@tabler/icons-react';
import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { FormLayout, Group, IconButton, Typography } from '@amalia/design-system/components';
import { colorsLabels, useChartColor } from '@amalia/reporting/dashboards-v2/components';
import { type ChartColor } from '@amalia/reporting/dashboards-v2/types';

type ChartColorPickerProps = {
  readonly onChangeChartColor: (color: ChartColor) => Promise<void>;
  readonly chartColor: ChartColor;
};

export const ChartColorPicker = memo(function ChartColorPicker({
  chartColor,
  onChangeChartColor,
}: ChartColorPickerProps) {
  const chartColors = useChartColor();
  const { formatMessage } = useIntl();
  return (
    <FormLayout.Group>
      <Typography variant={Typography.Variant.BODY_BASE_MEDIUM}>
        <FormattedMessage defaultMessage="Color" />
      </Typography>
      <Group
        gap={4}
        css={css`
          flex-wrap: wrap;
        `}
      >
        {Object.entries(chartColors).map(([color, value]) => (
          <IconButton
            key={color}
            color={value}
            isActive={chartColor === color}
            label={formatMessage(colorsLabels[color as ChartColor])}
            icon={
              <IconCircle
                color={value}
                fill={value}
              />
            }
            onClick={() => onChangeChartColor(color as ChartColor)}
          />
        ))}
      </Group>
    </FormLayout.Group>
  );
});
