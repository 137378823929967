import { isEqual, pick, uniqWith } from 'lodash';

import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { type RecordContent } from '@amalia/data-capture/connectors/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { type ComputeEngineResult } from '@amalia/payout-calculation/types';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';

export default new AmaliaFunctionDefault<[RecordContent[], string[] | string], ComputeEngineResult>({
  name: AmaliaFunctionKeys.UNIQUE,
  category: AmaliaFunctionCategory.ARRAY,

  exec: (rows, fields) =>
    uniqWith(rows, (actVal, otherVal) => {
      const arrPick = pick(actVal, fields);
      const otherPick = pick(otherVal, fields);
      return isEqual(arrPick, otherPick);
    }),

  nbParamsRequired: 2,

  description:
    'Return the filtered dataset with unique values on a parameter. Used in advanced settings of filters or links and can be combined with SORT function.',

  params: [
    {
      name: 'array',
      description: '$rows for the dataset of the active filter or SORT($rows, ...) if you want the sorted dataset.',
      validFormats: [FormatsEnum.table],
    },
    {
      name: 'parameters',
      description:
        'Fields or properties on which we want to have only unique records. Format as follow: "machineName".',
    },
  ],

  examples: [
    {
      desc: 'Returns the dataset with unique records on the property “opportunity name”',
      formula: 'UNIQUE($rows, “accountId”)' as AmaliaFormula,
    },
    {
      desc: 'Returns the dataset with unique records on both properties “opportunity name” and “accountId”',
      formula: 'UNIQUE($rows, [“opportunityName”, “accountId”])' as AmaliaFormula,
    },
    {
      desc: 'Returns the dataset with unique records having the highest value for amount on the property “opportunity name”',
      formula: 'UNIQUE(SORT($rows, “amount”, “DESC”), “opportunityName”)' as AmaliaFormula,
    },
  ],
});
