import { css } from '@emotion/react';
import { IconChevronUp } from '@tabler/icons-react';
import { memo, useCallback, useState, type ReactNode } from 'react';

import { Collapse, Stack, UnstyledButton } from '@amalia/design-system/components';

export type CollapsibleBoxProps = {
  readonly children: ReactNode;
  readonly defaultOpenState?: boolean;
  readonly title: ReactNode;
};

export const CollapsibleBox = memo(function CollapsibleBox({
  children,
  defaultOpenState = true,
  title,
}: CollapsibleBoxProps) {
  const [isOpen, setIsOpen] = useState(defaultOpenState);

  const handleToggle = useCallback(() => {
    setIsOpen((openState) => !openState);
  }, []);

  return (
    <Stack
      gap={8}
      css={(theme) => css`
        transition: ${theme.ds.transitions.default('padding-bottom')};
        border-radius: ${theme.ds.borderRadiuses.squared};
        border: 1px solid ${theme.ds.colors.gray[100]};
        padding: 8px 12px;

        padding-bottom: ${isOpen ? '12px' : '8px'};
      `}
    >
      <UnstyledButton
        data-test-id="toggle collapse"
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          cursor: pointer;
        `}
        onClick={handleToggle}
      >
        {title}

        <IconChevronUp
          css={(theme) => css`
            flex: none;
            transition: ${theme.ds.transitions.default('transform')};
            transform: ${isOpen ? 'rotate(0deg)' : 'rotate(180deg)'};
          `}
        />
      </UnstyledButton>

      <Collapse
        lazy
        data-testid="collapsible-box-content"
        isOpen={isOpen}
      >
        {children}
      </Collapse>
    </Stack>
  );
});
