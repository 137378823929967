import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { DesignerTokenIcon } from '@amalia/amalia-lang/tokens/components';
import { MenuDropdown } from '@amalia/design-system/components';

import { type NewObjectSupportedTokenType } from './DesignerNewObjectModal.types';
import { designerNewObjectModalItemMessages } from './DesignerNewObjectModalItem.messages';

export type DesignerNewObjectModalItemProps = {
  readonly type: NewObjectSupportedTokenType;
  readonly onCreate: (type: NewObjectSupportedTokenType) => void;
};

export const DesignerNewObjectModalItem = memo(function DesignerNewObjectModalItem({
  type,
  onCreate,
}: DesignerNewObjectModalItemProps) {
  const handleClick = useCallback(() => onCreate(type), [onCreate, type]);

  return (
    <MenuDropdown.Item
      icon={<DesignerTokenIcon tokenType={type} />}
      label={<FormattedMessage {...designerNewObjectModalItemMessages[type]} />}
      onClick={handleClick}
    />
  );
});
