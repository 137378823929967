import styled from '@emotion/styled';
import { Fragment, memo, type ReactElement, type ReactNode } from 'react';

import { CircularLoader, Group, Modal, Stack, type ModalMainActionProps } from '@amalia/design-system/components';

import { ChartPreviewPlaceholder } from './preview-placeholder/ChartPreviewPlaceholder';

const DashboardChartConfigurationModalLayoutBody = styled(Group)`
  overflow-y: auto;
  width: 100%;
  flex: 1;
  border-top: 1px solid ${({ theme }) => theme.ds.colors.gray[100]};
`;

const DashboardChartConfigurationModalLayoutLeftPanel = styled(Stack)`
  gap: 32px;
  flex-shrink: 0;
  width: 320px;
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.ds.colors.gray[100]};
`;

const DashboardChartConfigurationModalLayoutLeftPanelMenu = styled.div`
  padding: 24px 32px 0 32px;
`;

const DashboardChartConfigurationModalLayoutLeftPanelContent = styled.div`
  padding: 0 32px;
  flex: 1;
  overflow-y: auto;
`;

const DashboardChartConfigurationModalLayoutRightPanel = styled(Stack)`
  width: 100%;
  margin: auto;
`;

const DashboardChartConfigurationModalLayoutPreview = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 24px 32px;
`;

export type DashboardChartConfigurationModalLayout = {
  readonly isLoading: boolean;
  readonly isFormValid: boolean;
  readonly chartPreview: ReactNode;
  readonly tabsMenu: ReactNode;
  readonly tabsContent: ReactNode;
  readonly submitButton: ReactElement<ModalMainActionProps>;
  readonly title: ReactNode;
};

export const DashboardChartConfigurationModalLayout = memo(function DashboardChartConfigurationModalLayout({
  chartPreview,
  isFormValid,
  isLoading,
  tabsMenu,
  tabsContent,
  submitButton,
  title,
}: DashboardChartConfigurationModalLayout) {
  return (
    <Fragment>
      <Modal.Content noBottomPadding>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <DashboardChartConfigurationModalLayoutBody>
          <DashboardChartConfigurationModalLayoutLeftPanel>
            <DashboardChartConfigurationModalLayoutLeftPanelMenu>
              {tabsMenu}
            </DashboardChartConfigurationModalLayoutLeftPanelMenu>
            <DashboardChartConfigurationModalLayoutLeftPanelContent>
              {tabsContent}
            </DashboardChartConfigurationModalLayoutLeftPanelContent>
          </DashboardChartConfigurationModalLayoutLeftPanel>

          <DashboardChartConfigurationModalLayoutRightPanel>
            <DashboardChartConfigurationModalLayoutPreview>
              {(() => {
                if (isLoading) {
                  return (
                    <Group justify="center">
                      <CircularLoader />
                    </Group>
                  );
                }

                if (isFormValid && chartPreview) {
                  return chartPreview;
                }

                return <ChartPreviewPlaceholder />;
              })()}
            </DashboardChartConfigurationModalLayoutPreview>
          </DashboardChartConfigurationModalLayoutRightPanel>
        </DashboardChartConfigurationModalLayoutBody>
      </Modal.Content>

      <Modal.Actions>
        <Modal.CancelAction />

        {submitButton}
      </Modal.Actions>
    </Fragment>
  );
});
