import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';

import { CalculationParser } from '../../../CalculationParser';
import { AmaliaFunctionRawArgs } from '../../AmaliaFunction';

export default new AmaliaFunctionRawArgs<number | null>({
  name: AmaliaFunctionKeys.convertCurrency,
  category: AmaliaFunctionCategory.CURRENCY,
  hiddenFromLibrary: true,

  execMock: () => 1,
  exec: (args, _, scope) => CalculationParser.getFunctionResult(args, scope, AmaliaFunctionKeys.convertCurrency),

  nbParamsRequired: 3,

  description: 'Return an amount converted from a source currency to a target currency`',

  params: [
    { name: 'amount', description: 'Amount to convert' },
    { name: 'sourceCurrency', description: 'Source currency: currency code, variables, fields, properties, keywords' },
    { name: 'targetCurrency', description: 'Target currency: currency code, variables, fields, properties, keywords' },
  ],

  examples: [
    {
      desc: 'Converts Payout from EUR to USD.',
      formula: 'convertCurrency(opportunity.payout, "EUR", "USD")' as AmaliaFormula,
    },
    {
      desc: "Converts opportunity's amount from opportunity currency to user's currency.",
      formula: 'convertCurrency(opportunity.amount, opportunity.currency, user.currency)' as AmaliaFormula,
    },
  ],

  generateComputedFunctionResult: (args) => ({
    formula: args[0].toString() as AmaliaFormula,
    fromCurrencyCode: args[1].toString() as AmaliaFormula,
    toCurrencyCode: args[2].toString() as AmaliaFormula,
  }),
});
