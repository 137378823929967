import { type ChartType } from '@amalia/reporting/dashboards-v2/types';

/**
 * Exception thrown when an unsupported chart type is encountered.
 *
 * It happens because we use type-guards everywhere and we need to
 * handle a new case at multiple places when a new chart type is added.
 */
export class UnsupportedChartTypeException extends Error {
  public constructor(chartType: ChartType) {
    super(`Unsupported chart type: ${chartType}`);
    Error.captureStackTrace(this, UnsupportedChartTypeException);
  }
}
