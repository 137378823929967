import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { useSnackbars } from '@amalia/design-system/components';
import { toError } from '@amalia/ext/typescript';
import {
  type Dashboard,
  type CreateDashboardRequest,
  type PatchDashboardRequest,
  type PostDashboardSharedUsersRequest,
} from '@amalia/reporting/dashboards-v2/types';
import { type UserComputed } from '@amalia/tenants/users/types';

import { DashboardsApiClient } from './api-client';
import { DASHBOARDS_QUERY_KEYS } from './queries.keys';

////////////////////////
// QUERIES
////////////////////////

export const useFindDashboard = (dashboardId: Dashboard['id']) =>
  useQuery({
    queryKey: [DASHBOARDS_QUERY_KEYS.DASHBOARDS, dashboardId],
    queryFn: () => DashboardsApiClient.findDashboard(dashboardId),
  });

export const useFindDashboards = ({ isQueryEnabled = true }: { isQueryEnabled?: boolean } = { isQueryEnabled: true }) =>
  useQuery({
    queryKey: [DASHBOARDS_QUERY_KEYS.DASHBOARDS],
    queryFn: DashboardsApiClient.findDashboards,
    enabled: isQueryEnabled,
  });

export const useFindDashboardShareCandidates = (dashboardId: Dashboard['id']) =>
  useQuery({
    queryKey: [
      DASHBOARDS_QUERY_KEYS.DASHBOARDS,
      DASHBOARDS_QUERY_KEYS.DASHBOARDS_PERMISSIONS,
      dashboardId,
      'share-candidates',
    ],
    queryFn: () => DashboardsApiClient.findDashboardShareCandidates(dashboardId),
    placeholderData: [],
  });

export const useGetDashboardUsersSharedWith = (dashboardId: Dashboard['id']) =>
  useQuery({
    queryKey: [
      DASHBOARDS_QUERY_KEYS.DASHBOARDS,
      DASHBOARDS_QUERY_KEYS.DASHBOARDS_PERMISSIONS,
      dashboardId,
      'shared-with',
    ],
    queryFn: () => DashboardsApiClient.getDashboardUsersSharedWith(dashboardId),
    placeholderData: [],
  });

export const useIsUserAllowedToViewDashboard = () =>
  useQuery({
    queryKey: [DASHBOARDS_QUERY_KEYS.DASHBOARDS, 'is-user-allowed-to-access'],
    queryFn: DashboardsApiClient.isUserAllowedToViewDashboard,
    placeholderData: false,
    staleTime: Infinity,
  });

export const useGetHomeDashboardId = () =>
  useQuery({
    queryKey: [DASHBOARDS_QUERY_KEYS.DASHBOARDS, 'home-dashboard'],
    queryFn: DashboardsApiClient.getDashboardAsHome,
    placeholderData: { dashboardId: null },
  });

//////////////////////////
// MUTATIONS
//////////////////////////

export const useCreateDashboard = () => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: [DASHBOARDS_QUERY_KEYS.DASHBOARDS, 'create'],
    mutationFn: (createDashboardRequest: CreateDashboardRequest) =>
      DashboardsApiClient.createDashboard(createDashboardRequest),
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Dashboard created' }));
      await queryClient.invalidateQueries({
        queryKey: [DASHBOARDS_QUERY_KEYS.DASHBOARDS],
      });
    },
    onError: (error) => {
      snackError(toError(error).message);
    },
  });
};

export const usePatchDashboard = (dashboardId: Dashboard['id'] | null) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: [DASHBOARDS_QUERY_KEYS.DASHBOARDS, dashboardId, 'patch'],
    mutationFn: (patchDashboardRequest: PatchDashboardRequest) =>
      DashboardsApiClient.patchDashboard(dashboardId!, patchDashboardRequest),
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Dashboard updated' }));
      await queryClient.invalidateQueries({
        queryKey: [DASHBOARDS_QUERY_KEYS.DASHBOARDS],
      });
    },
    onError: (error) => {
      snackError(toError(error).message);
    },
  });
};

export const useDeleteDashboard = (dashboardId: Dashboard['id'] | null) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: [DASHBOARDS_QUERY_KEYS.DASHBOARDS, dashboardId, 'delete'],
    mutationFn: () => DashboardsApiClient.deleteDashboard(dashboardId!),
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Dashboard deleted' }));
      await queryClient.invalidateQueries({
        queryKey: [DASHBOARDS_QUERY_KEYS.DASHBOARDS],
      });
    },
    onError: (error) => {
      snackError(toError(error).message);
    },
  });
};

export const useUpdateDashboardShareCandidates = (
  dashboardId: Dashboard['id'],
  resetSelectedShareCandidates: () => void,
) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: [DASHBOARDS_QUERY_KEYS.DASHBOARDS, dashboardId, 'patch-share-candidates'],
    mutationFn: (postDashboardRequest: PostDashboardSharedUsersRequest) =>
      DashboardsApiClient.updateDashboardShareCandidates(dashboardId, postDashboardRequest),
    onSuccess: async (sharedToCount) => {
      snackSuccess(
        formatMessage(
          {
            defaultMessage: 'Dashboard shared to {sharedToCount, plural, one {# manager} other {# managers}}',
          },
          { sharedToCount },
        ),
      );
      resetSelectedShareCandidates();
      await queryClient.invalidateQueries({
        queryKey: [DASHBOARDS_QUERY_KEYS.DASHBOARDS, DASHBOARDS_QUERY_KEYS.DASHBOARDS_PERMISSIONS],
      });
    },
    onError: (error) => {
      snackError(toError(error).message);
    },
  });
};

export const useDeleteDashboardUserSharedWith = (dashboardId: Dashboard['id']) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: [DASHBOARDS_QUERY_KEYS.DASHBOARDS, dashboardId, 'delete-user-shared-with'],
    mutationFn: (userToRemoveId: UserComputed['id']) =>
      DashboardsApiClient.removeDashboardUserSharedWith(dashboardId, userToRemoveId),
    onSuccess: async (userRemoved) => {
      snackSuccess(
        formatMessage(
          {
            defaultMessage: "{userFirstName} {userLastName}'s access has been removed.",
          },
          { userFirstName: userRemoved.firstName, userLastName: userRemoved.lastName },
        ),
      );
      await queryClient.invalidateQueries({
        queryKey: [DASHBOARDS_QUERY_KEYS.DASHBOARDS, DASHBOARDS_QUERY_KEYS.DASHBOARDS_PERMISSIONS],
      });
    },
    onError: (error) => {
      snackError(toError(error).message);
    },
  });
};

export const useSetDashboardAsHome = (dashboardId: Dashboard['id']) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: [DASHBOARDS_QUERY_KEYS.DASHBOARDS, dashboardId, 'set-as-home'],
    mutationFn: () => DashboardsApiClient.setDashboardAsHome(dashboardId),
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Dashboard set as home' }));
      await queryClient.invalidateQueries({
        queryKey: [DASHBOARDS_QUERY_KEYS.DASHBOARDS, 'home-dashboard'],
      });
    },
    onError: (error) => {
      snackError(toError(error).message);
    },
  });
};

export const useRemoveDashboardAsHome = () => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: [DASHBOARDS_QUERY_KEYS.DASHBOARDS, 'delete-as-home'],
    mutationFn: DashboardsApiClient.removeDashboardAsHome,
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Dashboard removed from home' }));
      await queryClient.invalidateQueries({
        queryKey: [DASHBOARDS_QUERY_KEYS.DASHBOARDS, 'home-dashboard'],
      });
    },
    onError: (error) => {
      snackError(toError(error).message);
    },
  });
};
