import { IconChevronDown, IconHelp } from '@tabler/icons-react';
import clsx from 'clsx';
import {
  type ReactNode,
  memo,
  useCallback,
  useState,
  type ComponentPropsWithoutRef,
  type ReactElement,
  type JSXElementConstructor,
  cloneElement,
} from 'react';

import { type MergeAll } from '@amalia/ext/typescript';

import { Collapse } from '../../../general/collapse/Collapse';
import { type CountBadgeProps } from '../../../general/count-badge/CountBadge';
import { CountBadgeSize } from '../../../general/count-badge/CountBadge.types';
import { type IconButtonProps } from '../../../general/icon-button/IconButton';
import { IconButtonSize } from '../../../general/icon-button/IconButton.types';
import { type TablerIconElement } from '../../../general/icons/types';
import { TextOverflow } from '../../../general/text-overflow/TextOverflow';
import { UnstyledButton } from '../../../general/unstyled-button/UnstyledButton';
import { Tooltip } from '../../tooltip/Tooltip';

import * as styles from './DropdownGroup.styles';

export type DropdownGroupProps = MergeAll<
  [
    ComponentPropsWithoutRef<'div'>,
    {
      /** Group label. */
      label: ReactNode;
      /** Optional count badge. */
      countBadge?: ReactElement<CountBadgeProps, JSXElementConstructor<CountBadgeProps>>;
      /** Optional tooltip message. */
      tooltip?: ReactNode;
      /** Optional icon on the left. */
      icon?: TablerIconElement;
      /** Optional action. */
      action?: ReactElement<IconButtonProps>;
      /** Options. */
      children?: ReactNode;
      /** Is the group open. Leave undefined for uncontrolled toggle. */
      isOpen?: boolean;
      /** Open change handler. Called with the new value. */
      onChangeIsOpen?: (isOpen: boolean) => void;
      /** Initial open state if isOpen is undefined. */
      initialIsOpen?: boolean;
      /** If not collapsible, the group is considered expanded. */
      isCollapsible?: boolean;
    },
  ]
>;

const DropdownGroupBase = memo(function DropdownGroup({
  label,
  countBadge = undefined,
  children = undefined,
  tooltip = undefined,
  icon = undefined,
  action = undefined,
  isOpen: controlledIsOpen = undefined,
  onChangeIsOpen: controlledOnChangeIsOpen = undefined,
  initialIsOpen = true,
  isCollapsible = true,
  ...props
}: DropdownGroupProps) {
  const [uncontrolledIsOpen, setUncontrolledIsOpen] = useState(initialIsOpen);

  const isOpen = controlledIsOpen ?? uncontrolledIsOpen;
  const onChangeIsOpen = controlledOnChangeIsOpen ?? setUncontrolledIsOpen;

  const isVisuallyOpen = !isCollapsible || isOpen;

  const handleToggleOpen = useCallback(() => onChangeIsOpen(!isOpen), [isOpen, onChangeIsOpen]);

  return (
    <styles.DropdownGroupContainer {...props}>
      <div css={styles.dropdownGroupTriggerContainer}>
        <UnstyledButton
          css={styles.dropdownGroupTrigger}
          data-is-open={isVisuallyOpen}
          data-testid="dropdown-group-trigger"
          disabled={!isCollapsible}
          onClick={handleToggleOpen}
        >
          <div css={styles.iconLabelContainer}>
            {!!icon &&
              cloneElement(icon, {
                width: 14,
                height: 14,
                color: 'currentColor',
              })}

            <div css={styles.labelTooltipContainer}>
              <TextOverflow
                css={(theme) => theme.ds.typographies.bodyXsmallMedium}
                tooltipPlacement="right"
              >
                {label}
              </TextOverflow>

              {!!tooltip && (
                <Tooltip content={tooltip}>
                  <IconHelp
                    css={styles.icon}
                    size={12}
                  />
                </Tooltip>
              )}
            </div>
          </div>

          {!!countBadge &&
            cloneElement(countBadge, {
              size: CountBadgeSize.SMALL,
            })}

          {!!(isCollapsible || action) && (
            <div css={styles.actions}>
              {/* Can't put a button inside a button so this is a placeholder and the action will be positioned absolutely. */}
              {!!action && <div css={styles.actionPlaceholder} />}

              {!!isCollapsible && (
                <IconChevronDown
                  className={clsx({ [styles.IS_OPEN_CLASSNAME]: isOpen })}
                  color="currentColor"
                  css={styles.arrow}
                  size={16}
                />
              )}
            </div>
          )}
        </UnstyledButton>

        {!!action && (
          <div
            className={clsx({ [styles.IS_COLLAPSIBLE_CLASSNAME]: isCollapsible })}
            css={styles.actionContainer}
          >
            {cloneElement(action, {
              size: IconButtonSize.SMALL,
            })}
          </div>
        )}
      </div>

      <Collapse
        lazy
        isOpen={isVisuallyOpen}
      >
        {children}
      </Collapse>
    </styles.DropdownGroupContainer>
  );
});

export const DropdownGroup = Object.assign(DropdownGroupBase, {
  Container: styles.DropdownGroupContainer,
});
