import moment from 'moment';
import { type ReactNode, useCallback, forwardRef, type ForwardedRef, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import { assert } from '@amalia/ext/typescript';

import { type DatePickerValueProps } from '../../../../data-input/date-picker-base/DatePickerBase.types';
import { Tag, type TagProps } from '../../../../general/tag/Tag';
import { useFiltersContext } from '../../Filters.context';
import { type FilterKey } from '../../Filters.types';
import { isEmptyFilterDatePickerValue } from '../helpers/filterDatePickerValue';

const getValueLabel = <TWithRange extends boolean | undefined = undefined>(
  selectsRange: TWithRange,
  value: DatePickerValueProps<TWithRange>['value'],
) => {
  if (selectsRange) {
    assert(Array.isArray(value), 'Range mode requires an array value');

    const [startDate, endDate] = value;

    switch (true) {
      case !!startDate && !!endDate:
        return (
          <FormattedMessage
            defaultMessage="{startDate} to {endDate}"
            values={{
              startDate: moment(startDate).format('YYYY-MM-DD'),
              endDate: moment(endDate).format('YYYY-MM-DD'),
            }}
          />
        );
      case !!startDate:
        return (
          <FormattedMessage
            defaultMessage="From {startDate}"
            values={{ startDate: moment(startDate).format('YYYY-MM-DD') }}
          />
        );
      case !!endDate:
        return (
          <FormattedMessage
            defaultMessage="To {endDate}"
            values={{ endDate: moment(endDate).format('YYYY-MM-DD') }}
          />
        );
      default:
        return null;
    }
  }

  assert(value instanceof Date, 'value must be a Date instance');
  return moment.utc(value).format('YYYY-MM-DD');
};

export type FilterDatePickerTagProps<TWithRange extends boolean | undefined = undefined> = Pick<
  TagProps<void>,
  'error'
> & {
  /** Filter key. Must be unique in the Filters children list. */
  readonly id: FilterKey;
  /** Filter label. */
  readonly label: ReactNode;
  /** Override label when value is empty. */
  readonly emptyValueLabel?: ReactNode;
  /** Is the dropdown disabled. */
  readonly disabled?: boolean;
  /** Can this filter be removed. */
  readonly isStatic?: boolean;
  /** Is the calendar open. */
  readonly isCalendarOpen?: boolean;
  /** Callback when clicking on the tag. */
  readonly onClick?: () => void;
  /** 'value' is overridden by react-datepicker but its formatting is not customizable so we just pass our own value prop. */
  readonly ownValue: DatePickerValueProps<TWithRange>['value'];
  /** Is range mode. */
  readonly selectsRange?: TWithRange;
};

const FilterDatePickerTagForwardRef = forwardRef(function FilterDatePickerTag<
  TWithRange extends boolean | undefined = undefined,
>(
  {
    id,
    label,
    emptyValueLabel,
    disabled,
    isStatic,
    isCalendarOpen,
    onClick,
    ownValue: value,
    selectsRange,
    error,
  }: FilterDatePickerTagProps<TWithRange>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { onHideFilter } = useFiltersContext();

  const handleDelete = useCallback(() => {
    // Clear values before hiding the filter, otherwise the filter is still active but no longer displayed.
    // onClear();
    onHideFilter(id);
  }, [id, /* onClear, */ onHideFilter]);

  return (
    <Tag
      ref={ref}
      disabled={disabled}
      error={error}
      id={id}
      isActive={isCalendarOpen}
      onClick={onClick}
      onDelete={isStatic ? undefined : handleDelete}
    >
      {isEmptyFilterDatePickerValue(value) ? (
        emptyValueLabel || label
      ) : (
        <FormattedMessage
          defaultMessage="{label}: {value}"
          values={{ label, value: getValueLabel(selectsRange, value) }}
        />
      )}
    </Tag>
  );
});

export const FilterDatePickerTag = FilterDatePickerTagForwardRef as <
  TWithRange extends boolean | undefined = undefined,
>(
  props: FilterDatePickerTagProps<TWithRange> & { ref?: ForwardedRef<HTMLDivElement> },
) => ReactElement | null;
