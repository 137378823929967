import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { REFRESHMENTS_ONGOING_STATUSES } from '@amalia/core/types';
import { DataImportsApiClient } from '@amalia/data-capture/imports/api-client';
import { assert } from '@amalia/ext/typescript';

import { DATA_IMPORTS_KEYS } from './queries.keys';

export const useLastConnectorImport = (connectorId?: string, objectDefinitionName?: string) =>
  useQuery({
    enabled: !!connectorId && !!objectDefinitionName,
    queryKey: [DATA_IMPORTS_KEYS.LAST_CONNECTOR_IMPORT, connectorId, objectDefinitionName],
    queryFn: async () => {
      assert(connectorId, 'Connector ID is required');
      assert(objectDefinitionName, 'Object definition name is required');

      return DataImportsApiClient.getLastConnectorImport(connectorId, {
        name: objectDefinitionName,
      });
    },
    refetchInterval: (query) =>
      query.state.data && REFRESHMENTS_ONGOING_STATUSES.includes(query.state.data.status) ? 5000 : false,
  });

/**
 * React query mutation hook to create a data refreshment.
 */
export const useCreateDataImport = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: DataImportsApiClient.create,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [DATA_IMPORTS_KEYS.LAST_CONNECTOR_IMPORT] });
    },
  });
};
