import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { type ComputeEngineResult } from '@amalia/payout-calculation/types';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';

export default new AmaliaFunctionDefault<[ComputeEngineResult], boolean>({
  name: AmaliaFunctionKeys.isNotNull,
  category: AmaliaFunctionCategory.MISC,

  exec: (evaluate) => !(evaluate === null || evaluate === undefined || evaluate === Infinity || evaluate === -Infinity),

  // Do not put 1 here because execution will crash if null,
  // which is going against the purpose of this function.
  nbParamsRequired: 0,
  description: 'Check if parameter exists',

  params: [
    { name: 'parameter', description: 'Variables, Fields, Properties, Links etc. to check.', defaultValue: null },
  ],

  examples: [
    {
      desc: 'Returns true if cancelled date exists.',
      formula: 'isNotNull(opportunity.cancelledDate)' as AmaliaFormula,
    },
    {
      desc: 'Returns true since the parameter (0) is a defined value.',
      formula: 'isNotNull(0)' as AmaliaFormula,
      result: true,
    },
    {
      desc: 'Returns true if the link to previous opportunity exists.',
      formula: 'isNotNull(opportunity.previousOpportunity)' as AmaliaFormula,
    },
    {
      desc: 'Returns false since the parameter is null.',
      formula: 'isNotNull(null)' as AmaliaFormula,
      result: false,
    },
    {
      desc: 'Returns false since the parameter is undefined.',
      formula: 'isNotNull(undefined)' as AmaliaFormula,
      result: false,
    },
    {
      desc: 'Returns true since the parameter is defined.',
      formula: "isNotNull('test')" as AmaliaFormula,
      result: true,
    },
  ],
});
