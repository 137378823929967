import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { type ComputeEngineResult } from '@amalia/payout-calculation/types';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';

export default new AmaliaFunctionDefault<[number, ComputeEngineResult], number>({
  name: AmaliaFunctionKeys.TIER,
  category: AmaliaFunctionCategory.INDICES,
  nbParamsRequired: 2,
  description: 'Compare a parameter to tiers in a table and return the corresponding value',

  exec: (tierToCompare, table) => {
    // The assert is good but it's not working because the scope.service return a shitty type, so we must disable it for now
    // assert(isComputedEngine2DTable<number>(table), 'In TIER, wrong table format');

    let toReturn = 0;
    // Sorry for the worst quickaround ever, but I don't have time to fix the type of table
    for (const tier of table as number[][]) {
      if (tier[1] > tierToCompare) {
        toReturn = tier[2];
        break;
      }
    }
    return toReturn;
  },

  params: [
    { name: 'parameter', description: 'Variables, fields, properties etc.' },
    { name: 'table', description: 'Table containing (min and max) and return values.' },
  ],

  examples: [
    {
      desc: 'Returns 0.4',
      formula: 'TIER(0.5548, [[0, 0.3, 0],[0.3, 0.5, 0.1],[0.5, 0.6, 0.4],[0.6, 0.7, 0.7]])' as AmaliaFormula,
      result: 0.4,
    },
    {
      desc: 'Returns the value from the 3rd column of the corresponding tier in the Payout Grid table based on target reach.',
      formula: 'TIER(statement.targetReach, statement.payoutGrid)' as AmaliaFormula,
    },
  ],
});
