import { useAuth0 } from '@auth0/auth0-react';
import { css } from '@emotion/react';
import { isEqual } from 'lodash';
import { memo, type ReactNode, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';

import { http, HttpError } from '@amalia/core/http/client';
import { hideLoadingScreen } from '@amalia/core/layout/loading-screen';
import { Typography } from '@amalia/design-system/components';
import { useQueryString } from '@amalia/ext/react-router-dom';
import { toError } from '@amalia/ext/typescript';
import { AuthContext, useFetchAuthenticatedContext } from '@amalia/kernel/auth/state';
import { getMockedAccessToken } from '@amalia/lib-ui';
import { useCompany } from '@amalia/tenants/companies/state';

import { AccountNotInvitedPage } from './account-not-invited-page/AccountNotInvitedPage';
import { AuthenticationErrorPage } from './authentication-error-page/AuthenticationErrorPage';
import { AuthenticationLogin } from './authentication-login/AuthenticationLogin';
import { MaintenancePage } from './maintenance-page/MaintenancePage';

interface AuthorizationProtectorProps {
  readonly children: ReactNode;
}

export const AuthorizationProtector = memo(function AuthorizationProtector({ children }: AuthorizationProtectorProps) {
  const { search } = useLocation();
  const { error: errorFromAuth0, error_description: errorFromAuth0Description } = useQueryString();
  const [error, setError] = useState<Error | null>(null);
  const { user: auth0User, isAuthenticated: isAuthenticatedWithAuth0, isLoading, getAccessTokenSilently } = useAuth0();

  const { authenticatedContext, error: userError, refetch } = useFetchAuthenticatedContext();

  // Make sure that the redux store is set with the current company before starting the app.
  const { data: company } = useCompany({ enabled: !!authenticatedContext?.user });

  const mockedAccessToken = useMemo(() => {
    const params = new URLSearchParams(search);
    const tokenFromUrl = params.get('token');
    return getMockedAccessToken(tokenFromUrl);
  }, [search]);

  useAsyncEffect(async () => {
    if (errorFromAuth0) {
      setError(new Error(`Error ${errorFromAuth0} from Authentication Provider: ${errorFromAuth0Description}`));
    } else if (userError && !isEqual(userError, error)) {
      setError(toError(userError));
    } else {
      try {
        // If we have a mocked access token, for instance in a test scenario, put
        // it directly in the Authorization header of all future requests. If not,
        // give the getAccessTokenSilently callback to an axios middleware that
        // will call this function on each request to get a token.
        if (mockedAccessToken) {
          http.setJwt(mockedAccessToken);
          await refetch();
        } else {
          http.setTokenInterceptor(getAccessTokenSilently);
        }
      } catch (thrownError) {
        setError(toError(thrownError));
      }
    }
    return () => {
      http.setJwt(null);
      http.clearTokenInterceptor();
    };
  }, [
    error,
    errorFromAuth0,
    errorFromAuth0Description,
    getAccessTokenSilently,
    isAuthenticatedWithAuth0,
    isLoading,
    mockedAccessToken,
    refetch,
    userError,
  ]);

  // We consider the page ready if it's not loading anymore, and if
  // either auth0 doesn't connect anyone (then we'll show the connection page)
  // or there is a user in the store.
  const userIsAnonymous = !auth0User && !mockedAccessToken;
  const isAuthReady = !isLoading && (!!(authenticatedContext && company) || userIsAnonymous || !!error);

  useEffect(() => {
    if (isAuthReady) {
      hideLoadingScreen();
    }
  }, [isAuthReady]);

  switch (true) {
    // The app is actually down but let's pretend it's a planned maintenance lol.
    case !!error && error.message === 'Network Error':
      return <MaintenancePage />;

    // Maintenance page.
    case !!error && error instanceof HttpError && [500, 503].includes(error.statusCode): {
      const { payload, message } = error as HttpError<{ startDate?: Date; endDate?: Date } | undefined>;
      const { startDate, endDate } = payload || {};
      return (
        <MaintenancePage
          endDate={endDate}
          message={message}
          startDate={startDate}
        />
      );
    }

    case !!error && error instanceof HttpError && error.statusCode === 403:
      return <AccountNotInvitedPage />;

    // We caught an error when no account linked.
    case !!error && !!auth0User:
      return (
        <AuthenticationErrorPage
          message={<FormattedMessage defaultMessage="No linked account exists." />}
          footer={
            <Typography variant={Typography.Variant.BODY_LARGE_REGULAR}>
              <FormattedMessage
                defaultMessage="Don't have an account? <Link>Contact us now</Link>."
                values={{
                  Link: (chunks) => (
                    <Typography
                      as="a"
                      href="mailto:hello@amalia.io"
                      variant={Typography.Variant.BODY_LARGE_MEDIUM}
                      css={(theme) => css`
                        text-decoration: none;
                        color: ${theme.ds.colors.primary[400]};
                      `}
                    >
                      {chunks}
                    </Typography>
                  ),
                }}
              />
            </Typography>
          }
        />
      );

    // We caught an error during connexion.
    case !!error:
      return (
        <AuthenticationErrorPage
          message={
            <div>
              <FormattedMessage defaultMessage="An error occurred while trying to log you in. Please contact your administrator." />
              <br />
              <br />
              {error.message}
            </div>
          }
        />
      );

    // Connexion took place but we didn't connect anyone.
    case isAuthReady && userIsAnonymous:
      return <AuthenticationLogin />;

    // Connexion took place, the user is now connected.
    case isAuthReady && !!authenticatedContext:
      return <AuthContext.Provider value={authenticatedContext}>{children}</AuthContext.Provider>;

    // Default case only happen when the user is not loading and not loaded,
    // which means it's the startup of the app (between the first react render
    // and the run of the useEffect that goes fetch `/users/me`). Do not return
    // anything because it's gonna be hidden behind the loading screen anyway.
    default:
      return null;
  }
});
