import { css } from '@emotion/react';
import { IconCircle } from '@tabler/icons-react';
import { useFormikContext } from 'formik';
import { memo, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { FORMATS_SYMBOLS_AND_LABELS, type FormatsEnum } from '@amalia/data-capture/fields/types';
import { ColumnHelper, FormFieldError, FormLayout, Table, Typography } from '@amalia/design-system/components';
import { useChartColor } from '@amalia/reporting/dashboards-v2/components';
import {
  defaultGaugeSegmentsValue,
  type ChartType,
  type DashboardChartConfiguration,
} from '@amalia/reporting/dashboards-v2/types';

type Row = {
  id: number;
  color: { value: string; label: string };
};

const columnHelper = new ColumnHelper<Row>();

type GaugeSegmentsValuesTableProps = {
  readonly kpiFormat: FormatsEnum;
};

export const GaugeSegmentsValuesTable = memo(function GaugeSegmentsValuesTable({
  kpiFormat,
}: GaugeSegmentsValuesTableProps) {
  const chartColor = useChartColor();
  const { values, setFieldValue, errors } = useFormikContext<DashboardChartConfiguration<ChartType.KPI_CARD_CHART>>();

  const data = useMemo(
    () => [
      {
        id: 0,
        color: { value: chartColor.RED, label: '' },
      },
      {
        id: 1,
        color: { value: chartColor.YELLOW, label: 'Yellow' },
      },
      {
        id: 2,
        color: { value: chartColor.GREEN, label: 'Green' },
      },
    ],
    [chartColor.GREEN, chartColor.RED, chartColor.YELLOW],
  );

  const handleSegmentsValueChange = useCallback(
    async (value: string, row: Row) => {
      const segmentsValue = values.displaySettings.segmentsValue || defaultGaugeSegmentsValue;
      const segmentsUpdated = segmentsValue.map((prevValue, index) => (index === row.id ? +value : prevValue));
      await setFieldValue('displaySettings.segmentsValue', segmentsUpdated);
    },
    [setFieldValue, values.displaySettings.segmentsValue],
  );

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'lower',
        header: '',
        size: 98,
        cell: ({ row }) => (
          <Table.Cell.TextField
            disabled
            // eslint-disable-next-line formatjs/no-literal-string-in-jsx
            placeholder={`${values.displaySettings.segmentsValue?.[row.id - 1] ?? 0}`}
            row={row}
            suffix={FORMATS_SYMBOLS_AND_LABELS[kpiFormat].symbol}
          />
        ),
      }),
      columnHelper.display({
        id: 'upper',
        header: '',
        size: 98,
        cell: ({ row }) => (
          <Table.Cell.TextField
            min={0}
            row={row}
            suffix={FORMATS_SYMBOLS_AND_LABELS[kpiFormat].symbol}
            type="number"
            value={`${values.displaySettings.segmentsValue?.[+row.id]}`}
            css={css`
              & input::-webkit-outer-spin-button,
              & input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
              & input[type='number'] {
                -moz-appearance: textfield;
                appearance: textfield;
              }
            `}
            onChange={handleSegmentsValueChange}
          />
        ),
      }),
      columnHelper.display({
        id: 'color',
        header: '',
        size: 0,
        cell: ({ row }) => (
          <Table.Cell>
            <IconCircle
              color={row.color.value}
              fill={row.color.value}
            />
          </Table.Cell>
        ),
      }),
    ],
    [handleSegmentsValueChange, kpiFormat, values.displaySettings.segmentsValue],
  );

  return (
    <FormLayout.Group>
      <Typography variant={Typography.Variant.BODY_BASE_MEDIUM}>
        <FormattedMessage defaultMessage="Segment ranges" />
      </Typography>
      <Table
        columns={columns}
        data={data}
        rowKey="id"
      />
      {!!errors.displaySettings?.segmentsValue && (
        <div
          css={css`
            margin-bottom: 12px;
          `}
        >
          <FormFieldError>{errors.displaySettings.segmentsValue}</FormFieldError>
        </div>
      )}
    </FormLayout.Group>
  );
});
