import { memo, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { Modal, ModalSize, ModalVariant } from '@amalia/design-system/components';
import { FormikForm } from '@amalia/ext/formik';
import {
  ChartType,
  type DashboardChart,
  type DashboardChartConfiguration,
} from '@amalia/reporting/dashboards-v2/types';

import { DashboardChartConfigurationModalForm } from './DashboardChartConfigurationModalForm';
import {
  formInitialValues,
  getKpiCardChartValidationSchema,
  simpleBarChartValidationSchema,
  simpleLineChartValidationSchema,
} from './validation-schemas';

interface DashboardChartConfigurationModalProps {
  readonly dashboardChart?: DashboardChart<ChartType> | null;
  readonly onClose: () => void;
  readonly onSubmit: (dashboardChart: DashboardChartConfiguration<ChartType>) => unknown;
}

export const DashboardChartConfigurationModal = memo(function DashboardChartConfigurationModal({
  onClose,
  onSubmit,
  dashboardChart,
}: DashboardChartConfigurationModalProps) {
  const { formatMessage } = useIntl();
  const initialValues = useMemo(() => dashboardChart ?? formInitialValues, [dashboardChart]);

  const ChartTypeValidationSchemaMapping = useMemo(
    () => ({
      [ChartType.KPI_CARD_CHART]: getKpiCardChartValidationSchema(formatMessage),
      [ChartType.SIMPLE_BAR_CHART]: simpleBarChartValidationSchema,
      [ChartType.SIMPLE_LINE_CHART]: simpleLineChartValidationSchema,
    }),
    [formatMessage],
  );

  const [validationSchema, setValidationSchema] = useState(ChartTypeValidationSchemaMapping[initialValues.type]);

  const handleChangeSchemaAfterChartTypeChange = useCallback(
    (newType: ChartType) => {
      setValidationSchema(ChartTypeValidationSchemaMapping[newType]);
    },
    [ChartTypeValidationSchemaMapping],
  );

  return (
    <Modal
      isOpen
      size={ModalSize.XLARGE}
      variant={ModalVariant.DEFAULT}
      onClose={onClose}
    >
      <FormikForm
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <DashboardChartConfigurationModalForm
          dashboardChart={dashboardChart}
          validationSchema={validationSchema}
          onChartTypeChange={handleChangeSchemaAfterChartTypeChange}
        />
      </FormikForm>
    </Modal>
  );
});
