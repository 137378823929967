import {
  KPIChartDisplayMode,
  isKpiCardChartConfiguration,
  isSimpleBarChartConfiguration,
  isSimpleLineChartConfiguration,
  type ChartType,
  type DashboardChartConfiguration,
} from '@amalia/reporting/dashboards-v2/types';

import { type DashboardChartSizing } from './dashboard-chart-sizing.types';

export const computeChartSizingBox = <TChartType extends ChartType>(
  dashboardChartConfiguration: DashboardChartConfiguration<TChartType>,
): DashboardChartSizing => {
  switch (true) {
    case isKpiCardChartConfiguration(dashboardChartConfiguration): {
      const isKpiChartEnabled = dashboardChartConfiguration.displaySettings.displayMode !== KPIChartDisplayMode.KPI;

      return {
        width: {
          min: isKpiChartEnabled ? 6 : 4,
          max: 12,
          default: 6,
        },
        height: {
          min: 1,
          max: 1,
          default: 1,
        },
      };
    }
    case isSimpleBarChartConfiguration(dashboardChartConfiguration):
    case isSimpleLineChartConfiguration(dashboardChartConfiguration):
      return {
        width: {
          min: 1,
          max: 12,
          default: 12,
        },
        height: {
          min: 2,
          max: 2,
          default: 2,
        },
      };
    default:
      throw new Error('Unsupported chart type');
  }
};
