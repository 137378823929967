import escapeStringRegexp from 'escape-string-regexp';
import { isNil, keyBy, uniqWith } from 'lodash';
import { type ReactNode } from 'react';

import { commonMathJs } from '@amalia/amalia-lang/amalia-mathjs';
import { type MathNode, SanitizeFormula } from '@amalia/amalia-lang/formula/evaluate/shared';
import { type FormulaKeywordsDisplayDetailsRecord } from '@amalia/amalia-lang/formula/keywords/components';
import { FormulaKeyword } from '@amalia/amalia-lang/formula/types';
import {
  AmaliaAccessorKeywords,
  type TokenType,
  type Variable,
  VariableObjectsEnum,
  type VariablesMap,
} from '@amalia/amalia-lang/tokens/types';
import { type CustomObjectDefinition, type CustomObjectDefinitionsMap } from '@amalia/data-capture/record-models/types';
import { isEnum } from '@amalia/ext/typescript';
import { type Relationship, type Filter, type FiltersMap } from '@amalia/payout-definition/plans/types';

import { type RelationshipsMap } from '../../types/relationships';

import { ConstantFormulaNode } from './types/ConstantFormulaNode';
import { FilterFormulaNode } from './types/FilterFormulaNode';
import { FunctionFormulaNode } from './types/FunctionFormulaNode';
import { type FormulaNodeObject, type IFormulaNode } from './types/IFormulaNode';
import { KeywordFormulaNode } from './types/KeywordFormulaNode';
import { OperatorFormulaNode } from './types/OperatorFormulaNode';
import { PropertyFormulaNode } from './types/PropertyFormulaNode';
import { RelationshipFormulaNode } from './types/RelationshipFormulaNode';
import { VariableFormulaNode } from './types/VariableFormulaNode';

// At the beginning we only wanted hashmaps, but because of the context we cannot
// index by machineName before keyBy or else we'll lost variables that have the same
// name in different contexts. So for objects that can be in a context (filters and
// variables for the moment), we use lists.
export type DesignerDataInput = {
  filtersList: Filter[];
  variablesList: Variable[];
  relationships: RelationshipsMap;
  customObjectDefinitions: CustomObjectDefinitionsMap;
};

export type DesignerDataMap = {
  filters: FiltersMap;
  variables: VariablesMap;
  relationships: RelationshipsMap;
  customObjectDefinitions: CustomObjectDefinitionsMap;
};

export type FormulaContext = {
  rowRelationshipMachineName?: string;
  planId?: string;
};

export class Formula2Service {
  private readonly designerData: DesignerDataMap;

  private readonly formulaContext?: FormulaContext;

  private readonly keywordsDisplayDetails: FormulaKeywordsDisplayDetailsRecord;

  public static traverseNode(result: MathNode[], nodes: MathNode): MathNode[] {
    const values = result;
    nodes.traverse((node) => {
      if (!commonMathJs.isArrayNode(node)) {
        values.push(node);
      }
    });

    return values;
  }

  public static parseNode(formula: string): MathNode {
    return commonMathJs.parse(formula);
  }

  public constructor(
    designerData: DesignerDataInput,
    keywordsDisplayDetails: FormulaKeywordsDisplayDetailsRecord,
    formulaContext?: FormulaContext,
  ) {
    this.designerData = this.filterDesignerDataWithContext(designerData, formulaContext);
    this.formulaContext = formulaContext;
    this.keywordsDisplayDetails = keywordsDisplayDetails;
  }

  public parseFormulaForTracing(originalFormula: string): IFormulaNode[] {
    const formula = this.replace$RowWithRelationship(originalFormula);

    // Parse the formula and get ALL accessor nodes
    const nodes = commonMathJs.parse(SanitizeFormula.sanitizeFormula(this.replace$RowWithRelationship(formula)));

    const allParsedNodes: MathNode[] = Formula2Service.traverseNode([], nodes);

    const formulaNodesToReturn: IFormulaNode[] = [];

    allParsedNodes.forEach((node) => {
      const nodeInString = node.toString();

      // MANAGER ALL NON-ACCESSOR NODES HERE
      if (commonMathJs.isConstantNode(node)) {
        // For constant nodes, get all positions for that constant that is by the end of the formula or not followed by a point nor a digit
        const nodePositions = this.getNodePositions(formula, node.value);

        formulaNodesToReturn.push(
          ...nodePositions.map(
            (position) => new ConstantFormulaNode(node.value.toString(), position, node.value.toString()),
          ),
        );
      }

      if (commonMathJs.isOperatorNode(node)) {
        const operator = node.op;

        let nodePositions: number[] = [];

        // If operator is only composed by letters, match it like a word
        if (/^[a-zA-Z]+$/gu.test(operator)) {
          nodePositions = this.getNodePositions(formula, operator);
        } else {
          // otherwise match it like an operator (without checking for following spaces and ...)
          nodePositions = [...formula.matchAll(new RegExp(escapeStringRegexp(operator), 'gui'))]
            .map((a) => a.index)
            .filter((n) => !isNil(n));
        }

        formulaNodesToReturn.push(
          ...nodePositions.map((position) => new OperatorFormulaNode(node.op, position, node.op)),
        );
      }

      // FUNCTION
      if (commonMathJs.isFunctionNode(node)) {
        const nodePositions = this.getNodePositions(formula, node.fn.name);

        formulaNodesToReturn.push(
          ...nodePositions.map((position) => new FunctionFormulaNode(node.fn.name, position, node.fn.name)),
        );
      }

      // KEYWORDS
      const keywordMatching = isEnum(nodeInString, FormulaKeyword);
      if (keywordMatching) {
        const nodePositions = this.getNodePositions(formula, nodeInString);

        const formulaKeyword = this.keywordsDisplayDetails[nodeInString];

        formulaNodesToReturn.push(
          ...nodePositions.map(
            (position) => new KeywordFormulaNode(nodeInString, position, formulaKeyword.name, formulaKeyword.color),
          ),
        );
      }

      // AT THIS POINT, WE MATCH ONLY ACCESSOR NODES
      if ('isAccessorNode' in node && node.isAccessorNode) {
        const nodeIdentifier = nodeInString.split('.');
        const [objectName, propertyName, ...propertyNameIfRelationship] = nodeIdentifier;
        const nodePositions = this.getNodePositions(formula, nodeInString);

        const definition = this.designerData.customObjectDefinitions[objectName] as CustomObjectDefinition | undefined;

        if (
          [
            // VARIABLE
            VariableObjectsEnum.statement,
            VariableObjectsEnum.user,
            VariableObjectsEnum.plan,
            VariableObjectsEnum.team,
          ].includes(objectName as VariableObjectsEnum)
        ) {
          const variable = this.designerData.variables[propertyName] as Variable | undefined;

          if (variable) {
            formulaNodesToReturn.push(
              ...nodePositions.map(
                (position) =>
                  new VariableFormulaNode(nodeInString, position, variable, objectName as VariableObjectsEnum),
              ),
            );
          }
        } else if (objectName === AmaliaAccessorKeywords.filter) {
          // FILTER
          const filter = this.designerData.filters[propertyName] as Filter | undefined;

          if (filter) {
            formulaNodesToReturn.push(
              ...nodePositions.map((position) => new FilterFormulaNode(nodeInString, position, filter)),
            );
          }
        } else if (definition) {
          // If we find a definition here, we have either: object variable, relationship, or native field

          // Try everything but relationship
          const variable = this.designerData.variables[propertyName] as Variable | undefined;
          const property = definition.properties[propertyName];

          if (variable && variable.objectId === definition.id) {
            formulaNodesToReturn.push(
              ...nodePositions.map(
                (position) =>
                  new VariableFormulaNode(nodeInString, position, variable, VariableObjectsEnum.object, definition),
              ),
            );
          } else if (property) {
            formulaNodesToReturn.push(
              ...nodePositions.map((position) => new PropertyFormulaNode(nodeInString, position, property, definition)),
            );
          } else {
            // Probably a relationship, check for length of properties.
            const allPropertiesName = [propertyName, ...propertyNameIfRelationship];

            if (allPropertiesName.length === 1) {
              // Maybe the formula ends with a relationship
              const childRelationship = this.designerData.relationships[allPropertiesName[0]] as
                | Relationship
                | undefined;
              if (childRelationship) {
                formulaNodesToReturn.push(
                  ...nodePositions.map(
                    (position) =>
                      new RelationshipFormulaNode(
                        nodeInString,
                        position,
                        childRelationship,
                        this.designerData.customObjectDefinitions[childRelationship.toDefinitionMachineName],
                        childRelationship,
                      ),
                  ),
                );
              }
            } else {
              // Get the object name
              const relationshipName = allPropertiesName[allPropertiesName.length - 2];
              const relationshipProperty = allPropertiesName[allPropertiesName.length - 1];

              const relationship = this.designerData.relationships[relationshipName] as Relationship | undefined;
              if (relationship) {
                const relationshipDefinition = this.designerData.customObjectDefinitions[
                  relationship.toDefinitionMachineName
                ] as CustomObjectDefinition | undefined;

                if (relationshipDefinition) {
                  const relationshipVariable = this.designerData.variables[relationshipProperty] as
                    | Variable
                    | undefined;
                  const relationshipField = relationshipDefinition.properties[relationshipProperty];
                  const childRelationship = this.designerData.relationships[relationshipProperty] as
                    | Relationship
                    | undefined;

                  if (relationshipVariable) {
                    formulaNodesToReturn.push(
                      ...nodePositions.map(
                        (position) =>
                          new VariableFormulaNode(
                            nodeInString,
                            position,
                            relationshipVariable,
                            VariableObjectsEnum.object,
                            relationshipDefinition,
                            relationship,
                          ),
                      ),
                    );
                  } else if (relationshipField) {
                    formulaNodesToReturn.push(
                      ...nodePositions.map(
                        (position) =>
                          new PropertyFormulaNode(
                            nodeInString,
                            position,
                            relationshipField,
                            relationshipDefinition,
                            relationship,
                          ),
                      ),
                    );
                  } else if (childRelationship) {
                    formulaNodesToReturn.push(
                      ...nodePositions.map(
                        (position) =>
                          new RelationshipFormulaNode(
                            nodeInString,
                            position,
                            childRelationship,
                            this.designerData.customObjectDefinitions[childRelationship.toDefinitionMachineName],
                            relationship,
                          ),
                      ),
                    );
                  }
                }
              }
            }
          }
        }
      }
    });

    const notEmptyFormulaNodes = formulaNodesToReturn.filter((f) => f.getIndices().length !== 0);
    return uniqWith(
      notEmptyFormulaNodes,
      (f1: IFormulaNode, f2: IFormulaNode) => f1.getIndices().start === f2.getIndices().start,
    );
  }

  public formulaNodesToHTML(
    originalFormula: string,
    formulaNodes: IFormulaNode[],
    classNames?: { chip?: string; clickable?: string },
    onClickDesignerElement?: (type: TokenType, id: string, definitionMachinename?: string) => void,
  ): ReactNode[] {
    const formula = this.replace$RowWithRelationship(originalFormula);

    let currentIndex = 0;
    const reactNodes: ReactNode[] = [];

    if (!formula) {
      return [];
    }

    const formulaNodesForIndex = keyBy(formulaNodes, (f: IFormulaNode) => f.getIndices().start);

    while (currentIndex < formula.length) {
      const currentFormulaNode = formulaNodesForIndex[currentIndex] as IFormulaNode<FormulaNodeObject> | undefined;
      if (currentFormulaNode) {
        reactNodes.push(currentFormulaNode.toHTML(classNames, onClickDesignerElement));
        currentIndex += currentFormulaNode.getIndices().length;
      } else {
        // Just copy the current character
        reactNodes.push(formula[currentIndex]);
        currentIndex++;
      }
    }

    return reactNodes;
  }

  /**
   * Filter data that can actually be displayed based on the current contexts then build hashmaps.
   *
   * @param designerData
   * @param formulaContext
   * @private
   */
  private filterDesignerDataWithContext(
    designerData: DesignerDataInput,
    formulaContext?: FormulaContext,
  ): DesignerDataMap {
    const { customObjectDefinitions, relationships, variablesList, filtersList } = designerData;

    const globalVariables = variablesList.filter((v) => !v.planId);
    const globalFilters = filtersList.filter((f) => !f.planId);

    // No context, we can only use global objects.
    if (!formulaContext?.planId) {
      return {
        customObjectDefinitions,
        relationships,
        filters: keyBy(globalFilters, 'machineName'),
        variables: keyBy(globalVariables, 'machineName'),
      };
    }

    // If the context has a planId, it means we can only apply global elements or elements from the same context.
    const localVariables = variablesList.filter((v) => v.planId === formulaContext.planId);
    const localFilters = filtersList.filter((f) => f.planId === formulaContext.planId);

    return {
      customObjectDefinitions,
      relationships,
      filters: keyBy(globalFilters.concat(localFilters), 'machineName'),
      variables: keyBy(globalVariables.concat(localVariables), 'machineName'),
    };
  }

  private replace$RowWithRelationship(formula: string) {
    if (this.formulaContext?.rowRelationshipMachineName) {
      return formula.replace(/\$row/gu, this.formulaContext.rowRelationshipMachineName);
    }
    return formula;
  }

  private buildRegexForMatchings(nodeInString: number | string) {
    const nodeToMatch = nodeInString.toString().replace(/[.]/gu, '\\.');

    return new RegExp(`(?<!(\\w|\\.))${nodeToMatch}(?!(\\w|\\.))`, 'gu');
  }

  private getNodePositions(formula: string, nodeInString: number | string): number[] {
    return [...formula.matchAll(this.buildRegexForMatchings(nodeInString))]
      .map((a) => a.index)
      .filter((n) => !isNil(n));
  }
}
