import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { type ComputeEngineResult } from '@amalia/payout-calculation/types';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';

export default new AmaliaFunctionDefault<
  [ComputeEngineResult, ComputeEngineResult, ComputeEngineResult],
  ComputeEngineResult
>({
  name: AmaliaFunctionKeys.GETINTABLE,
  category: AmaliaFunctionCategory.ARRAY,
  nbParamsRequired: 3,
  description: 'Given a table variable, returns the value of a cell.',

  exec: (table, rowIndex, columnIndex) => {
    if (!Array.isArray(table)) {
      throw new Error('In GetInTable, table is not an array. Are you sure this is a table variable?');
    }

    if (typeof rowIndex !== 'number' || typeof columnIndex !== 'number') {
      throw new Error('In GetInTable, Row or Column is not numeric');
    }

    const row = table[rowIndex];

    if (!row) {
      return null;
    }

    if (!Array.isArray(row)) {
      throw new Error('In GetInTable, Row is not an array. Does your table have two dimensions?');
    }

    const value = row[columnIndex] as ComputeEngineResult;

    // Undefined in the calculation engine has no meaning, we prefer `null`.
    return value ?? null;
  },

  execMock: () => 1,

  params: [
    { name: 'table', description: 'The table used to fetch the value', validFormats: [FormatsEnum.table] },
    { name: 'rowIndex', description: 'Number of the row starting at 0.' },
    { name: 'columnIndex', description: 'Number of the column starting at 0.' },
  ],

  examples: [
    {
      desc: 'Returns 200',
      formula: 'GETINTABLE([[100], [200], [300]], 1, 0)' as AmaliaFormula,
      result: 200,
    },
    {
      desc: 'Returns 2000',
      formula: 'GETINTABLE([[100, 1000], [200, 2000], [300, 3000]], 1, 1)' as AmaliaFormula,
      result: 2000,
    },
    {
      desc: 'Returns null because the rowIndex calls a value out of the table',
      formula: 'GETINTABLE([[100, 1000], [200, 2000], [300, 3000]], 5, 1)' as AmaliaFormula,
      result: null,
    },
    {
      desc: 'Returns the value of the first cell of the table statement.tierTable',
      formula: 'GETINTABLE(statement.tierTable, 0, 0)' as AmaliaFormula,
    },
  ],
});
