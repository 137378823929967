import { ceil } from 'lodash';

import { type AmaliaFormula, AmaliaFunctionCategory, AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';

import { AmaliaFunctionDefault } from '../../AmaliaFunction';

export default new AmaliaFunctionDefault<[number, number], number>({
  name: AmaliaFunctionKeys.CEIL,
  category: AmaliaFunctionCategory.NUMBERS,

  nbParamsRequired: 1,
  description: 'Round a number up',

  exec: (numberInput, nbDecimals) => ceil(numberInput, nbDecimals),

  params: [
    {
      name: 'input',
      description: 'Input number to get its ceil: numbers, fields, properties, variables',
    },
    {
      name: 'nbDecimals',
      description: 'The number of decimal places of the output. By default it is 0.',
      defaultValue: '0',
    },
  ],

  examples: [
    {
      desc: 'Returns 6',
      formula: 'CEIL(5.3)' as AmaliaFormula,
      result: 6,
    },
    {
      desc: 'Returns 1.7',
      formula: 'CEIL(1.68, 1)' as AmaliaFormula,
      result: 1.7,
    },
    {
      desc: 'Returns 1.58',
      formula: 'CEIL(1.573, 2)' as AmaliaFormula,
      result: 1.58,
    },
    {
      desc: 'Returns the closet ceil to Commission with 2 decimal places.',
      formula: 'CEIL(statement.commission, 2)' as AmaliaFormula,
    },
    {
      desc: 'Returns 160',
      formula: 'CEIL(154, -1)' as AmaliaFormula,
      result: 160,
    },
  ],
});
