import { type Variable } from '@amalia/amalia-lang/tokens/types';
import { type FormatsEnum } from '@amalia/data-capture/fields/types';
import { type PeriodFrequencyEnum } from '@amalia/payout-definition/periods/types';
import { type Company } from '@amalia/tenants/companies/types';
import { type UserContract } from '@amalia/tenants/users/types';

import { type BadgeConfiguration } from './badges';
import { type PlanAssignment } from './plan-assignments';
import { type PlanForecast } from './plans/forecasts';
import { type PlanRule, type RuleAssignment } from './plans/rules';
import { PlanRuleChartEnumType } from './plans/rules/charts';
import { type PlanRuleCategoryColor } from './plans/rules/colors';

export interface ComputedHighlightedKpi {
  format: FormatsEnum;

  minimumValue: number;
  maximumValue: number;
  currentValue: number | null;

  progress: number;

  minimumVariableLabel: string | null;
  maximumVariableLabel: string | null;
  variableLabel: string;
}

export enum HighlightedKpiIdentifier {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export interface HighlightedKpi {
  id: string;
  createdAt: Date;
  updatedAt: Date;

  identifier: HighlightedKpiIdentifier;

  company?: Company;

  plan?: Plan;
  planId: string;

  variable?: Variable;
  variableId: string;

  minimumVariable?: Variable | null;
  minimumVariableId: string | null;

  maximumVariable?: Variable | null;
  maximumVariableId: string | null;
}

export interface CreateHighlightedKpiRequest {
  variableId: string;
  minimumVariableId: string | null;
  maximumVariableId: string | null;
}

export interface IndexedPlanTemplate {
  dependencyIds: string[];
}

export interface PlanTemplateError {
  context: string;
  message: string;
}

export interface Plan {
  id: string;
  createdAt: Date;
  updatedAt: Date;

  name: string;
  frequency: PeriodFrequencyEnum;
  calculationBatchSize: number | null;
  calculationMaxParallel: number | null;
  maxRecursionLevel: number | null;
  linkToPlanDocumentation: string | null;
  indexedWeight: number;
  manualWeight: number | null;

  archived: boolean;
  isHidden: boolean;
  isForecasted: boolean;
  calculateBothStatements: boolean;
  isSimulationEnabled: boolean;
  isSimulationAddRecordEnabled: boolean;

  // You could expect to see this type here.
  // planTemplate: PlanTemplateDehydrated;
  // However we're not writing it for two reasons:
  //  - it creates a circular import in Nx
  //  - most of the time it's not selected and not
  //    available, if you need it in the frontend
  //    you have to call /plans/:id/template

  indexedPlanTemplate?: IndexedPlanTemplate | null;
  planTemplateError: PlanTemplateError | null;

  company?: Company;
  user?: UserContract;
  userId: string | null;
  forecast?: PlanForecast;
  forecastId: string | null;
  workflowId: string | null;

  // FIXME: we should remove this one and use a better DTO instead
  planAssignements?: PlanAssignment[];

  highlightedKpis?: HighlightedKpi[];
  badgeConfigurations?: BadgeConfiguration[];
  rules?: PlanRule[];
  planRules?: RuleAssignment[];
  categoriesV2: PlanCategory[] | null;
  /* True if the plan uses a variable result instead of total */
  useVariableInsteadOfTotal: boolean | null;
  /* Variable to use if useVariableInsteadOfTotal is true */
  totalVariableId: string | null;

  adjustments_index?: number;
}

export type PlanResponseDto = Pick<
  Plan,
  | 'archived'
  | 'badgeConfigurations'
  | 'calculateBothStatements'
  | 'categoriesV2'
  | 'forecastId'
  | 'frequency'
  | 'id'
  | 'indexedWeight'
  | 'isForecasted'
  | 'isSimulationAddRecordEnabled'
  | 'isSimulationEnabled'
  | 'linkToPlanDocumentation'
  | 'manualWeight'
  | 'name'
  | 'planTemplateError'
  | 'totalVariableId'
  | 'useVariableInsteadOfTotal'
  | 'workflowId'
> & {
  rules: PlanRule[];
  planAssignements: Pick<PlanAssignment, 'effectiveAsOf' | 'effectiveUntil' | 'id' | 'planId' | 'userId'>[];
  highlightedKpis: Pick<HighlightedKpi, 'identifier' | 'maximumVariableId' | 'minimumVariableId' | 'variableId'>[];
};

export interface CreatePlanRequest {
  name: Plan['name'];
  frequency?: PeriodFrequencyEnum;
}

export type DuplicatePlanRequest = {
  newPlanName: Plan['name'];
};

export type PatchPlanArchivedRequest = {
  archived: Plan['archived'];
};

export type PatchPlanVisibility = {
  visibility: PlanVisibility;
};

export interface PlanCategory {
  name: string;
  color?: PlanRuleCategoryColor; // It's required in the form but it was originally derived from the name.
}

// Indexed by planId.
export type PlansMap = Record<string, Plan>;

type PlanRuleChartDetail = { name: string };

export const PlanRuleChartDetails: Record<PlanRuleChartEnumType, PlanRuleChartDetail> = {
  [PlanRuleChartEnumType.TARGET_ACHIEVEMENT]: {
    name: 'Target Achievement',
  },
  [PlanRuleChartEnumType.STATEMENT_METRIC]: {
    name: 'Metric',
  },
};

export enum PlanVisibility {
  LIVE = 'LIVE',
  HIDDEN = 'HIDDEN',
}

export enum PlanIsHiddenQueryChoices {
  LIVE = 'LIVE',
  HIDDEN = 'HIDDEN',
  BOTH = 'BOTH',
}
