import { isNaN } from 'lodash';
import { type IntlShape } from 'react-intl';
import * as Yup from 'yup';

import { CustomReportAggregationOperation } from '@amalia/reporting/custom-reports/shared';
import {
  ChartColor,
  ChartDisplaySettingsXAxis,
  ChartType,
  KPIChartDisplayMode,
  type DashboardChartConfiguration,
} from '@amalia/reporting/dashboards-v2/types';

const filtersValidations = Yup.object().shape({
  periods: Yup.object().shape({
    isEnabled: Yup.boolean().required(),
    values: Yup.array().of(Yup.string()).required(),
  }),
  teams: Yup.object().shape({
    isEnabled: Yup.boolean().required(),
    values: Yup.array().of(Yup.string()).required(),
  }),
  users: Yup.object().shape({
    isEnabled: Yup.boolean().required(),
    values: Yup.array().of(Yup.string()).required(),
  }),
});

const commonValidations = Yup.object().shape({
  customReportId: Yup.string().required(),
  displaySettings: Yup.object().shape({
    color: Yup.string().oneOf(Object.values(ChartColor)).required(),
    filters: filtersValidations,
  }),
  name: Yup.string().required().min(2).max(100),
  type: Yup.string().oneOf(Object.values(ChartType)).required(),
});

export const getKpiCardChartValidationSchema = (formatMessage: IntlShape['formatMessage']) =>
  commonValidations.concat(
    Yup.object().shape({
      displaySettings: Yup.object().shape({
        displayMode: Yup.string().oneOf(Object.values(KPIChartDisplayMode)).required(),
        kpi: Yup.object().shape({
          aggregation: Yup.object().shape({
            operation: Yup.string().required(),
          }),
          identifier: Yup.string().required(),
        }),
        segmentsValue: Yup.array().when('displayMode', {
          is: (v: KPIChartDisplayMode) => v === KPIChartDisplayMode.GAUGE,
          then: (yupSchema) =>
            yupSchema
              .of(Yup.number().required())
              .length(3)
              // Custom method to check if each value is greater than the preceding one + common errors
              .test('ascendingOrder', formatMessage, function checkGaugeSegments(array: number[] | undefined) {
                if (!Array.isArray(array) || array.length !== 3) {
                  return this.createError({
                    message: formatMessage({ defaultMessage: 'There must be exactly 3 values' }),
                  });
                }
                if (array.some(isNaN)) {
                  return this.createError({
                    message: formatMessage({ defaultMessage: 'Some values are not of type number' }),
                  });
                }
                if (array.some((value) => value < 0)) {
                  return this.createError({
                    message: formatMessage({ defaultMessage: 'Some values are under 0' }),
                  });
                }
                for (let i = 1; i < array.length; i++) {
                  if (array[i] <= array[i - 1]) {
                    return this.createError({
                      message: formatMessage(
                        {
                          defaultMessage:
                            'Element at index {superiorIndex} ({value}) should be greater than element at index {index} ({previousValue})',
                        },
                        { superiorIndex: i + 1, value: array[i], previousValue: array[i - 1], index: i },
                      ),
                    });
                  }
                }
                return true;
              }),
          otherwise: (yupSchema) => yupSchema.nullable(),
        }),
      }),
    }),
  );

export const simpleBarChartValidationSchema = commonValidations.concat(
  Yup.object().shape({
    displaySettings: Yup.object().shape({
      xAxis: Yup.object().shape({
        identifier: Yup.string().oneOf([ChartDisplaySettingsXAxis.PERIOD, ChartDisplaySettingsXAxis.USER]).required(),
      }),
      yAxis: Yup.object().shape({
        aggregation: Yup.object().shape({
          operation: Yup.string().required(),
        }),
        identifier: Yup.string().required(),
        joins: Yup.array().of(Yup.string()),
      }),
    }),
  }),
);

export const simpleLineChartValidationSchema = commonValidations.concat(
  Yup.object().shape({
    displaySettings: Yup.object().shape({
      xAxis: Yup.object().shape({
        identifier: Yup.string().oneOf([ChartDisplaySettingsXAxis.PERIOD, ChartDisplaySettingsXAxis.USER]).required(),
      }),
      yAxis: Yup.object().shape({
        aggregation: Yup.object().shape({
          operation: Yup.string().required(),
        }),
        identifier: Yup.string().required(),
        joins: Yup.array().of(Yup.string()),
      }),
    }),
  }),
);

export const formInitialValues: DashboardChartConfiguration<ChartType.SIMPLE_BAR_CHART> = {
  name: '',
  customReportId: '',
  type: ChartType.SIMPLE_BAR_CHART,
  displaySettings: {
    filters: {
      periods: {
        isEnabled: true,
        values: [],
      },
      users: {
        isEnabled: true,
        values: [],
      },
      teams: {
        isEnabled: true,
        values: [],
      },
    },
    xAxis: {
      identifier: ChartDisplaySettingsXAxis.PERIOD,
    },
    yAxis: {
      aggregation: { operation: CustomReportAggregationOperation.sum },
      identifier: '',
      joins: [],
    },
    color: ChartColor.DARK_BLUE,
  },
};
