import { IconDownload, IconInfoCircle, IconViewfinder } from '@tabler/icons-react';
import { noop } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { isBeingReviewed, type PaymentCategory } from '@amalia/core/types';
import { openInNewTab } from '@amalia/ext/web';
import { type CreateAdjustmentDto, DataExportsApiClient } from '@amalia/frontend/web-data-layers';
import { ActionsEnum, SubjectsEnum, userProfileSubject } from '@amalia/kernel/auth/shared';
import { useAbilityContext } from '@amalia/kernel/auth/state';
import { useStatementDetailContext } from '@amalia/lib-ui';
import { type StatementThread } from '@amalia/payout-collaboration/comments/types';
import { type DataExportRequest } from '@amalia/reporting/exports/shared';
import { type CompanyFeatureFlags } from '@amalia/tenants/companies/types';
import { tenantUsersRoutes } from '@amalia/tenants/users/profile/shared';

import { type StatementViewEnum } from '../../StatementDetail';

export type StatementDetailHeaderActionsProps = {
  setSelectAdjustment: (adjustment: CreateAdjustmentDto) => void;
  handleForecastedSwitch: (nextState: StatementViewEnum) => Promise<void>;
  statementCommentThread: StatementThread | null;
  paymentTotalByType?: Record<PaymentCategory, number> & {
    containsHoldRules: boolean;
  };
  companyFeatureFlags: Record<CompanyFeatureFlags, boolean>;
};

export const useStatementDetailHeaderActions = ({
  setSelectAdjustment,
  handleForecastedSwitch,
  statementCommentThread,
  paymentTotalByType,
  companyFeatureFlags,
}: StatementDetailHeaderActionsProps) => {
  const ability = useAbilityContext();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  // CALCULATION
  const {
    statement,
    isForecastedView,
    isCalculationRunning,
    calculationProgress,
    openStatementThreadPanel,
    launchCalculation,
    stopCalculation,
  } = useStatementDetailContext();

  const handleClickQuota = useCallback(() => {
    if (statement.userId) {
      openInNewTab(generatePath(tenantUsersRoutes.MEMBER_PAGE, { memberId: statement.userId }));
    }
  }, [statement.userId]);

  const handleClickDocumentation = useCallback(() => {
    if (statement.plan?.linkToPlanDocumentation) {
      openInNewTab(statement.plan.linkToPlanDocumentation);
    }
  }, [statement.plan]);

  // WORKFLOW
  const isWorkflowBegun = useMemo(() => isBeingReviewed(statement), [statement]);

  // ADJUSTMENTS
  const handleNewAdjustment = useCallback(
    () =>
      setSelectAdjustment
        ? setSelectAdjustment({
            name: '',
            description: '',
            paymentPeriodId: statement.period.id,
          })
        : noop,
    [setSelectAdjustment, statement],
  );

  // EXPORT
  const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false);

  const handleExportModalOpen = useCallback(() => setIsExportModalOpen(true), []);
  const handleExportModalClose = useCallback(() => setIsExportModalOpen(false), []);
  const handleExportModalSubmit = useCallback(async (values: DataExportRequest) => {
    await DataExportsApiClient.createDataExport(values);
    setIsExportModalOpen(false);
  }, []);

  // COMMENTS
  const onStatementCommentsClicked = useCallback(() => {
    if (statementCommentThread?.id) {
      navigate(
        generatePath(routes.STATEMENT_COMMENT, {
          id: statement.id,
          stid: statementCommentThread.id,
        }),
      );
    } else {
      openStatementThreadPanel();
    }
  }, [statementCommentThread, statement, navigate, openStatementThreadPanel]);

  // ICONS
  const menuIconsList = useMemo(
    () =>
      [
        statement.userId &&
          ability.can(ActionsEnum.view, userProfileSubject({ id: statement.userId })) && {
            label: formatMessage({ defaultMessage: 'User profile' }),
            icon: <IconViewfinder />,
            handleClick: handleClickQuota,
          },
        ability.can(ActionsEnum.export, SubjectsEnum.Statement) &&
          !isForecastedView && {
            icon: <IconDownload />,
            label: formatMessage({ defaultMessage: 'Export' }),
            handleClick: handleExportModalOpen,
          },
        statement.plan?.linkToPlanDocumentation && {
          icon: <IconInfoCircle />,
          label: formatMessage({ defaultMessage: 'Plan documentation' }),
          handleClick: handleClickDocumentation,
        },
      ].filter(Boolean),
    [
      ability,
      handleClickQuota,
      handleExportModalOpen,
      handleClickDocumentation,
      statement,
      formatMessage,
      isForecastedView,
    ],
  );

  return {
    statement,
    ability,
    isWorkflowBegun,
    companyFeatureFlags,
    handleForecastedSwitch,
    handleExportModalClose,
    handleExportModalSubmit,
    formatMessage,
    handleNewAdjustment,
    statementCommentThread,
    onStatementCommentsClicked,
    menuIconsList,
    isExportModalOpen,
    paymentTotalByType,
    launchCalculation,
    stopCalculation,
    isForecastedView,
    isCalculationRunning,
    calculationProgress,
  };
};
