import { css } from '@emotion/react';
import { IconCheck, IconTrash } from '@tabler/icons-react';
import { isEmpty } from 'lodash';
import { memo, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { UserPrettyFormat } from '@amalia/data-capture/fields/components';
import {
  Button,
  Group,
  Modal,
  Select,
  type SelectOption,
  Stack,
  IconButton,
  FormFieldLabel,
  Checkbox,
} from '@amalia/design-system/components';
import {
  useDeleteDashboardUserSharedWith,
  useGetDashboardUsersSharedWith,
  useUpdateDashboardShareCandidates,
} from '@amalia/reporting/dashboards-v2/state';

import { useDashboardShareCandidatesOptions } from '../hooks/useDashboardShareCandidationsOptions';

interface DashboardShareModalProps {
  readonly dashboardId: string;
  readonly isOpen: boolean;
  readonly onCloseModal: () => void;
}

export const DashboardShareModal = memo(function DashboardShareModal({
  dashboardId,
  onCloseModal,
  isOpen,
}: DashboardShareModalProps) {
  const { formatMessage } = useIntl();

  const shareCandidatesOptions = useDashboardShareCandidatesOptions(dashboardId);
  const [selectedShareCandidates, setSelectedShareCandidates] = useState<string[]>([]);
  const [mustNotifyUsers, setMustNotifyUsers] = useState(true);

  const resetSelectedShareCandidates = useCallback(() => setSelectedShareCandidates([]), []);

  const { data: usersThatHaveAccess } = useGetDashboardUsersSharedWith(dashboardId);
  const { mutate: updateDashboardShareCandidates, isPending } = useUpdateDashboardShareCandidates(
    dashboardId,
    resetSelectedShareCandidates,
  );
  const { mutate: removeUserFromSharingList } = useDeleteDashboardUserSharedWith(dashboardId);

  const handleRemoveUserFromSharingList = useCallback(
    (userToRemoveId: string) => {
      removeUserFromSharingList(userToRemoveId);
    },
    [removeUserFromSharingList],
  );

  const handleConfirm = useCallback(() => {
    updateDashboardShareCandidates({ candidateIds: selectedShareCandidates, mustNotifyUsers });
  }, [selectedShareCandidates, updateDashboardShareCandidates, mustNotifyUsers]);

  return (
    <Modal
      isOpen={isOpen}
      size={Modal.Size.MEDIUM}
      onClose={onCloseModal}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage defaultMessage="Share dashboard" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Stack gap={32}>
            <Stack gap={24}>
              <FormattedMessage defaultMessage="By sharing this dashboard, you will give selected users read-only access to all charts in the dashboard. Note that all admin users at your company have access to all dashboards." />

              <Stack gap={4}>
                <FormFieldLabel>
                  <FormattedMessage defaultMessage="Share with" />
                </FormFieldLabel>
                <Group
                  align="center"
                  gap={16}
                >
                  <div
                    css={css`
                      flex-grow: 1;
                    `}
                  >
                    <Select<SelectOption<string>, true>
                      isMultiple
                      options={shareCandidatesOptions}
                      placeholder={formatMessage({ defaultMessage: 'Select managers' })}
                      value={selectedShareCandidates}
                      onChange={setSelectedShareCandidates}
                    />
                  </div>
                  <Button
                    disabled={selectedShareCandidates.length === 0}
                    icon={<IconCheck />}
                    isLoading={isPending}
                    css={css`
                      height: min-content;
                    `}
                    onClick={handleConfirm}
                  >
                    <FormattedMessage defaultMessage="Confirm" />
                  </Button>
                </Group>
              </Stack>
              {!isEmpty(selectedShareCandidates) && (
                <Checkbox
                  checked={mustNotifyUsers}
                  label={<FormattedMessage defaultMessage="Notify users about sharing this dashboard" />}
                  onChange={setMustNotifyUsers}
                />
              )}
            </Stack>
            <Stack gap={8}>
              {(usersThatHaveAccess || []).map((user) => (
                <Group key={user.id}>
                  <UserPrettyFormat
                    firstName={user.firstName}
                    lastName={user.lastName}
                    pictureURL={user.pictureURL}
                  />
                  <IconButton
                    icon={<IconTrash />}
                    label={formatMessage({ defaultMessage: 'Remove access' })}
                    variant={IconButton.Variant.DANGER}
                    onClick={() => handleRemoveUserFromSharingList(user.id)}
                  />
                </Group>
              ))}
            </Stack>
          </Stack>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
});
