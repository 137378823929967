import { IconChevronRight, IconPencil, IconTrash } from '@tabler/icons-react';
import { memo, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { type Adjustment, formatTotal, isBeingReviewed } from '@amalia/core/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import {
  ColumnHelper,
  DataGrid,
  Table,
  type DataGridProps,
  useDataGridState,
  useControlledDataGridData,
} from '@amalia/design-system/components';
import { Link } from '@amalia/ext/react-router-dom';
import { type MergeAll } from '@amalia/ext/typescript';
import { objectToQs } from '@amalia/ext/web';
import {
  STATEMENTS_ACTIONS,
  useThunkDispatch,
  deleteAdjustment,
  selectCustomObjectDefinitionsMapById,
} from '@amalia/frontend/web-data-layers';
import { ActionsEnum, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { useAbilityContext } from '@amalia/kernel/auth/state';
import { useStatementDetailContext } from '@amalia/lib-ui';

const columnHelper = new ColumnHelper<Adjustment>();

export type AdjustmentsDataGridProps = MergeAll<
  [
    Omit<DataGridProps<Adjustment, Adjustment['id']>, 'columns' | 'columnSorting' | 'data' | 'rowKey'>,
    {
      adjustments: Adjustment[];
      onSelectAdjustment: (adjustment: Adjustment) => void;
    },
  ]
>;

export const AdjustmentsDataGrid = memo(function AdjustmentsDataGrid({
  adjustments,
  onSelectAdjustment,
  ...props
}: AdjustmentsDataGridProps) {
  const { formatMessage } = useIntl();
  const dispatch = useThunkDispatch();

  const ability = useAbilityContext();
  const { statement, launchCalculation } = useStatementDetailContext();
  const customObjectDefinitionMap = useSelector(selectCustomObjectDefinitionsMapById);

  const hasRecord = adjustments.some((adjustment) => adjustment.rowExternalId);
  const isWorkflowBegun = isBeingReviewed(statement);

  const handleDeleteAdjustment = useCallback(
    async (adjustmentId: string) => {
      const action = await dispatch(deleteAdjustment(statement.id, adjustmentId));
      if (action.type !== STATEMENTS_ACTIONS.ERROR) {
        await launchCalculation();
      }
    },
    [dispatch, statement, launchCalculation],
  );

  const columns = useMemo(
    () =>
      [
        columnHelper.accessor('name', {
          id: 'name',
          size: 140,
          header: formatMessage({ defaultMessage: 'Adjusment name' }),
          sortingFn: DataGrid.sortingFn.strings,
          cell: ({ value: name }) => <Table.Cell.Main>{name}</Table.Cell.Main>,
        }),

        hasRecord &&
          columnHelper.display({
            id: 'record',
            header: formatMessage({ defaultMessage: 'Record' }),
            size: 240,
            sortingFn: (a, b) => DataGrid.sortingFn.strings(a.rowName || a.rowExternalId, b.rowName || b.rowExternalId),
            cell: ({ row: adjustment }) =>
              adjustment.rowExternalId ? (
                <Table.Cell.WithActions
                  actions={
                    // Add a link to the record if the user can view it.
                    ability.can(ActionsEnum.view, SubjectsEnum.Data) &&
                    customObjectDefinitionMap[adjustment.rowDefinitionId]?.machineName ? (
                      <Link
                        openInNewTab
                        unsetStyle
                        to={{
                          pathname: generatePath(routes.CAPTURED_RECORD_MODEL_DETAILS, {
                            modelMachineName: customObjectDefinitionMap[adjustment.rowDefinitionId].machineName,
                          }),
                          search: objectToQs({ search: adjustment.rowExternalId }),
                        }}
                      >
                        <Table.Cell.Action
                          icon={<IconChevronRight />}
                          iconPosition={Table.Cell.Action.IconPosition.RIGHT}
                        >
                          <FormattedMessage defaultMessage="View record" />
                        </Table.Cell.Action>
                      </Link>
                    ) : null
                  }
                >
                  {adjustment.rowName || adjustment.rowExternalId}
                </Table.Cell.WithActions>
              ) : null,
          }),

        columnHelper.accessor('amount', {
          id: 'amount',
          size: 0,
          header: formatMessage({ defaultMessage: 'Amount' }),
          sortingFn: DataGrid.sortingFn.numbers,
          cell: ({ value: amount }) => (
            <Table.Cell.Text>
              {amount ? formatTotal(amount, FormatsEnum.currency, statement.currency) : amount}
            </Table.Cell.Text>
          ),
        }),

        columnHelper.accessor('description', {
          id: 'description',
          header: formatMessage({ defaultMessage: 'Description' }),
          sortingFn: DataGrid.sortingFn.strings,
          cell: ({ value: description }) => <Table.Cell.Text>{description}</Table.Cell.Text>,
        }),

        !isWorkflowBegun &&
          ability.can(ActionsEnum.adjust, SubjectsEnum.Statement) &&
          columnHelper.display({
            id: Table.Cell.Actions.columnId,
            header: '',
            size: 0,
            cell: ({ row: adjustment }) => (
              <Table.Cell.Actions>
                <Table.Cell.IconAction
                  icon={<IconPencil />}
                  label={formatMessage({ defaultMessage: `Edit the adjustment {name}` }, { name: adjustment.name })}
                  onClick={() => onSelectAdjustment(adjustment)}
                />
                <Table.Cell.IconAction
                  icon={<IconTrash />}
                  label={formatMessage({ defaultMessage: `Delete the adjustment {name}` }, { name: adjustment.name })}
                  variant={Table.Cell.IconAction.Variant.DANGER}
                  onClick={() => handleDeleteAdjustment(adjustment.id)}
                />
              </Table.Cell.Actions>
            ),
          }),
      ].filter(Boolean),
    [
      formatMessage,
      statement.currency,
      isWorkflowBegun,
      ability,
      handleDeleteAdjustment,
      onSelectAdjustment,
      customObjectDefinitionMap,
      hasRecord,
    ],
  );

  const { columnSorting, setColumnSorting } = useDataGridState();
  const { data: controlledData } = useControlledDataGridData(columns, adjustments, { columnSorting });

  return (
    <DataGrid<Adjustment, Adjustment['id']>
      {...props}
      columns={columns}
      data={controlledData}
      rowKey="id"
      columnSorting={
        <DataGrid.ColumnSorting
          columnSorting={columnSorting}
          onChangeColumnSorting={setColumnSorting}
        />
      }
    />
  );
});
